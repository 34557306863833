import React, { useState } from "react";
import {
  Modal,
  Input,
  Button,
  Select,
  Switch,
  DatePicker,
  TimePicker,
  InputNumber,
  Divider,
} from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../UserManagement/UserManagement.scss";
import "./ModalStyles.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";
import UpArrow from "../../../../../Assets/svg/upArrow.svg";
import moment from "moment";
import { httpService } from "../../../../../services/httpService.service";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import IconStrategy from "../../../../../Assets/svg/trip.svg";

const { Option } = Select;

const TripLogCardModal = ({
  visible,
  onClose,
  fetchTripLogistics,
  siteVisits,
  setMessage,
}) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [overNight, setOverNight] = useState(true);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const projectId = localStorage.getItem("currentProjectID");
  const [loading, setLoading] = useState(false);
  const initialValues = {
    SiteVisitCard: "",
    dateForTrip: null,
    pickUpTime: "",
    pickUpPlace: "",
    meetingTime: "",
    meetingPlace: "",
    mainRoadsToTravel: "",
    distanceToSite: "",
    timeToSite: "",
    numberOfDays: "",
    enumeratorTeamDistance: "",
    numberOfPeople: "",
    numberOfNights: "",
    lodging: "",
    otherComments: "",
    logCoordinatorName: "",
    tripStatus: "",
    isOverNight: true,
  };

  const validationSchema = Yup.object({
    SiteVisitCard: Yup.string().required("Please select site visit card"),
    dateForTrip: Yup.date().required("Please select date"),
    pickUpTime: Yup.string().required("Please pick up time"),
    pickUpPlace: Yup.string().required("Enter pick up place"),
    meetingTime: Yup.string().required("Please pick up time"),
    meetingPlace: Yup.string().required("Enter meeting place"),
    enumeratorTeamDistance: Yup.string().required(
      "Select enumerator team distance"
    ),
    numberOfDays: Yup.number()
      .required("Please write number of days")
      .typeError("Please enter a valid number"),
    isOverNight: Yup.boolean(),
    numberOfPeople:
      overNight &&
      Yup.number()
        .required("Please enter number of people")
        .typeError("Please enter a valid number"),
    numberOfNights:
      overNight &&
      Yup.number()
        .required("Please enter number of nights")
        .typeError("Please enter a valid number"),
    lodging:
      overNight &&
      Yup.string().required("Please enter lodging (e.g. Hotel Name)"),
    logCoordinatorName: Yup.string().required(
      "Please write logistics coordinator name(s) & contact information"
    ),
    tripStatus: Yup.string().required("Enter select trip status"),
  });

  const handleSiteVisitChange = (value) => {
    const visit = siteVisits.filter((visit) => visit.id === value);
    // console.log(visit);
    setSelectedSiteVisit(visit);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);

    const payload = {
      projectId: projectId,
      siteVisitId: values?.SiteVisitCard,
      enumeratorInformation: {
        tripDate: values.dateForTrip,
        pickupTime: values.pickUpTime,
        pickupPlace: values.pickUpPlace,
        meetingTime: values.meetingTime,
        meetingPlace: values.meetingPlace,
        mainRoadsToTravel: values.mainRoadsToTravel,
        distanceToSite: values.distanceToSite,
        timeToSite: values.timeToSite,
        numberOfDays: values.numberOfDays,
        enumeratorTeamDistance: values.enumeratorTeamDistance,
        isOverNight: values.isOverNight,
        overNightNoOfPeople: values.numberOfPeople,
        overNightNoOfNights: values.numberOfNights,
        overNightLodging: values.lodging,
      },
      otherComments: values.otherComments,
      logiCoordNameAndOtherInfo: values.logCoordinatorName,
      tripStatus: values.tripStatus,
    };

    httpService.post(
      "/api/TripLogistic",
      payload,
      () => {
        setMessage({
          trigger: true,
          type: "success",
          icon: IconStrategy,
          title: "Trip Logistic added successfully!",
          text: "Trip Logistic has been added to your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        handleLeave();
        fetchTripLogistics();
        setSubmitting(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem adding this trip logistic to your database, please try again!`;
        setMessage({
          trigger: true,
          type: "error",
          icon: IconStrategy,
          title: "Failed to add the trip logistic",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setSubmitting(false);
      }
    );
  };

  const CustomTimePicker = ({ field, form, ...props }) => {
    const value = field.value ? moment(field.value, "HH:mm:ss") : null;
    return (
      <TimePicker
        {...props}
        value={value}
        onChange={(time, timeString) =>
          form.setFieldValue(field.name, timeString)
        }
        format="HH:mm:ss"
      />
    );
  };

  const handleClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onClose();
    setSelectedSiteVisit(null);
    setOverNight(true);
    setMoreInfo(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleSaveDraft = async (values) => {
    setLoading(true);
    console.log("Formik Values:", values);
    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      return;
    }

    const projectId = localStorage.getItem("currentProjectID");

    let setSelectedSiteVisit;
    if (values?.SiteVisitCard) {
      setSelectedSiteVisit = siteVisits?.find(
        (siteVisit) => siteVisit.id == values?.SiteVisitCard
      );
    }
    const formData = {
      projectId: projectId || "",
      siteVisitId: setSelectedSiteVisit || "",
      enumeratorInformation: {
        tripDate: values.dateForTrip || undefined,
        pickupTime: values.pickUpTime || "",
        pickupPlace: values.pickUpPlace || "",
        meetingTime: values.meetingTime || "",
        meetingPlace: values.meetingPlace || "",
        mainRoadsToTravel: values.mainRoadsToTravel || "",
        distanceToSite: values.distanceToSite || "",
        timeToSite: values.timeToSite || "",
        numberOfDays: values.numberOfDays || "",
        enumeratorTeamDistance: values.enumeratorTeamDistance || "",
        isOverNight: values.isOverNight || false,
        overNightNoOfPeople: values.numberOfPeople || 0,
        overNightNoOfNights: values.numberOfNights || 0,
        overNightLodging: values.lodging || "",
      },
      otherComments: values.otherComments || "",
      logiCoordNameAndOtherInfo: values.logCoordinatorName || "",
      tripStatus: values.tripStatus || "",
    };

    const jsonData = JSON.stringify(formData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 5, // Draft type
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft trip logistics card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "New draft trip logistics added successfully!",
            text: message,
          });
          fetchTripLogistics(projectId);
          handleLeave();
          setLoading(false);
        },
        (error) => {
          console.error("There was an error adding the task!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft trip logistics card card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });

          setLoading(false);
        }
      )
      .finally(() => {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleClose}
      footer={false}
      width={800}
      className="tripLogs"
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleClose}
          alt="cancelX"
        />
      </div>
      <h2 className="modalHeader">Trip logistics card</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, handleChange }) => (
          <Form className="formik-form">
            <div className="fullWidthInput">
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  Select site visit card
                </label>
                <Select
                  name="SiteVisitCard"
                  placeholder="Select site visit card"
                  onChange={(value) => {
                    setFieldValue("SiteVisitCard", value);
                    handleSiteVisitChange(value);
                  }}
                >
                  {siteVisits &&
                    siteVisits?.map((visit) => (
                      <Option key={visit.id} value={visit.id}>
                        {visit.identifier}
                      </Option>
                    ))}
                </Select>
              </div>
              <ErrorMessage
                name="SiteVisitCard"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </div>
            {selectedSiteVisit && (
              <div className="tripCardDetails">
                <div className="tripCardDetailsHeader">
                  <p className="modalsHeader1">Enumerator information</p>
                </div>

                <ul className={moreInfo ? "list" : "list showAllList"}>
                  <li>
                    <p>
                      Location 1:{" "}
                      <span>{selectedSiteVisit[0]?.locationDetails?.city}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Location 2:{" "}
                      <span>{selectedSiteVisit[0]?.locationDetails?.road}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Location 3:{" "}
                      <span>
                        {selectedSiteVisit[0]?.locationDetails?.district}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Site name: <span>{selectedSiteVisit[0]?.identifier}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Exact address:{" "}
                      <span>
                        {selectedSiteVisit[0]?.locationDetails?.address}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Start time:{" "}
                      <span>
                        {moment(selectedSiteVisit[0]?.visit?.startTime).format(
                          "LLL"
                        )}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Duration:{" "}
                      <span>{/* Calculate duration if needed */}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      IP Activity Description:{" "}
                      <span>
                        {selectedSiteVisit[0]?.specificsOfOnSiteOperation}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Cluster:{" "}
                      <span>{selectedSiteVisit[0]?.cluster?.name}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      TPM coordinator name:{" "}
                      <span>
                        {
                          selectedSiteVisit[0]?.logistic?.tpmCoordinator
                            ?.firstName
                        }{" "}
                        {
                          selectedSiteVisit[0]?.logistic?.tpmCoordinator
                            ?.lastName
                        }
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Enumerator Team card info: <span></span>
                    </p>
                  </li>
                </ul>

                <div className="seeMoreWrapper">
                  <a type="button" onClick={() => setMoreInfo(!moreInfo)}>
                    {moreInfo ? "See Less" : "See More"}{" "}
                    <img src={moreInfo ? UpArrow : DownArrow} alt="arrow" />
                  </a>
                </div>
              </div>
            )}
            <p className="modalsHeader1 mt-4">Enumerator information</p>
            {/* <div
              style={{
                display: "flex",
                gap: "10px",
                // justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            > */}
            <div
              className="my-2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Date for trip
                  </label>
                  <DatePicker
                    className="w-100"
                    name="dateForTrip"
                    onChange={(date) => setFieldValue("dateForTrip", date)}
                  />
                </div>
                <ErrorMessage
                  name="dateForTrip"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Pick up time
                  </label>
                  <Field
                    className="w-100"
                    name="pickUpTime"
                    component={CustomTimePicker}
                  />
                </div>
                <ErrorMessage
                  name="pickUpTime"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                paddingBottom: "10px",
                gap: "10px",
              }}
            > */}
            <div
              className="my-2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Pick up place
                  </label>
                  <Input
                    name="pickUpPlace"
                    className="w-100"
                    placeholder="Enter the pick up place"
                    value={values.pickUpPlace}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage
                  name="pickUpPlace"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "48%",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Meeting time
                  </label>
                  <Field
                    className="w-100"
                    name="meetingTime"
                    component={CustomTimePicker}
                  />
                </div>
                <ErrorMessage
                  name="meetingTime"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Meeting place
                  </label>
                  <Input
                    name="meetingPlace"
                    placeholder="Enter meeting place"
                    value={values.meetingPlace}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage
                  name="meetingPlace"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>Main roads to travel</label>
                  <Input
                    name="mainRoadsToTravel"
                    placeholder="Enter the main roads to travel"
                    value={values.mainRoadsToTravel}
                    onChange={handleChange}
                  />
                </div>
                <ErrorMessage
                  name="mainRoadsToTravel"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
                gap: "10px",
              }}
            > */}
            {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              > */}
            <div
              className="my-2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "48%",
                }}
              >
                <label>Distance to site</label>

                <InputNumber
                  name="distanceToSite"
                  className="w-100"
                  onChange={(values) => setFieldValue("distanceToSite", values)}
                  placeholder="Number"
                  value={values.distanceToSite}
                />
                {/* </div> */}
                <ErrorMessage
                  name="distanceToSite"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "48%",
                }}
              >
                <label>Time to site</label>
                <Field name="timeToSite" component={CustomTimePicker} />
              </div>
              <ErrorMessage
                name="timeToSite"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </div>
            {/* </div> */}
            <div
              className="my-2"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Number of days
                  </label>
                  <InputNumber
                    className="w-100"
                    name="numberOfDays"
                    placeholder="Number"
                    value={values.numberOfDays}
                    onChange={(values) => setFieldValue("numberOfDays", values)}
                  />
                </div>
                <ErrorMessage
                  name="numberOfDays"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Enumerator team distance
                  </label>
                  <Select
                    allowClear
                    showSearch
                    name="enumeratorTeamDistance"
                    placeholder="Select enumerator team distance"
                    onChange={(value) =>
                      setFieldValue("enumeratorTeamDistance", value)
                    }
                  >
                    <Option value="SameCity">Same city</Option>
                    <Option value="FromOtherStates">From other states</Option>
                    <Option value="AnotherStatesCity">
                      Another state’s city
                    </Option>
                    <Option value="Other">Other</Option>
                  </Select>
                  <ErrorMessage
                    name="enumeratorTeamDistance"
                    component="div"
                    className="error"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div
              className="my-3"
              style={{
                display: "flex",
                marginBottom: "20px",
                gap: "10px",
              }}
            >
              <Switch
                style={{ marginLeft: "10px" }}
                checked={values.isOverNight}
                onChange={(checked) => {
                  setOverNight(checked);
                  setFieldValue("isOverNight", checked);
                }}
              />
              <span>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                Overnight
              </span>
            </div>
            <ErrorMessage
              name="isOverNight"
              component="div"
              className="error"
              style={{ color: "red" }}
            />
            {overNight && (
              <div
                style={{
                  background: "var(--14, #F6F6F6)",
                  padding: "24px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              >
                <p className="modalsHeader1">Overnight details</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <label>
                        <span style={{ color: "red", paddingRight: "3px" }}>
                          *
                        </span>
                        Number of people
                      </label>
                      <InputNumber
                        className="w-100"
                        name="numberOfPeople"
                        placeholder="Number"
                        onChange={(values) =>
                          setFieldValue("numberOfPeople", values)
                        }
                        value={values.numberOfPeople}
                      />
                    </div>
                    <ErrorMessage
                      name="numberOfPeople"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <label>
                        <span style={{ color: "red", paddingRight: "3px" }}>
                          *
                        </span>
                        Number of nights
                      </label>
                      <InputNumber
                        className="w-100
                      "
                        name="numberOfNights"
                        placeholder="Number"
                        type="number"
                        onChange={(values) =>
                          setFieldValue("numberOfNights", values)
                        }
                        value={values.numberOfNights}
                      />
                    </div>
                    <ErrorMessage
                      name="numberOfNights"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <label>
                      <span style={{ color: "red", paddingRight: "3px" }}>
                        *
                      </span>
                      Lodging (e.g. Hotel Name)
                    </label>
                    <Input
                      name="lodging"
                      placeholder="Enter the lodging here"
                      value={values.lodging}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="lodging"
                    component="div"
                    className="error"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            )}
            <div style={{ paddingBottom: "10px" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label>Other comments</label>
                <Input.TextArea
                  name="otherComments"
                  rows={4}
                  value={values.otherComments}
                  placeholder="Other comments"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  Logistics coordinator name(s) & contact information
                </label>
                <Input
                  name="logCoordinatorName"
                  value={values.logCoordinatorName}
                  placeholder="Enter the log coordinator name"
                  onChange={handleChange}
                />
              </div>
              <ErrorMessage
                name="logCoordinatorName"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  Select trip status
                </label>
                <Select
                  name="tripStatus"
                  placeholder="Select the trip status"
                  onChange={(value) => setFieldValue("tripStatus", value)}
                >
                  <Option value={1}>Planned</Option>
                  <Option value={2}>In progress</Option>
                  <Option value={3}>Completed</Option>
                  <Option value={4}>Cancelled</Option>
                </Select>
              </div>
              <ErrorMessage
                name="tripStatus"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </div>
            <Divider />
            <div className="footer-buttons py-0">
              <Button
                className="cancel-workspace-button"
                key="saveDraft"
                onClick={() => handleSaveDraft(values)}
                loading={loading}
              >
                Save as draft
              </Button>
              <Button
                className="add-workspace-button"
                key="success"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TripLogCardModal;
