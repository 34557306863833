import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DownArrow from "../../../Assets/svg/downArrow.svg";
import "./KanbanBoardStyle.scss";

const KanbanBoardComponent = ({ tasks, columns, changeStatus, setClickedTaskId}) => {

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If no destination or same destination as source, do nothing
    if (!destination || destination.droppableId === source.droppableId) {
      return;
    }

    const sourceColumnId = parseInt(source.droppableId, 10);
    const destinationColumnId = parseInt(destination.droppableId, 10);

    const draggedTaskIndex = tasks.findIndex(
      (task) => task.column === sourceColumnId && task.id === result.draggableId
    );

    const newTasks = Array.from(tasks);
    const [draggedTask] = newTasks.splice(draggedTaskIndex, 1);

    draggedTask.column = destinationColumnId;
    newTasks.splice(destination.index, 0, draggedTask);

    changeStatus(draggedTask);
  };
  if (changeStatus === false) {
    return (
      <div className="kanbanBoard">
        <div className="cardMainDiv">
          {columns?.map((column) => (
            <div key={column.id} className="cardHeader">
              <div className="cardHeaderText" style={{ backgroundColor: column.color }}>
                <h2>{column.title}</h2>
              </div>
              <div className="cardWrapper">
                {tasks
                  .filter((task) => task.column === column.id)
                  .map((task, index) => (
                    <div key={task.id} className="card">
                      <h1>{task.content.title}</h1>
                      <p>
                        Status Updated: <span>{task.content.statusUpdated}</span>
                      </p>
                      <p>
                        IP: <span>{task.content.ip}</span>
                      </p>
                      <p>
                        State: <span>{task.content.state}</span>
                      </p>
                      <p>
                        Municipality: <span>{task.content.municipality}</span>
                      </p>
                      <div className="buttonWrapper">
                        <button onClick={() => setClickedTaskId(task.id)}>
                          See More
                          <img src={DownArrow} alt="toggle" />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }else{
    return (
      <div className="kanbanBoard">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="cardMainDiv">
            {columns?.map((column) => (
              <Droppable key={column.id} droppableId={column.id.toString()}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="cardHeader"
                  >
                    <div
                      className="cardHeaderText"
                      style={{ backgroundColor: column.color }}
                    >
                      <h2>{column.title}</h2>
                    </div>
                    <div className="cardWrapper">
                      {tasks
                        .filter((task) => task.column === column.id)
                        .map((task, index) => (
                          <Draggable
                            key={task.id}
                            draggableId={task.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`card`}
                              >
                                <h1>{task.content.title}</h1>
                                <p>
                                  Status Updated:{" "}
                                  <span>{task.content.statusUpdated}</span>
                                </p>
                                <p>
                                  IP: <span>{task.content.ip}</span>
                                </p>
                                <p>
                                  State: <span>{task.content.state}</span>
                                </p>
                                <p>
                                  Municipality:{" "}
                                  <span>{task.content.municipality}</span>
                                </p>
  
                                <div className="buttonWrapper">
                                  <button
                                    onClick={() => setClickedTaskId(task.id)}
                                  >
                                    See More
                                    <img src={DownArrow} alt="toggle" />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
    );
  }

};

export default KanbanBoardComponent;
