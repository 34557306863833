import React, { useEffect, useState } from "react";
import CalendarViewComponent from "../../../../../components/CalendarView/CalendarViewComponent";
import Filters from "../TableView/Filters";
import { Pagination } from "antd";
import Preview from "../TableView/modals/Preview";

const CalendarView = ({
  showAllTripData,
  fetchTripLogistics,
  pagination,
  onPageChange,
  setPagination,
  filters,
  setFilters,
}) => {
  const projectID = localStorage.getItem("currentProjectID");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();

  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  // useEffect(() => {
  //   fetchTripLogistics(
  //     projectID,
  //     filters.startDate,
  //     filters.endDate,
  //     filters.status,
  //     filters.country,
  //     filters.city,
  //     filters.search
  //   );
  // }, [filters,pagination.current, pagination.pageSize]);

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPagination({ ...pagination, current: 1 });
  };

  const events = showAllTripData.reduce((acc, trip) => {
    const dateKey = trip.enumeratorInformation.tripDate.split("T")[0];
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push({
      type: "success",
      status: trip.tripStatus,
      content: trip.logiCoordNameAndOtherInfo,
      id: trip.id,
    });
    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format("YYYY-MM-DD");
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter((event) => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (
        filters.search &&
        !event.content.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
      <Filters
        onChange={handleFiltersChange}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        fetchTripLogistics={fetchTripLogistics}
        projectID={projectID}
      />
      <CalendarViewComponent
        getListData={getListData}
        setClickedEventId={handleEventClicked}
      />
      {/* <div className="d-flex justify-content-end my-5">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) => {
            onPageChange(page, pageSize);
            setPagination({ ...pagination, current: page, pageSize });
          }}
        />
      </div> */}
      <Preview
        visible={showPreviewModal}
        onClose={handleCancel}
        record={clickedEventId}
      />
    </>
  );
};

export default CalendarView;
