import "./App.scss";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./app/pages/auth/Login";
import ProtectedRoute from "./app/pages/auth/ProtectedRoute";
import Projects from "./app/pages/projects/Projects";
import ProjectLayout from "./app/ProjectLayout";
import Dashboard from "./app/pages/dashboard/Dashboard";
import Planning from "./app/pages/planning/Planning";
import Flags from "./app/pages/Flags/Flags";
import Completed from "./app/pages/completed/Completed";
import Reporting from "./app/pages/reporting/Reporting";
import Implementation from "./app/pages/implementation/Implementation";
import MainLayout from "./app/MainLayout";
import UserManagement from "./app/pages/UserManagement/UserManagement";
import Workspaces from "./app/pages/workspace/Workspaces";
import NotFound from "./app/components/NotFound/NotFound";
import IpManagement from "./app/pages/IpManagement/TableView";
import SettingsLayout from "./app/SettingsLayout";
import AccountSummary from "./app/pages/accountSettings//AccountSummary/AccountSummary";
import LoginInformation from "./app/pages/accountSettings/LoginInformation/LoginInformation";

import ProfileInformation from "./app/pages/accountSettings/ProfileInformation/ProfileInformation";
import PaymentInformation from "./app/pages/accountSettings/Payments/PaymentInformation";
import firstTime from "./app/pages/firstTimeLogIn/firstTime";
import FirstTimeSignIn from "./app/pages/firstTimeLogIn/firstTime";
import ConfirmationCode from "./app/pages/firstTimeLogIn/confirmationCode";
import ForgotPassword from "./app/pages/forgotPassword/forgotPassword";
import ForgotPasswordCode from "./app/pages/forgotPassword/code";
import NewPassword from "./app/pages/forgotPassword/NewPassword";
import MainFlags from "./app/pages/Flags/MainFlags";
import BudgetSummary from "./app/pages/BudgetSummary/BudgetSummary";
import Offices from "./app/pages/offices/Offices";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/forgotPassword/code" element={<ForgotPasswordCode />} />
          <Route path="/newPassword" element={<NewPassword />} />
          <Route path="confirmationCode" element={<ConfirmationCode />} />

          <Route path="/*" element={<ProtectedRoute />}>
            <Route path="dashboard/*" element={<MainLayout />}>
              <Route path="user-management" element={<UserManagement />} />
              <Route path="contracts" element={<Workspaces />} />
              <>
                <Route path="budget-summary" element={<BudgetSummary />} />
              </>
              <Route path="offices" element={<Offices />} />

              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="projects" element={<Projects />} />
            <Route path="first-time" element={<FirstTimeSignIn />} />
            {/* <Route path="confirmationCode" element={<ConfirmationCode />} /> */}
            <Route path="project/*" element={<ProjectLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="planning" element={<Planning />} />
              <Route path="implementation" element={<Implementation />} />
              <Route path="reporting" element={<Reporting />} />
              <Route path="completed" element={<Completed />} />
              <Route path="flags" element={<MainFlags />} />{" "}
              <Route path="ip-management" element={<IpManagement />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="settings/*" element={<SettingsLayout />}>
              {/* <Route path="account-summary" element={<AccountSummary />} /> */}
              <Route path="login-information" element={<LoginInformation />} />
              <Route path="payments" element={<PaymentInformation />} />
              <Route
                path="profile-information"
                element={<ProfileInformation />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Login from "./app/pages/auth/Login";
// import MainLayout from "./app/MainLayout";
// import UserManagement from "./app/pages/UserManagement/UserManagement";
// import Workspaces from "./app/pages/workspace/Workspaces";
// import Projects from "./app/pages/projects/Projects";
// import ProjectLayout from "./app/ProjectLayout";
// import Dashboard from "./app/pages/dashboard/Dashboard";
// import Planning from "./app/pages/planning/Planning";
// import Flags from "./app/pages/Flags/Flags";
// import Completed from "./app/pages/completed/Completed";
// import Reporting from "./app/pages/reporting/Reporting";
// import Implementation from "./app/pages/implementation/Implementation";
// import ProtectedRoute from "./app/pages/auth/ProtectedRoute";

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route element={<ProtectedRoute />}>
//           <Route path="/dashboard" element={<MainLayout />}>
//             <Route path="user-management" element={<UserManagement />} />
//             <Route path="workspaces" element={<Workspaces />} />
//           </Route>
//           <Route path="/projects" element={<Projects />} />
//           <Route path="/project" element={<ProjectLayout />}>
//             <Route path="dashboard" element={<Dashboard />} />
//             <Route path="planning" element={<Planning />} />
//             <Route path="implementation" element={<Implementation />} />
//             <Route path="reporting" element={<Reporting />} />
//             <Route path="completed" element={<Completed />} />
//             <Route path="flags" element={<Flags />} />
//           </Route>
//         </Route>
//       </Routes>
//     </Router>
//   );
// }

// export default App;
