import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input, Spin, Form } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";

const { Option } = Select;

const EditOffice = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const formRef = React.useRef();  // Reference to the Form

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (visible && record) {
      setLoading(true);
      httpService.get(
        `/api/Office?id=${record.id}`,
        (res) => {
          setLoading(false);
          if (formRef.current) {
            formRef.current.setFieldsValue({
              officeName: res.data.name,
              country: res.data.countries.map((country) => country.id),
            });
          }
        },
        (error) => {
          console.log("Office Fetch Error:", error);
          setLoading(false);
        }
      );
    }
  }, [visible, record]);

  const fetchCountries = () => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => {
        setAllCountries(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleSubmit = (values) => {
    setSubmitting(true); 

    httpService.put(
      "/api/Office",
      {
        id: record?.id,
        name: values?.officeName || "",
        countryIds: values?.country || [],  
      },
      (res) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `Office updated successfully!`,
          text: `The office has been updated in your database!`,
        });
        setSubmitting(false);  // Stop loader on success
        onSubmit();
        onClose();
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          `We had a problem updating this office in your database, please try again.`;

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        console.log("Update error:", error);
        setSubmitting(false);  // Stop loader on error
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={400}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Edit Office</h2>
      <Spin spinning={loading || submitting}>
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Office Name"
            name="officeName"
            rules={[
              { required: true, message: "Please enter the office name" },
              {
                max: 100,
                message: "Office name must be at most 100 characters long",
              },
            ]}
          >
            <Input placeholder="Enter Office Name" autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please select at least one country" }]}
          >
            <Select
              mode="multiple"
              placeholder="Select Country"
              showSearch
              allowClear
              optionFilterProp="children"
            >
              {allCountries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditOffice;
