import React, { useEffect, useState } from "react";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import { Select, Input, Button } from "antd";
import { httpService } from "../../../../services/httpService.service";
const { Option } = Select;

//months
const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

//years
const years = [
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

const Filters = ({ filters, setFilters }) => {
  const [states, setStates] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState();

  //Filters
  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const apiStates = "/api/GeoData/countries";

    httpService.get(
      apiStates,
      (res) => setStates(res.data),
      (error) => console.log(error)
    );

    const apiClusters = "/api/Site/getAllClusters";

    httpService.get(
      apiClusters,
      (res) => setClusters(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleClearAll = () => {
    setFilters({
      current: 1,
      pageSize: 10,
      Search: "",
      ImplementingPartnerId: "",
      State: "",
      SiteVisitStatus: "",
      ClusterId: "",
      FieldTeamReportingStatus: "",
      Month: "",
      Year: "",
    });
  };

  const handleSearch = (event) => {
    const Search = event.target.value.toLowerCase();
    setFilters((prevFilters) => ({ ...prevFilters, Search, current: 1 }));
  };

  const handleIp = (ip) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ImplementingPartnerId: ip,
      current: 1,
    }));
  };

  const handleStateChange = (state) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      State: state,
      current: 1,
    }));
  };

  const handleClusterChange = (ClusterId) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ClusterId,
      current: 1,
    }));
  };

  const handleStatusChange = (status) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SiteVisitStatus: status,
      current: 1,
    }));
  };

  const handleReportingStatusChange = (FieldTeamReportingStatus) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      FieldTeamReportingStatus,
      current: 1,
    }));
  };

  const handleMonth = (Month) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Month,
      current: 1,
    }));
  };

  const handleYear = (Year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Year,
      current: 1,
    }));
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input
          placeholder="Type something here"
          suffix={<img src={SearchIcon} alt="Search" />}
          onChange={handleSearch}
          value={filters.Search || undefined}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              onChange={handleIp}
              value={filters.ImplementingPartnerId || undefined}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">State</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              showSearch
              allowClear
              onChange={handleStateChange}
              value={filters.State || undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {states &&
                states.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              onChange={handleClusterChange}
              value={filters.ClusterId || undefined}
            >
              {clusters &&
                clusters.map((cluster) => (
                  <Option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </Option>
                ))}
              {/* Add more gender options as needed */}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Site visit status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              onChange={handleStatusChange}
              value={filters.SiteVisitStatus || undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={1}>Data collection</Option>
              <Option value={2}>Post-data Collection</Option>
              <Option value={3}>Finalization</Option>
              <Option value={4}>Delivered</Option>
              <Option value={5}>IP Feedback</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Field team reporting status</p>
            <Select
              showSearch
              allowClear
              onChange={handleReportingStatusChange}
              value={filters.FieldTeamReportingStatus || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select reporting status"
            >
              <Option value={1}>Data Collection</Option>
              <Option value={2}>Data Check</Option>
              <Option value={3}>Translation</Option>
              <Option value={4}>Field Reporting Completed</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month</p>
            <Select
              showSearch
              allowClear
              onChange={handleMonth}
              value={filters.Month || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a month"
            >
              {months &&
                months.map((month) => (
                  <Option key={month.id} value={month.name}>
                    {month.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <Select
              showSearch
              allowClear
              onChange={handleYear}
              value={filters.Year || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a year"
            >
              {years &&
                years.map((year, index) => (
                  <Option key={index} value={year}>
                    {year}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
