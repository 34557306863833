import { Card, Dropdown, Menu, Button } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";

const WorkspaceBox = React.memo(
  ({ title, handleWorkspace, onEdit, onDelete, onClick, id, onPreview }) => {
    const handleEditClick = (e) => {
      e.domEvent.stopPropagation(); // Using e.domEvent to access the original event object
      onEdit();
    };

    const handleDeleteClick = (e) => {
      e.domEvent.stopPropagation(); // Using e.domEvent to access the original event object
      onDelete();
    };

    const handlePreviewClick = (e) => {
      e.domEvent.stopPropagation(); // Using e.domEvent to access the original event object
      onPreview();
    };
    const menu = useMemo(
      () => (
        <div className="workspaceModalMenu">
          <Menu>
            {" "}
            <Menu.Item key="preview" onClick={handlePreviewClick}>
              <EyeOutlined />
              Preview Contract
            </Menu.Item>
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Menu.Item key="edit" onClick={handleEditClick}>
                <EditOutlined />
                Edit Contract
              </Menu.Item>
            )}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Menu.Item
                key="delete"
                onClick={handleDeleteClick}
                style={{ color: "red" }}
              >
                <DeleteOutlined style={{ color: "red" }} />
                Delete Contract
              </Menu.Item>
            )}
          </Menu>
        </div>
      ),
      [onEdit, onDelete]
    );

    return (
      <Card
        className="workspace-box"
        onClick={() => onClick(id)}
        style={{ cursor: "pointer" }}
      >
        <img
          src="/assets/svg/workspace-bggraphics.svg"
          alt=""
          className="workspace-cicleimg"
        />
        <h2>{title}</h2>
        <div className="d-flex justify-content-end w-100 workspaceMenu">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              type="text"
              icon={<MoreOutlined />}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        </div>
      </Card>
    );
  }
);

WorkspaceBox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleWorkspace: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WorkspaceBox;
