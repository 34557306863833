import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../../services/httpService.service";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";

const { Option } = Select;

const PreviewTracking = ({ visible, onClose, siteVisitCardId }) => {
  console.log(siteVisitCardId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [siteVisitCard, setSiteVisitCard] = useState({});
  const [filteredContacts, setFilteredContacts] = useState({
    siteContacts: [],
    pointOfContacts: [],
    KIIcontacts: [],
  });

  useEffect(() => {
    if (siteVisitCardId && toBeVisible) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setSiteVisitCard(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [siteVisitCardId]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const contacts = siteVisitCard?.visit?.contacts || [];
    const siteContacts = contacts.filter(
      (contact) => contact.contactType === 1
    );
    const pointOfContacts = contacts.filter(
      (contact) => contact.contactType === 2
    );
    const KIIcontacts = contacts.filter((contact) => contact.contactType === 3);

    setFilteredContacts({
      siteContacts,
      pointOfContacts,
      KIIcontacts,
    });
  }, [siteVisitCard]);

  const renderMonth = (month) => {
    switch (month) {
      case "01":
        return "January";
        break;
      case "02":
        return "Febuary";
        break;
      case "03":
        return "March";
        break;
      case "04":
        return "April";
        break;
      case "05":
        return "May";
        break;
      case "06":
        return "June";
        break;
      case "07":
        return "July";
        break;
      case "08":
        return "August";
        break;
      case "09":
        return "September";
        break;
      case "10":
        return "October";
        break;
      case "11":
        return "November";
        break;
      case "12":
        return "December";
        break;
      default:
        return "Not set";
    }
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `Date: ${year}-${month}-${day}`;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Site visit identifier</span>
            }
          >
            <p className="previewText">{siteVisitCard?.identifier}</p>
          </Form.Item>
          {siteVisitCard.tracking && (
            <>
              <p className="modalsHeader1">Tracking</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="reportingYear"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting year</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.reportingYear}
                  </p>
                </Form.Item>
                <Form.Item
                  name="reportingMonth"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting month</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {renderMonth(siteVisitCard?.tracking?.reportingMonth)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="trainingIsCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Training Completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.trainingIsCompleted
                      ? "Yes"
                      : "No"}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="trainingDate"
                  label={
                    <span style={{ fontWeight: "bold" }}>Training date</span>
                  }
                  style={{ width: "33%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.trainingDate)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="trainingScoreInfo"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Training score info
                    </span>
                  }
                  style={{ width: "67%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.trainingScoreInfo}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="actualDateOfVisit"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Actual date of visit
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.actualDateOfVisit)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfReport"
                  label={
                    <span style={{ fontWeight: "bold" }}>Date of report</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfReport)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfDebrief"
                  label={
                    <span style={{ fontWeight: "bold" }}>Date of debrief</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfDebrief)}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="percentageOfDataCollectionCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      % of data collection completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {`${siteVisitCard?.tracking?.percentageOfDataCollectionCompleted}%`}
                  </p>
                </Form.Item>
                <Form.Item
                  name="siteVisitStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Site visit status
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.siteVisitStatus === 1
                      ? "Data Collection"
                      : siteVisitCard?.tracking?.siteVisitStatus === 2
                      ? "Post-data Collection"
                      : siteVisitCard?.tracking?.siteVisitStatus === 3
                      ? "Finalization"
                      : siteVisitCard?.tracking?.siteVisitStatus === 4
                      ? "Delivered"
                      : siteVisitCard?.tracking?.siteVisitStatus === 5
                      ? "IP Feedback"
                      : ""}
                  </p>
                </Form.Item>
                <Form.Item
                  name="fieldTeamReportingStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Field Team Reporting Status
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.fieldTeamReportingStatus === 1
                      ? "Data Collection"
                      : siteVisitCard?.tracking?.fieldTeamReportingStatus === 2
                      ? "Data Check"
                      : siteVisitCard?.tracking?.fieldTeamReportingStatus === 3
                      ? "Translation"
                      : siteVisitCard?.tracking?.fieldTeamReportingStatus === 4
                      ? "Field Reporting Completed"
                      : ""}
                  </p>
                </Form.Item>
              </div>
            </>
          )}

          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PreviewTracking;
