import { Modal, Form, Input, Button, Divider, Typography, Spin } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import TrackingDetails from "./TrackingDetails";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import dayjs from "dayjs";

const { TextArea } = Input;

const { Title } = Typography;

const TrackingModal = ({
  visible,
  onCancel,
  onOk,
  siteVisitCardId,
  tracked,
  setMessage,
}) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enumerators, setEnumerators] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    // console.log()
    if (siteVisitCardId && visible) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setEnumerators(res?.data?.logistic?.team?.enumerators);
          if (tracked) {
            const initialData = {
              identifier: res?.data?.identifier,
              actualDateOfVisit: dayjs(res?.data?.tracking?.actualDateOfVisit),
              dateOfReport: dayjs(res?.data?.tracking?.dateOfReport),
              dateOfDebrief: dayjs(res?.data?.tracking?.dateOfDebrief),
              fieldTeamReportingStatus:
                res?.data?.tracking?.fieldTeamReportingStatus,
              percentageOfDataCollectionCompleted:
                res?.data?.tracking?.percentageOfDataCollectionCompleted,
              reportingMonth: res?.data?.tracking?.reportingMonth,
              reportingYear: res?.data?.tracking?.reportingYear,
              siteVisitStatus: res?.data?.tracking?.siteVisitStatus,
              trainingDate: dayjs(res?.data?.tracking?.trainingDate),
              trainingIsCompleted: res?.data?.tracking?.trainingIsCompleted,
              trainingScoreInfo: res?.data?.tracking?.trainingScoreInfo,
            };
            form.setFieldsValue(initialData);
          } else {
            const initialData = {
              identifier: res?.data?.identifier,
            };
            form.setFieldsValue(initialData);
          }

          setLoading(false);
        },
        (err) => {
          console.log("Error: ", err);
          setLoading(false);
        }
      );
    }
  }, [visible]);

  const closeModal = () => {
    form.resetFields();
    onCancel();
  };

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = moment(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const formatHours = (hrs) => {
    if (!hrs?.$isDayjsObject) return "";

    const formattedTime = hrs.format("HH:mm:ss");
    return formattedTime;
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        const formData = new FormData();

        if (tracked) {
          formData.append("Id", siteVisitCardId);
          formData.append("Tracking.ReportingYear", values.reportingYear);
          formData.append("Tracking.ReportingMonth", values.reportingMonth);
          formData.append("Tracking.TrainingDate", trainingDate);
          formData.append(
            "Tracking.TrainingScoreInfo",
            values.trainingScoreInfo ? values.trainingScoreInfo : ""
          );
          formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
          formData.append("Tracking.DateOfReport", dateOfReport);
          formData.append("Tracking.DateOfDebrief", dateOfDebrief);
          formData.append(
            "Tracking.PercentageOfDataCollectionCompleted",
            values.percentageOfDataCollectionCompleted
          );
          formData.append("Tracking.SiteVisitStatus", values?.siteVisitStatus);
          formData.append(
            "Tracking.FieldTeamReportingStatus",
            values?.fieldTeamReportingStatus
          );
          formData.append(
            "Tracking.TrainingIsCompleted",
            values.trainingIsCompleted || false
          );

          httpService.put(
            "/api/Site/updateSiteVisit",
            formData,
            (res) => {
              const message =
                res?.message ||
                "You have successfully updated tracking in site visit";
              setMessage({
                trigger: true,
                type: "success",
                icon: "",
                title: `Tracking of site visit updated successfully!`,
                text: message,
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
              form.resetFields();
              onOk();
            },
            (error) => {
              const errorMessage =
                error?.errors?.[0]?.message ||
                "Failed to create tracking for site visit. Please try again later.";

              setMessage({
                trigger: true,
                type: "danger",
                icon: "",
                title: "Update Failed",
                text: errorMessage,
              });

              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);

              setSubmitLoader(false);
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          formData.append("siteVisitId", siteVisitCardId);
          formData.append("reportingYear", values.reportingYear);
          formData.append("reportingMonth", values.reportingMonth);
          formData.append("trainingDate", trainingDate);
          formData.append(
            "trainingScoreInfo",
            values.trainingScoreInfo ? values.trainingScoreInfo : ""
          );
          formData.append("actualDateOfVisit", actualDateOfVisit);
          formData.append("dateOfReport", dateOfReport);
          formData.append("dateOfDebrief", dateOfDebrief);
          formData.append(
            "percentageOfDataCollectionCompleted",
            values.percentageOfDataCollectionCompleted
          );
          formData.append("siteVisitStatus", values?.siteVisitStatus);
          formData.append(
            "fieldTeamReportingStatus",
            values?.fieldTeamReportingStatus
          );
          formData.append(
            "trainingIsCompleted",
            values.trainingIsCompleted || false
          );

          httpService.post(
            "/api/Site/createTracking",
            formData,
            (res) => {
              const message =
                res.message || "You have successfully tracked site visit";

              setMessage({
                trigger: true,
                type: "success",
                icon: "",
                title: `Tracking of site visit updated successfully!`,
                text: message,
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
              form.resetFields();
              onOk();
            },
            (error) => {
              const errorMessage =
                error?.errors?.[0]?.message ||
                "Failed to create tracking for site visit. Please try again later.";

              setMessage({
                trigger: true,
                type: "danger",
                icon: "",
                title: "Update Failed",
                text: errorMessage,
              });

              setTimeout(() => {
                setMessage({
                  trigger: false,
                  type: "",
                  icon: "",
                  title: "",
                  text: "",
                });
              }, 3000);
              setSubmitLoader(false);
            }
          );
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };
  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };
  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site visit card</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={onCancel} className="inviteButtonTPM">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          {tracked ? `Edit Tracking` : `Add Tracking`}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <div className="indentifierTracking">
            <Form.Item label="Site visit card identifier" name="identifier">
              <Input
                placeholder="### - Namegoeshere - Location - date"
                disabled
              />
            </Form.Item>
          </div>
          <Divider />

          <h3>Tracking</h3>
          <TrackingDetails
            enumerators={enumerators}
            // setEnumerators={setEnumerators}
          />

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default TrackingModal;
