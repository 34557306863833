import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const { Option } = Select;

const EditIp = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();

  // Populate form fields with record data when modal is opened or record changes
  useEffect(() => {
    if (record && visible) {
      form.setFieldsValue({
        ipName: record?.name, // Adjust key to match the actual data key in record
        location: record?.location, // Assuming 'location' is a key in record
        linkToWorkspace: record?.workspace, // Adjust key to match the actual data key in record
        linkToProjects: record?.project, // Adjust key to match the actual data key in record
      });
    }
  }, [record, visible]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={400}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          name="ipName"
          label={<span style={{ fontWeight: "bold" }}>IP Name</span>}
          rules={[{ required: true, message: "Please enter IP name" }]}
        >
          <p className="previewText">{record?.name}</p>
        </Form.Item>
        <Form.Item
          name="location"
          label={<span style={{ fontWeight: "bold" }}>Country</span>}
          rules={[{ required: true, message: "Please select Location" }]}
        >
          <p className="previewText">{record?.country}</p>
        </Form.Item>
        <Form.Item
          name="location"
          label={<span style={{ fontWeight: "bold" }}>Governorate/State</span>}
          rules={[{ required: true, message: "Please select Location" }]}
        >
          <p className="previewText">{record?.states}</p>
        </Form.Item>
        {!record?.cities || record?.cities === "N/A" ? (
          ""
        ) : (
          <Form.Item
            name="location"
            label={<span style={{ fontWeight: "bold" }}>City</span>}
            rules={[{ required: true, message: "Please select Location" }]}
          >
            <p className="previewText">{record?.cities}</p>
          </Form.Item>
        )}
        <div className="buttonsModal" style={{ marginTop: "20px" }}>
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditIp;
