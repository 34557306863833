import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col, Spin, Checkbox} from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import "../IpManagement/IpManagement.scss";
import { httpService } from "../../../services/httpService.service";
import moment from "moment";
import 'moment-weekday-calc';

const AddBudget = ({ visible, onClose, isEdit, contractEdit, callSummary }) => {
  const [form] = Form.useForm();
  const ipGroups = [{ id: Date.now() }]
  const [allCountries, setAllContries] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [isDraftModalVisible, setIsDraftModalVisible] = useState(false); // State for draft modal
  const workspaceId = localStorage.getItem("currentContractId");
  const [draftName, setDraftName] = useState(""); // State to hold draft name
  const [allContracts, setAllContracts] = useState([]);
  const [allContractLoader, setAllContractLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [staffInfo, setStaffInfo] = useState({})
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [activeContract, setActiveContract] = useState(null)
  const [numberOfBen, setNumberOfBen] = useState(0);

  const [workingDays, setWorkingDays] = useState(0);
  const [fieldBudget, setFieldBudget] = useState(0);
  const [netBudget, setNetBudget] = useState(0);
  const [fieldExpense, setFieldExpense] = useState(0);

  const callCountries = (data) => {
    setLoading(true);
    for (const key in form.getFieldsValue()) {
      if(key !== 'contract') {
        form.setFieldValue(key, null);
      }
    }
    setWorkingDays(calcBusinessDays(new Date(data?.start), new Date(data?.end)));
    setFieldBudget(0);
    setNetBudget(0);
    setFieldExpense(0);

    if(isEdit) {
      httpService.get(`/api/Contract/getContractBudgetById?id=${data?.id}`,(res) => {
        setAllContries(res?.data?.budgetDetails?.map((el) => ({value: el?.country?.id, label: el?.country?.stateName})));
        let countryData = {};
        countryData[`budget`] = res?.data?.budget;
        countryData[`contract`] = res?.data?.contractId;
        res?.data?.budgetDetails?.map((e) => {
          countryData[`pricingRate[${e?.country?.id}]`] = e?.pricingRate
          countryData[`timeRate[${e?.country?.id}]`] = e?.timeRate
          // countryData[`numberOfHours[${e?.country?.id}]`] = e?.numberOfHours
          if(e?.accomodationRate) {
            countryData[`oNaccomodationRate[${e?.country?.id}]`] = true
            countryData[`accomodationRate[${e?.country?.id}]`] = e?.accomodationRate
          }
          if(e?.travelRate) {
            countryData[`oNtravelRate[${e?.country?.id}]`] = true
            countryData[`travelRate[${e?.country?.id}]`] = e?.travelRate
          }
        });
        setActiveContract(res?.data);
        form.setFieldsValue(countryData);
        setLoading(false)
      }, (err) => {
        setLoading(false)
      })
    } else {
      fetchCountriesOfContract(data)
    }
    fetchNumberOfBen(data);
  }

  useEffect(() => {
    if(activeContract && isEdit) {
      changePricing();
    }
  },[activeContract])

  useEffect(() => {
    changePricing();
  },[numberOfBen])

  const fetchNumberOfBen = (data) => {
    httpService.get(`/api/Contract/getBeneficiaryByContract?contractId=${isEdit ? data?.contractId : data?.value}`,(res) => {
      setNumberOfBen(res?.data);
    },
    (err) => {
      console.log(err)
    })
  }

  const fetchCountriesOfContract = (data) => {
    httpService.get(`/api/GeoData/getCountriesOfContract?contractId=${data?.value}`,(res) => {
      setAllContries(res?.data?.map((el) => ({value: el?.id, label: el?.stateName})));
      setLoading(false)
    }, (err) => {
      setLoading(false)
    })
  }

  const fetchAllContracts = () => {
    setAllContractLoader(true);
    httpService.get(`/api/Contract/getAll?GetContractsWithoutBudget=true&ignorePagination=true`,(res) => {
        setAllContracts(res?.data?.map((el) => ({value: el?.id, label: el?.name, start: el?.contractStartDate, end: el?.contractEndDate})));
        setAllContractLoader(false);  
      }, (err) => {
        setAllContractLoader(false);  
    })
  }

  const calcBusinessDays = (date1, date2) => {
    return moment().weekdayCalc({
      rangeStart: new Date(date1),
      rangeEnd: moment(new Date(date2)).add(1, 'day').toDate(),
      weekdays: [1,2,3,4,5],
      exclusions: []
    })
  }

  useEffect(() => {
    if(!isEdit && visible) {
      fetchAllContracts()
    }
  },[visible])

  const handleCancel = () => {
    form.resetFields();
    setStaffInfo({travel: {}, accomodation: {}})
    setAllContracts([]);
    onClose();
    setWorkingDays(0);
    setFieldBudget(0);
    setNetBudget(0);
    setFieldExpense(0);
    setAllContries([])
  };

  const handleDraftSave = () => {
    console.log("Draft saved:", draftName);
    setIsDraftModalVisible(false);
  };

  const changeFormValue = (el, key) => {
    setStaffInfo((prevState) => ({...prevState, [key]: {...prevState[key], [el?.target?.id] : el?.target?.checked}}));
    changePricing();
  }

  const formSubmit = (values) => {
    setLoadingSubmission(true);
    const budgetDetails = [];
    const entriesById = {};

    for (const key in values) {
      const match = key.match(/\[(.*?)\]/);
      if (match) {
        const id = match[1];
        const fieldName = key.split('[')[0];
  
        if (!entriesById[id]) {
          entriesById[id] = {
            countryId: id,
            pricingRate: null,
            timeRate: null,
            travelRate: null,
            accomodationRate: null,
            // numberOfHours: null
          };
        }
  
        // Update values based on the formData fields
        if (fieldName === 'pricingRate') {
          entriesById[id].pricingRate = parseFloat(values[key]) || 0;
        } else if (fieldName === 'timeRate') {
          entriesById[id].timeRate = parseFloat(values[key]) || null;
        } else if (fieldName === 'travelRate') {
            if(values[`oN${key}`] === true) {
              entriesById[id].travelRate = parseFloat(values[`${key}`]) || null;
            }
        } else if (fieldName === 'accomodationRate') {
          if(values[`oN${key}`] === true) {
            entriesById[id].accomodationRate = parseFloat(values[`${key}`]) || null;
          }
        } 
        // else if (fieldName === 'numberOfHours') {
        //   entriesById[id].numberOfHours = parseFloat(values[key]) || null;
        // }
      }
    }

    for (const id in entriesById) {
      budgetDetails.push(entriesById[id]);
    }

    const result = {
      ...(isEdit ? {id: contractEdit?.id} : {contractId: values.contract}),
      id: contractEdit?.id,
      budget: parseFloat(values?.budget),
      budgetDetails
    };

    if(isEdit) {
      httpService.put(`/api/Contract/editContractBudget`, result, (el) =>{
        // callSummary();
        onClose('success');
        setAllContries([]);
        setAllContracts([]);
        setLoadingSubmission(false);
      }, (err) => {
        onClose('error');
        setLoadingSubmission(false);
      })
    } else {
      httpService.post(`/api/Contract/createContractBudget`, result, (el) =>{
        onClose('success');
        setAllContries([]);
        setAllContracts([]);
        setLoadingSubmission(false);
        callSummary();
      }, (err) => {
        onClose('error');
        setLoadingSubmission(false);
      })
    }
  }

  const changePricing = () => {
    const entriesById = {};
    for (const key in form.getFieldsValue()) {
      const match = key.match(/\[(.*?)\]/);
      if (match) {
        const id = match[1];
        const fieldName = key.split('[')[0];

        if (!entriesById[id]) {
          entriesById[id] = {
            countryId: id,
            pricingRate: null,
            timeRate: null,
            travelRate: null,
            accomodationRate: null,
            // numberOfHours: null
          };
        }

        // Update values based on the formData fields
        if (fieldName === 'pricingRate') {
          entriesById[id].pricingRate = parseFloat(form.getFieldsValue()[key]) || 0;
        } else if (fieldName === 'timeRate') {
          entriesById[id].timeRate = parseFloat(form.getFieldsValue()[key]) || null;
        } else if (fieldName === 'travelRate') {
            if(form.getFieldsValue()[`oN${key}`] === true) {
              entriesById[id].travelRate = parseFloat(form.getFieldsValue()[`${key}`]) || null;
            }
        } else if (fieldName === 'accomodationRate') {
          if(form.getFieldsValue()[`oN${key}`] === true) {
            entriesById[id].accomodationRate = parseFloat(form.getFieldsValue()[`${key}`]) || null;
          }
        } 
        // else if (fieldName === 'numberOfHours') {
        //   entriesById[id].numberOfHours = parseFloat(form.getFieldsValue()[key]) || null;
        // }
      }
    }

    let budgetAll = 0;
    let expenses = 0;

    for(const item in entriesById) {
      if(entriesById[item]?.timeRate === 1) {
        if(isEdit) {
          budgetAll += parseFloat(entriesById[item]?.pricingRate) * parseFloat(calcBusinessDays(activeContract?.contractStartDate, activeContract?.contractEndDate));
        } else {
          budgetAll += parseFloat(entriesById[item]?.pricingRate) * parseFloat(workingDays);
        }
      } else if (entriesById[item]?.timeRate === 2 && numberOfBen > 0) {
        budgetAll += parseFloat(entriesById[item]?.pricingRate) * parseFloat(numberOfBen);
      }

      if(entriesById[item]?.travelRate && entriesById[item]?.travelRate > 0) {
        expenses += entriesById[item]?.travelRate
      }

      if(entriesById[item]?.accomodationRate && entriesById[item]?.accomodationRate > 0) {
        if(isEdit) {
          expenses += entriesById[item]?.accomodationRate * parseFloat(calcBusinessDays(activeContract?.contractStartDate, activeContract?.contractEndDate));
        } else {
          expenses += entriesById[item]?.accomodationRate * parseFloat(workingDays);
        }
      }
    }

    setFieldBudget(budgetAll);
    setNetBudget(budgetAll);
    setFieldExpense(budgetAll + expenses);
  }

  useEffect(() => {
    if(isEdit && visible) {
      callCountries(contractEdit)
    }
  },[contractEdit, visible])

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={null} // Changed to null for better handling
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Add contract budget details</h2>
      <div style={{ marginBottom: "20px" }}>
        <p className="modalsHeader1">Budget information</p>
      </div>

      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={formSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contract"
              label="Select a contract"
              rules={[{ required: isEdit ? false : true, message: "Please select a contract!" }]}
            >
              {
                isEdit ?
                  <span>{contractEdit?.contractName}</span>
                :
                  <Select placeholder="Select a contract"
                    onChange={(id,data) => callCountries(data)}
                    options={allContracts}
                    loading={allContractLoader}
                  />
              }
            </Form.Item>
          </Col>
          <Col span={12}>
                <Form.Item
                  name="budget"
                  label="What is the budget for this contract in USD?"
                  rules={[{ required: true, message: "Please input the budget!" }]}
                  >
                  {
                    loading && isEdit 
                    ?
                      <Spin>
                        <Input type="number" prefix={'$'} placeholder="Enter budget in USD" min={0}/>
                      </Spin>
                    :
                      <Input type="number" prefix={'$'} placeholder="Enter budget in USD" min={0}/>
                  }
                </Form.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: "20px" }}>
          <p className="modalsHeader1">Staff information</p>
        </div>
        <div className="divIpDesign">
        {
          loading ?
          <Spin /> :  
          allCountries && allCountries?.length ? allCountries.map((group, index) => (
            <div className="ipGroupWrapper" key={group?.value}>
              <div className="ipGroup">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="modalsHeader1">{group?.label}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  <Form.Item
                    name={`timeRate[${group?.value}]`}
                    label="Select your time rate"
                    rules={[
                      { required: true, message: "Please select a time rate" },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Select placeholder="Select time rate" onChange={(el) => changeFormValue(el, 'timeRate')} allowClear
                      options={[{
                        value: 2,
                        label: 'Survey Rate'
                      },
                      {
                        value: 1,
                        label: 'Daily'
                      }]}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`pricingRate[${group?.value}]`}
                    label="Pricing Rate"
                    rules={[
                      { required: true, message: "Please enter pricing rate" },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input
                      type="number"
                      placeholder="Enter pricing rate"
                      autoComplete="off"
                      onChange={() => changePricing()}
                      prefix={'$'}
                      min={0}
                    />
                  </Form.Item>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  {
                    form?.getFieldValue(`timeRate[${group?.value}]`) === 2 &&
                    <Form.Item
                      name={`numberOfHours[${group?.value}]`}
                      label="Number of surveys"
                      rules={[
                        { required: true, message: "Please enter the number of surveys" },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input
                        type="number"
                        placeholder="Enter number of surveys"
                        autoComplete="off"
                        onChange={() => changePricing()}
                        min={0}
                      />
                    </Form.Item>
                  }
                </div> */}
                <div className="travel_rate">
                    <h5>Travel rate <span>(optional)</span></h5>
                    <Form.Item name={`oNtravelRate[${group?.value}]`} valuePropName="checked">
                      <Checkbox onChange={(el) => changeFormValue(el, 'value')}>
                        <div className="checkboxEnable">
                            <div className="checkboxIcon">
                              <div className="checkboxIconInner"></div>
                            </div>
                            <span>Enable travel rate</span>
                        </div>
                      </Checkbox>
                    </Form.Item>
                    <>
                      <div className="travelRateOn" style={{display : form?.getFieldValue(`oNtravelRate[${group?.value}]`) ? 'block' : 'none'}}>
                        <h5>If enabled write here the numbers to include the rate</h5>
                        <Form.Item name={`travelRate[${group?.value}]`}>
                          <Input type="number" min={0} placeholder="Write here the numbers.." onChange={() => changePricing()} prefix={'$'}/>
                        </Form.Item>
                      </div>
                    </>
                </div>
                <div className="acco_rate">
                    <h5>Accomodation rate <span>(optional)</span></h5>
                    <Form.Item name={`oNaccomodationRate[${group?.value}]`} valuePropName="checked">
                      <Checkbox onChange={(el) => changeFormValue(el, 'accomodation')}>
                        <div className="checkboxEnable">
                            <div className="checkboxIcon">  
                              <div className="checkboxIconInner"></div>
                            </div>
                            <span>Enable accomodation rate</span>
                        </div>
                      </Checkbox>
                    </Form.Item>
                    <>
                      <div className="accomodationRateOn" style={{display : form?.getFieldValue(`oNaccomodationRate[${group?.value}]`) ? 'block' : 'none'}}>
                        <h5>If enabled write here the numbers to include the rate</h5>
                        <Form.Item name={`accomodationRate[${group?.value}]`}>
                          <Input type="number" min={0} placeholder="Write here the numbers.." onChange={() => changePricing()} prefix={'$'}/>
                        </Form.Item>
                      </div>
                    </>
                </div>
                {/* End of New Fields */}

                {index < ipGroups.length - 1 && (
                  <hr
                    style={{ border: "0.5px solid #F5B0D2", margin: "20px 0" }}
                  />
                )}
              </div>
            </div>
          )) : 
          <>
            <span className="noContract">
              No contract selected
            </span>
          </>}
        </div>
        <div className="summary">
        <div style={{ marginBottom: "20px" }}>
          <p className="modalsHeader1">Summary</p>
        </div>
        {
          loading && isEdit
          ?
          <Spin style={{margin: '0 auto 20px'}}/> 
          :
          <div className="summaryGroup">
              <div className="frame"><p className="frameText1">Working Days</p><p className="frameNumber1">{
              !isEdit ?
              workingDays :
              calcBusinessDays(activeContract?.contractStartDate, activeContract?.contractEndDate)
              }</p></div>
              <div className="frame"><p className="frameText1">Field Ops. budget</p><p className="frameNumber1">${fieldBudget}</p></div>
              <div className="frame"><p className="frameText1">Net budget</p><p className="frameNumber2">${netBudget}</p></div>
              <div className="frame"><p className="frameText1">Field Ops. expenses</p><p className="frameNumber3">${fieldExpense}</p></div>
          </div>
        }
        </div>

        <Form.Item>
          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <Button
              className="saveDraftButton"
              key="save-draft"
              onClick={() => setIsDraftModalVisible(true)}
            >
              Save as Draft
            </Button> */}
            <Button className="addButtonTPM" key="submit" type="primary" htmlType="submit" loading={loadingSubmission}>
              {isEdit ?
                'Edit' :
                'Submit'  
              }
            </Button>
          </div>
        </Form.Item>
      </Form>

      {/* Save as Draft Modal */}
      <Modal
        visible={isDraftModalVisible}
        closable={false}
        onCancel={() => setIsDraftModalVisible(false)}
        footer={null}
      >
         <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => setIsDraftModalVisible(false)}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Save Draft</h2>
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              value={draftName}
              onChange={(e) => setDraftName(e.target.value)}
              placeholder="Enter draft name"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={() => setIsDraftModalVisible(false)}>Cancel</Button>
            <Button type="primary" onClick={handleDraftSave}>
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </Modal>
  );
};

export default AddBudget;