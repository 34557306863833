import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Checkbox,
  Tooltip,
} from "antd";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { AimOutlined } from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { OpenStreetMapAutocomplete } from "@amraneze/osm-autocomplete";
import "./LocationDetails.scss";
import axios from "axios";

const LocationMarker = ({ position, setPosition, form }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      form.setFieldsValue({
        latitude: lat.toFixed(6),
        longitude: lng.toFixed(6),
      });
    },
  });

  return position ? (
    <Marker
      position={position}
      icon={
        new Icon({
          iconUrl: markerIconPng,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
      }
    />
  ) : null;
};

const MapWithInvalidateSize = ({ children }) => {
  const map = useMap();

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 100); // Delay to ensure proper rendering
  }, [map]);

  return <>{children}</>;
};

const MapCenterUpdater = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position && position[0] !== null && position[1] !== null) {
      map.setView(position, map.getZoom(), {
        animate: true,
      });
    }
  }, [position, map]);

  return null;
};

const LocationDetails = ({ form, isEditing, parishCheck, setParishCheck }) => {
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [position, setPosition] = useState([0.0, 0.0]);
  const [loading, setLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(isEditing ? 13 : 2);
  const [mapLoading, setMapLoading] = useState(false);
  const lat = form.getFieldValue("latitude");
  const lon = form.getFieldValue("longitude");
  const [address, setAddress] = useState("");

  useEffect(() => {
    const lat = form.getFieldValue("latitude");
    const lng = form.getFieldValue("longitude");
    map(lat, lng);
    if (isEditing && lat && lon) {
      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
        setPosition([parsedLat, parsedLon]);
      }
      setLoading(false); // Data has been loaded
    }
  }, [form, isEditing, lat, lon]);

  useEffect(() => {
    if (!isNaN(latitude) && !isNaN(longitude)) {
      setPosition([latitude, longitude]);
    } else {
      setPosition([0, 0]); // Default to a neutral position if invalid
    }
  }, [latitude, longitude]);

  const map = (lat, lng) => {
    console.log("lat and lng", lat, lng);
    axios
      .get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          lat: lat,
          lon: lng,
          format: "json",
        },
      })
      .then((response) => {
        if (response.data && response.data.address) {
          let formattedAddress = response.data.display_name;

          if (address.municipality) {
            formattedAddress += `, Parish: ${address.municipality}`;
          }

          setAddress(formattedAddress);
        } else {
          setAddress("Address not found");
        }
      })
      .catch((err) => {
        console.error(err);
        setAddress("Failed to get address");
      });
  };

  useEffect(() => {
    const handleValuesChange = () => {
      const lat = form.getFieldValue("latitude");
      const lon = form.getFieldValue("longitude");

      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
      } else {
        setPosition([0, 0]); // Reset position if values are invalid
      }
      setLoading(false); // Data has been loaded
    };

    form.onValuesChange = handleValuesChange;

    return () => {
      form.onValuesChange = () => {}; // Cleanup
    };
  }, [form]);

  const handleGetCurrentLocation = () => {
    setMapLoading(true);
    setZoomLevel(13);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, accuracy } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          setPosition([latitude, longitude]);
          form.setFieldsValue({
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            accuracy: accuracy.toFixed(2),
          });
          // Set zoom level to 13 when current location is set
          setLoading(false); // Data has been loaded
          setMapLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false); // Ensure loading is turned off even if there's an error
          setMapLoading(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false); // Ensure loading is turned off even if geolocation is not supported
      setMapLoading(false);
    }
  };

  const selectLocation = (data) => {
    const { lat, lon } = data;
    if (data) {
      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
        setPosition([parsedLat, parsedLon]);
        form.setFieldsValue({
          latitude: parsedLat.toFixed(6),
          longitude: parsedLon.toFixed(6),
          accuracy: 0,
        });
        setZoomLevel(13); // Optionally set zoom level to 13 on search
        setLoading(false); // Data has been loaded
      }
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "latitude") {
      setLatitude(parseFloat(value) || 0);
    } else if (name === "longitude") {
      setLongitude(parseFloat(value) || 0);
    }
  };

  const handleParishCheckChange = (e) => {
    const checked = e.target.checked;
    setParishCheck(checked);

    if (!checked) {
      form.setFieldsValue({ parish: undefined });
    }
  };

  return (
    <>
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Latitude (x°)"
                  name="latitude"
                  initialValue={isEditing ? latitude : 0} // Set default value for adding
                  rules={[{ required: true, message: "Please enter latitude" }]}
                >
                  <Input
                    type="number"
                    name="latitude"
                    placeholder="Enter latitude value"
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Longitude (y°)"
                  name="longitude"
                  initialValue={isEditing ? longitude : 0}
                  rules={[
                    { required: true, message: "Please enter longitude" },
                  ]}
                >
                  <Input
                    type="number"
                    name="longitude"
                    placeholder="Enter longitude value"
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </Col>
              <div className="w-100 d-flex flex-column gap-1 mb-4">
                <Form.Item name="parishCheck" className="m-0 p-0">
                  <Checkbox
                    value={parishCheck}
                    checked={parishCheck}
                    onChange={handleParishCheckChange}
                    id="parish"
                  >
                    <span className="parish">Parish </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "gray",
                      }}
                    >
                      (optional)
                    </span>
                  </Checkbox>
                </Form.Item>
                {parishCheck && (
                  <Form.Item name="parish" className="m-0 p-2">
                    <Input type="text" placeholder="Please enter parish" />
                  </Form.Item>
                )}
              </div>
              {address && (
                <Col span={24}>
                  <Form.Item label="Selected Address" name="address">
                    <p
                      style={{ fontSize: "14px", lineHeight: "1.8" }}
                      className="border rounded p-3 mb-3 bg-light"
                    >
                      {address}
                    </p>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={20}>
                <Form.Item label="Search" name="search">
                  <div
                    style={{ position: "relative", zIndex: 2 }}
                    className="osm-search"
                  >
                    <OpenStreetMapAutocomplete
                      value={null}
                      onChange={(val) => selectLocation(val)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Tooltip title="This feature works only when you have location enabled in your browser!">
                    <Button
                      onClick={handleGetCurrentLocation}
                      style={{ marginTop: 30 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <AimOutlined style={{ color: "#999" }} />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Spin spinning={mapLoading}>
                  <div
                    style={{
                      height: "400px",
                      width: "100%",
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    <MapContainer
                      center={position} // Use current position state
                      zoom={zoomLevel} // Use dynamic zoom level
                      style={{ height: "100%", width: "100%" }}
                      maxBounds={[
                        [-90, -180],
                        [90, 180],
                      ]}
                      maxBoundsViscosity={1.0}
                    >
                      <MapWithInvalidateSize>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {!loading && position && (
                          <LocationMarker
                            position={position}
                            setPosition={setPosition}
                            form={form}
                          />
                        )}
                        <MapCenterUpdater position={position} />
                      </MapWithInvalidateSize>
                    </MapContainer>
                  </div>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              marginTop: "-110px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Form.Item
                name="isVirtual"
                style={{ marginBottom: "0" }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <span>This visit will be virtual</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LocationDetails;
