import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spin, Divider } from "antd";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../../../../services/httpService.service";
import "./Preview.scss";
const Preview = ({ visible, onClose, record }) => {
  const [teamData, setTeamData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (record?.id) {
      fetchTeamById(record.id);
    }
  }, [record?.id]);

  const fetchTeamById = (teamId) => {
    setLoading(true);
    const apiEndpoint = `/api/User/getTeamById?teamId=${teamId}`;
    httpService.get(
      apiEndpoint,
      (res) => {
        const team = res.data;
        setTeamData(team);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching team details:", error);

        setLoading(false);
      }
    );
  };

  const membersUsernames =
    teamData?.members?.map((member) => member.userName).join(", ") || "";

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt=""
        />
      </div>
      <Spin spinning={loading}>
        <h2 className="modalHeader">Enumerator Team Card</h2>
        <p className="modalsHeader1">General details</p>
        <Form layout="vertical">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="name"
              label={<span style={{ fontWeight: "bold" }}>Team Name</span>}
            >
              <p className="previewText">{teamData.name}</p>
            </Form.Item>
            <Form.Item
              name="members"
              label={<span style={{ fontWeight: "bold" }}>Location</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {teamData?.state?.country?.stateName}
              </p>
            </Form.Item>
          </div>
          <span style={{ fontWeight: "bold" }}>Team Members</span>

          <div className="memberWrapper">
            {teamData?.members &&
              teamData?.members.map((member) => (
                <div className="contactsInner" style={{ width: "40%" }}>
                  <h1>{member.roleName}</h1>
                  <div className="contactDetails">
                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Member name</span>
                      <p>
                        {member.firstName} {member.lastName}
                      </p>
                    </div>

                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Email</span>
                      <p className="previewtext">{member.username}</p>
                    </div>
                  </div>
                </div>
              ))}
            {teamData?.drivers &&
              teamData?.drivers.map((member) => (
                <div className="contactsInner" style={{ width: "40%" }}>
                  <h1>Driver</h1>
                  <div className="contactDetails">
                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Member name</span>
                      <p>{member.name}</p>
                    </div>

                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Phone number</span>
                      <p className="previewtext">+{member.phoneNumber}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Preview;
