import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Row,
  Col,
  Table,
  Pagination,
  Spin,
  Divider,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import numberOfMembers from "../../../Assets/svg/numberOfMembers.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import { httpService } from "../../../services/httpService.service";
import "./UserManagement.scss";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";

const { Option } = Select;

const InviteMember = ({ visible, onClose, handleMembersAdded, setMessage }) => {
  const [loading, setLoading] = useState(true);
  const [officesByRow, setOfficesByRow] = useState({});
  const [roles, setRoles] = useState([]);
  const [offices, setOffices] = useState([]);
  const [allCountries, setAllContries] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [generatedData, setGeneratedData] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingStates, setLoadingStates] = useState(false);

  const pageSize = 5;

  const fetchRoles = () => {
    httpService.get(
      "/api/Account/getAllRoles",
      (res) => {
        setRoles(res.data);
        checkLoading();
      },
      (error) => {
        console.error("Failed to fetch roles:", error);
        checkLoading();
      }
    );
  };

  const fetchContracts = () => {
    httpService.get(
      "/api/Contract/getAll",
      (res) => {
        setContracts(res.data);
        checkLoading();
      },
      (error) => {
        console.error("Failed to fetch contracts:", error);
        checkLoading();
      }
    );
  };

  useEffect(() => {
    setLoading(true);

    fetchRoles();
    fetchContracts();
    httpService.get(
      "/api/GeoData/countries",
      (res) => {
        setAllContries(res.data);

        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);

  const checkLoading = () => {
    if (roles.length > 0 && contracts.length > 0) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setGeneratedData([]);
    form.resetFields();
    setEmailError(null);
    onClose();
  };

  const clearForm = () => {
    setGeneratedData([]);
    setEmailError(null);
    form.resetFields();
  };

  const validateEmails = () => {
    const emails = form.getFieldValue("email");

    if (!emails || emails.trim() === "") {
      setEmailError("Please enter at least one email.");
      return false;
    }

    const emailList = emails.split(",").map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailList.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setEmailError(`Invalid emails found: ${invalidEmails.join(", ")}`);
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };

  const handleGenerate = async () => {
    if (!validateEmails()) {
      return;
    }

    try {
      const { email, contracts, role, country, office } =
        await form.validateFields([
          "email",
          "contracts",
          "role",
          "country",
          "office",
        ]);

      setLoading(true);

      const formData = form.getFieldValue();
      const emails = email ? email.split(",").map((email) => email.trim()) : [];

      const data = emails.map((email, index) => ({
        key: index,
        email,
        role: formData.role,
        contract: formData.contracts,
        country: formData.country,
        offices: office,
      }));

      await httpService.get(
        `/api/Office/getAll?IgnorePagination=true`,
        (res) => {
          data.forEach((element, index) => {
            setOfficesByRow((prev) => ({
              ...prev,
              [index]: res.data,
            }));
          });
        },
        (error) => console.log(error)
      );

      console.log("Generated Data:", data);
      setGeneratedData(data);
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (emailToDelete) => {
    const updatedPreviewData = generatedData.filter(
      (emailData) => emailData.email !== emailToDelete
    );
    setGeneratedData(updatedPreviewData);

    let currentEmails = form.getFieldValue("email");
    console.log("Current Emails:", currentEmails);
    let updatedEmails = currentEmails
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email !== emailToDelete);

    let finalEmails = updatedEmails.join(", ").replace(/,\s*$/, "");

    console.log("Final Emails:", finalEmails);
    form.setFieldsValue({ email: finalEmails });
  };

  const sendInvitations = async () => {
    if (!validateEmails()) {
      return;
    }
    setLoading(true);

    // Create an array of field names to validate
    const fieldsToValidate = generatedData
      .map((_, index) => [
        `contract-${index}`,
        `role-${index}`,
        `country-${index}`,
        `office-${index}`,
      ])
      .flat();

    form
      .validateFields(fieldsToValidate)
      .then((values) => {
        const payload = {
          users: generatedData.map((user, index) => ({
            email: user.email,
            officeLocations: form.getFieldValue(`office-${index}`) || [],
            position: user.position || "string",
            contractRoles: (form.getFieldValue(`contract-${index}`) || []).map(
              (contractId) => ({
                contractId,
                roleId: form.getFieldValue(`role-${index}`),
              })
            ),
          })),
        };

        httpService.post(
          "/api/User/addMembers",
          payload,
          (response) => {
            const message =
              response?.message ||
              "Members are added successfully, please check emails for validation code!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: `Members added successfully!`,
              text: message,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: ``,
              });
            }, 3000);
            form.resetFields();
            setLoading(false);
            if (response?.data?.length > 0) {
              handleMembersAdded(response.data);
            }
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "There was a problem adding this members to your database. Please try again later!";
            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: ``,
              });
            }, 3000);
            setLoading(false);
          }
        );
      })
      .catch((error) => {
        console.error("Validation Error:", error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: "Please fill all fields correctly!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
        setLoading(false);
      });
  };

  const handleRoleOfUserChange = (recordKey) => (selectedValue) => {
    setGeneratedData((prevData) => {
      const updatedData = prevData?.map((record) => {
        if (record.key === recordKey) {
          return { ...record, roleId: selectedValue };
        }
        return record;
      });
      return updatedData;
    });
  };

  const handleContractOfUserChange = (recordKey) => (selectedValues) => {
    setGeneratedData((prevData) => {
      const updatedData = prevData?.map((record) => {
        if (record.key === recordKey) {
          return { ...record, contractIds: selectedValues };
        }
        return record;
      });
      return updatedData;
    });
  };

  const handleCountryOfUserChange = (recordKey) => (selectedValue) => {
    const officeToReset = `office-${recordKey}`;
    form.setFieldsValue({ [officeToReset]: [] });

    if (selectedValue) {
      httpService.get(
        `/api/Office/getAll?IgnorePagination=true`,
        (res) => {
          setOfficesByRow((prev) => ({
            ...prev,
            [recordKey]: res.data,
          }));
        },
        (error) => console.log(error)
      );
    } else {
      setOfficesByRow((prev) => ({
        ...prev,
        [recordKey]: [],
      }));
    }
  };

  const handleOfficesOfUserChange = (recordKey) => (selectedValues) => {
    setGeneratedData((prevData) => {
      const updatedData = prevData?.map((record) => {
        if (record.key === recordKey) {
          return { ...record, offices: selectedValues };
        }
        return record;
      });
      return updatedData;
    });
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Form.Item
          name={`email-${record.key}`}
          style={{ margin: 0 }}
          initialValue={text}
        >
          <span>{text}</span> {/* Displaying email as text */}
        </Form.Item>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      minWidth: 200,
      key: "role",
      render: (text, record) => (
        <Form.Item
          name={`role-${record.key}`}
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please select a role!" }]}
          initialValue={text}
        >
          <Select
            onChange={handleRoleOfUserChange(record.key)}
            className="w-100"
            allowClear
            showSearch
            style={{ minWidth: 180 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {roles?.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.roleName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Contract",
      dataIndex: "contract",
      minWidth: 200,
      key: "contract",
      render: (text, record) => (
        <Form.Item
          name={`contract-${record.key}`}
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please select a contract!" }]}
          initialValue={text || []}
        >
          <Select
            mode="multiple"
            onChange={handleContractOfUserChange(record.key)}
            className="w-100"
            allowClear
            showSearch
            style={{ minWidth: 180 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {contracts?.map((contract) => (
              <Option key={contract.id} value={contract.id}>
                {contract.contractNumber}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      minWidth: 200,
      key: "country",
      render: (text, record) => (
        <Form.Item
          name={`country-${record.key}`}
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please select a country!" }]}
          initialValue={text}
        >
          <Select
            onChange={handleCountryOfUserChange(record.key)}
            className="w-100"
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
            style={{ minWidth: 180 }}
          >
            {allCountries?.map((country) => (
              <Option key={country.id} value={country.id}>
                {country.stateName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Offices",
      dataIndex: "offices",
      minWidth: 200,
      key: "offices",
      render: (text, record) => (
        <Form.Item
          name={`office-${record.key}`}
          style={{ margin: 0 }}
          rules={[{ required: true, message: "Please select an office!" }]}
          initialValue={text || []}
        >
          <Select
            onChange={(value) => {
              handleOfficesOfUserChange(record.key)(value);
            }}
            mode="multiple"
            className="w-100"
            allowClear
            showSearch
            style={{ minWidth: 180 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {officesByRow[record.key]?.map((office) => (
              <Option key={office.id} value={office.id}>
                {office.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      fixed: generatedData && generatedData.length > 0 ? "right" : undefined,
      key: "actions",
      render: (_, record) => (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <img
            className=" mx-2"
            src={deleteIcon}
            onClick={() => handleDelete(record.email)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  const fetchOfficeData = (countryIds) => {
    const params = new URLSearchParams();

    params.append("CountryIds", countryIds);

    httpService.get(
      `/api/Office/getAll?IgnorePagination=true`,
      (res) => {
        setOffices(res.data);
        setLoadingStates(false);
      },
      (error) => {
        console.log(error);
        setLoadingStates(false);
      }
    );
  };

  const handleCountryChange = (countryId) => {
    form.resetFields(["office"]);
    setGeneratedData([]);
    // resetFormExceptSpecificFields(form);
    setOffices([]);
    if (countryId) {
      setLoadingStates(true);
      fetchOfficeData(countryId);
    }
  };

  const handleOfficeSelectChange = () => {
    setGeneratedData([]);
    // resetFormExceptSpecificFields(form);
  };

  const handleRoleChange = () => {
    setGeneratedData([]);
    // resetFormExceptSpecificFields(form);
  };

  const handleContractsChange = () => {
    setGeneratedData([]);
    // resetFormExceptSpecificFields(form);
  };

  const handleInputChange = (e) => {
    setGeneratedData([]);
    // resetFormExceptSpecificFields(form);
    form.setFieldsValue({ email: e.target.value });
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <div className="memberInvitation">
            <h2 className="modalHeader">Invite Members</h2>

            <div
              style={{
                borderRadius: "6px",
                padding: "1px 0px 1px 10px",
                gap: "10px",
                background: "#FADCEA",
                marginBottom: "10px",
              }}
            >
              <h1
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  margin: 0,
                }}
              >
                Note: Please separate emails using a{" "}
                <span style={{ fontWeight: 700 }}>comma</span> and also{" "}
                <span style={{ fontWeight: 700 }}>do not</span> leave a comma in
                the end!
              </h1>
            </div>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter at least one email." },
              ]}
            >
              <h5 className="sectionLabel">Emails of members</h5>
              <div className="emailsInput">
                <h5>Paste here the emails of the members you want to invite</h5>
                <TextArea
                  // value={form.getFieldValue("email")}
                  onChange={handleInputChange} // Set the email value in form state
                  placeholder="Enter emails separated by commas..."
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              </div>
            </Form.Item>
            {emailError && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {emailError}
              </div>
            )}
            {emailError && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {emailError}
              </div>
            )}
          </div>

          <div className="details pt-4">
            <h5 className="sectionLabel">Details</h5>
          </div>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select
                  placeholder="Select Role"
                  allowClear
                  showSearch
                  value={form.role}
                  onChange={handleRoleChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {roles?.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="contracts"
                label="Contracts"
                rules={[
                  { required: true, message: "Please select contracts type" },
                ]}
              >
                <Select
                  value={form.contracts}
                  mode="multiple"
                  onChange={handleContractsChange}
                  placeholder="Select contract type"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {contracts?.map((contract) => (
                    <Option key={contract.id} value={contract.id}>
                      {contract.contractNumber}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <p className="modalsHeader1">Location Information</p>

          <Spin spinning={loadingStates}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: true, message: "Please select country" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select Country"
                    onChange={handleCountryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .startsWith(input.toLowerCase())
                    }
                  >
                    {allCountries?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.stateName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Office"
                  name="office"
                  rules={[
                    { required: true, message: "Please select an office" },
                  ]}
                >
                  <Select
                    disabled={!form.getFieldValue("country")}
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Select an office"
                    dropdownRender={(menu) => <>{menu}</>}
                    onChange={handleOfficeSelectChange}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().startsWith(input.toLowerCase())
                    }
                    options={offices?.map((office) => ({
                      label: office.name,
                      value: office.id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Spin>

          <div className="buttonsModal mt-2">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={clearForm}
            >
              Clear all fields
            </Button>
            <Button
              className="addButtonTPM"
              type="primary"
              onClick={handleGenerate}
            >
              Generate Members
            </Button>
          </div>

          {generatedData.length > 0 ? (
            <>
              <Divider />
              <div className="details pt-4">
                <h5 className="sectionLabel">Generated members</h5>
              </div>
              <Table
                className="pt-5"
                columns={columns}
                dataSource={generatedData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )}
                pagination={false}
              />
              <div className="d-flex justify-content-between align-items-center">
                <div className="emailCount">
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      <img src={numberOfMembers} alt="Number of members" />
                      <span style={{ marginLeft: "7px" }}>
                        {generatedData.length}
                      </span>
                    </>
                  )}
                </div>
                <Pagination
                  current={currentPage}
                  onChange={(page) => setCurrentPage(page)}
                  total={generatedData.length}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  className="pt-4 d-flex justify-content-end"
                />
              </div>
            </>
          ) : (
            ""
          )}

          {generatedData.length > 0 && (
            <>
              <Divider />
              <div className="buttonsModal ">
                <Button
                  className="inviteButtonTPM"
                  key="send"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="addButtonTPM"
                  key="send"
                  onClick={sendInvitations}
                >
                  Invite Members
                </Button>
              </div>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default InviteMember;
