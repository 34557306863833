import { Modal, Form, Input, Button, Divider, Typography, Spin } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import { useSiteVisitStore } from "./store/siteVisitStore";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import dayjs from "dayjs";

const { TextArea } = Input;

const { Title } = Typography;

const EditSiteVisitCardModal = ({
  visible,
  onCancel,
  onOk,
  siteVisitCardId,
  setMessage,
}) => {
  const [form] = Form.useForm();
  const [kills, setKills] = useState(1);
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [clusters, setClusters] = useState([]);
  const [singleTeam, setSingleTeam] = useState({});
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [photosLimit, setPhotosLimit] = useState(0);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [videosLimit, setVideosLimit] = useState(0);
  const [allIPPlans, setAllIPPlans] = useState([]);
  const [postponed, setPostponed] = useState(false);
  const [observationList, setObservationList] = useState(false);
  const [existingFiles, setExistingFiles] = useState({});
  const [siteContactsRequired, setSiteContactsRequired] = useState(false);
  const [selectedIpPlan, setSelectedIpPlan] = useState([]);
  const [showHideIpPlanDetails, setShowHideIpPlanDetails] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [parishCheck, setParishCheck] = useState(false);
  const { setSiteVisitList, setOtherDocuments, setSiteVideos } =
    useSiteVisitStore();

  const [SiteLoader, setSiteLoader] = useState(false);
  const [siteID, setSiteID] = useState("");

  const onClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onCancel();
    form.resetFields();
    setSiteVisitList([]);
    setOtherDocuments([]);
    setSiteVideos([]);
    setSingleTeam(null);
    setRequiredPhotos(false);
    setPhotosLimit(0);
    setRequiredVideos(false);
    setObservationList(false);
    setVideosLimit(0);
    setKills(1);
    setClusters([]);
    setSectors([]);
    setSelectedIpPlan("");
    setShowHideIpPlanDetails(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  const fetchIpPlanDetails = (ipPlanId) => {
    if (ipPlanId) {
      const apiIpPlan = `/api/IPPlan/getIPPlan?id=${ipPlanId}`;

      httpService.get(
        apiIpPlan,
        (res) => {
          setSelectedIpPlan(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  const fetchSectorData = async (clusterId) => {
    httpService.get(
      "/api/Site/getAllSectorsByCluster?clusterId=" + clusterId,
      (res) => setSectors(res.data),
      (error) => console.error("Error fetching data:", error)
    );
  };

  useEffect(() => {
    if (visible && siteVisitCardId) {
      setSiteLoader(true);
      httpService.get(
        `/api/Site/getSiteVisit?id=${siteVisitCardId}`,
        (res) => {
          setSiteLoader(false);
          setSiteID(res?.data?.id);

          const siteContact =
            res?.data?.visit?.contacts?.find(
              (contact) => contact?.contactType === 1
            ) || null;

          const pointContact =
            res?.data?.visit?.contacts?.find(
              (contact) => contact?.contactType === 2
            ) || null;

          const KIIContacts =
            res?.data?.visit?.contacts?.filter((e) => e?.contactType === 3) ||
            [];

          const KIIContactsLength = KIIContacts?.length;

          const plannedVisit = res?.data?.visit?.plannedVisit;
          setKills(KIIContactsLength || 0);
          const parishChecked = res?.data?.perish === "false" ? false : true;
          setParishCheck(parishChecked);
          setSiteContactsRequired(!!siteContact);
          const ipPlanId = res?.data?.ipPlan?.id;
          const clusterId = res?.data?.cluster?.id;
          const initialData = {
            siteContactsRequired: !!siteContact,
            siteContacts: siteContact
              ? {
                  ...siteContact,
                  id: siteContact.id,
                  contactName: siteContact.name,
                }
              : {},
            pointContacts: pointContact
              ? {
                  ...pointContact,
                  id: pointContact.id,
                  contactName: pointContact.name,
                }
              : {},
            KIIcontacts: KIIContacts?.map((e) => ({
              ...e,
              contactName: e.name,
            })),
            parishCheck: parishChecked,
            parish:
              res?.data?.perish !== "false" ? res?.data?.perish : undefined,
            IPlaningCard: ipPlanId,
            cluster: clusterId,
            sector: res?.data?.sector?.id,

            subSector: res?.data?.subSector?.id,
            projectId: projectId,
            latitude: res?.data?.locationDetails?.latitude,
            longitude: res?.data?.locationDetails?.longitude,
            isVirtual: res?.data?.isVirtual,
            dateOfPlannedVisit: dayjs(plannedVisit),

            ObservationListCheck: res?.data?.observationList,
            beneficiariesCount:
              res?.data?.visit?.interviewDetails?.beneficiariesCount,
            kills: KIIContactsLength || 0,
            observationChecklistCount:
              res?.data?.visit?.interviewDetails?.observationChecklist,
            observationChecklistType:
              res?.data?.visit?.interviewDetails?.observationChecklistType ||
              "",

            mediaLimit: res?.data?.visit?.interviewDetails?.mediaLimit,
            samplingInstructions:
              res?.data?.visit?.interviewDetails?.samplingInstructions,
            clientInstructions:
              res?.data?.visit?.interviewDetails?.clientInstructions,
            otherInstructions:
              res?.data?.visit?.interviewDetails?.otherInstructions,
            visitPlanApproval: res?.data?.visit?.visitPlanApproval,

            specificsOfOnSiteOperation: res?.data?.specificsOfOnSiteOperation,

            tpmCoordinator: res?.data?.logistic?.tpmCoordinator?.id,
            coordinationType: res?.data?.logistic?.coordinationType,
            fulcrumAccount: res?.data?.logistic?.fulcrumAccount || "",
            activationDate: res?.data?.logistic?.activationDate,
            isTeamLocal: res?.data?.logistic?.isTeamLocal,

            team: res?.data?.logistic?.team?.id,
            isPostponed: res?.data?.logistic?.isPostponed,
            postponedReason: res?.data?.logistic?.postponedReason,
            postponedDetails: res?.data?.logistic?.postponedDetails,
            notes: res?.data?.logistic?.notes,

            videosRequired: res?.data?.hasVideos || false,
            videosLimit: res?.data?.videoCount || 0,
            photosRequired: res?.data?.hasPhotos || false,
            photosLimit: res?.data?.photoCount || 0,
          };
          setSelectedIpPlan(ipPlanId);
          fetchIpPlanDetails(ipPlanId);
          fetchSectorData(clusterId);
          setPostponed(res?.data?.logistic?.isPostponed);
          setRequiredPhotos(res?.data?.hasPhotos || false);
          setPhotosLimit(res?.data?.photoCount || 0);
          setRequiredVideos(res?.data?.hasVideos || false);
          setVideosLimit(res?.data?.videoCount || 0);
          setObservationList(res?.data?.observationList || false);
          setSelectedSectorId(res?.data?.sector?.id);
          const teamId = res?.data?.logistic?.team?.id;
          if (teamId) {
            httpService.get(
              `/api/User/getTeamById?teamId=${teamId}`,
              (res) => {
                setSingleTeam(res?.data);
              },
              (err) => {
                console.log(err);
              }
            );
          }

          const files = res?.data?.siteVisitFiles;

          const formattedFiles = files?.map((item) => ({
            fileDataId: item.id,
            entityId: item.id,
            fileKey: item.fileName,
            filePath: item.filePath,
            fileCategory: item.fileCategory,
          }));
          console.log({
            report_documents:
              formattedFiles?.filter((e) => e?.fileCategory === 1) || [],
            other_files:
              formattedFiles?.filter((e) => e?.fileCategory === 2) || [],
            video_files:
              formattedFiles?.filter((e) => e?.fileCategory === 3) || [],
          });
          setExistingFiles((prevExistingFiles) => ({
            ...prevExistingFiles,
            report_documents:
              formattedFiles?.filter((e) => e?.fileCategory === 1) || [],
            other_files:
              formattedFiles?.filter((e) => e?.fileCategory === 2) || [],
            video_files:
              formattedFiles?.filter(
                (e) => e?.fileCategory === 3 || e?.fileCategory === 4
              ) || [],
          }));

          setInitialValues(initialData);
          form.setFieldsValue(initialData);
        },
        (err) => {
          setSiteLoader(false);
          console.log(err);
        }
      );
    }
  }, [visible]);

  // console.log("existingFiles:", existingFiles);

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = dayjs(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);
    await form
      .validateFields()
      .then((values) => {
        const plannedVisit = `${formatDate(values.dateOfPlannedVisit)}`;
        const activationDate = formatDate(
          values.dateOfPlannedVisit,
          "activation"
        );

        let siteContacts = values.siteContacts ? [values.siteContacts] : [];
        let pointContacts = values.pointContacts ? [values.pointContacts] : [];
        let KIIcontacts = Array.isArray(values.KIIcontacts)
          ? values.KIIcontacts
          : [];

        siteContacts?.map((contact) => (contact.contactType = 1));
        pointContacts?.map((contact) => (contact.contactType = 2));
        KIIcontacts?.map((contact) => (contact.contactType = 3));

        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];

        const formData = new FormData();
        formData.append("Id", siteID);
        formData.append("IPPlanId", values.IPlaningCard);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector || "");
        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude);
        formData.append("LocationDetails.Longitude", values.longitude);
        formData.append("Perish", values.parish || false);
        formData.append("isVirtual", values.isVirtual);
        formData.append(
          "Visit.PlannedVisit",
          plannedVisit || "2024-10-03T14:44:40.891Z"
        );

        createContacts?.forEach((contact, index) => {
          console.log("contact", contact);
          console.log("contact.id", contact.id);
          if (contact?.id) {
            formData.append(`Visit.Contacts[${index}].id`, contact.id);
          }
          formData.append(`Visit.Contacts[${index}].name`, contact.contactName);
          formData.append(`Visit.Contacts[${index}].email`, contact.email);
          formData.append(`Visit.Contacts[${index}].role`, contact.role);
          formData.append(
            `Visit.Contacts[${index}].phone`,
            contact.phone || ""
          );
          formData.append(
            `Visit.Contacts[${index}].skype`,
            contact.skype || ""
          );
          formData.append(
            `Visit.Contacts[${index}].organization`,
            contact.organization || ""
          );
          formData.append(
            `Visit.Contacts[${index}].contactType`,
            contact.contactType
          );
        });

        formData.append("ObservationList", values.observationList || false);
        formData.append(
          "Visit.InterviewDetails.BeneficiariesCount",
          values?.beneficiariesCount || 0
        );
        formData.append("Visit.InterviewDetails.ClientsCount", values.kills);
        formData.append(
          "Visit.InterviewDetails.ObservationChecklist",
          values.observationChecklistCount || 0
        );

        formData.append(
          "Visit.InterviewDetails.ObservationChecklistType",
          (values.observationChecklistType = "")
        );
        formData.append(
          "Visit.InterviewDetails.SamplingInstructions",
          values.samplingInstructions || ""
        );
        formData.append(
          "Visit.InterviewDetails.ClientInstructions",
          values.clientInstructions || ""
        );
        formData.append(
          "Visit.InterviewDetails.OtherInstructions",
          values.otherInstructions || ""
        );
        formData.append(
          "Visit.VisitPlanApproval",
          values.visitPlanApproval || ""
        );

        formData.append(
          "specificsOfOnSiteOperation",
          values.specificsOfOnSiteOperation
        );
        formData.append(
          "ObservationList",
          values.ObservationListCheck || false
        );

        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);
        formData.append(
          "Logistic.ActivationDate",
          activationDate || "2024-10-03T14:44:40.891Z"
        );
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed || false);
        formData.append(
          "Logistic.PostponedReason",
          values.postponedReason || ""
        );
        formData.append(
          "Logistic.PostponedDetails",
          values.postponedDetails || ""
        );
        formData.append("Logistic.Notes", values.notes || "");

        formData.append("HasVideos", values.videosRequired || false);
        formData.append("VideoCount", values.videosLimit || 0);

        formData.append("HasPhotos", values.photosRequired || false);
        formData.append("PhotoCount", values.photosLimit || 0);

        const existingFilesLength =
          (existingFiles?.report_documents?.length || 0) +
          (existingFiles?.other_files?.length || 0) +
          (existingFiles?.video_files?.length || 0);

        // Append existing files
        existingFiles?.report_documents?.forEach((file, index) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 1);
          formData.append(`UpdateFiles[${index}].EntityId`, file.entityId);
          formData.append(`UpdateFiles[${index}].Id`, file.entityId);
          formData.append(`UpdateFiles[${index}].FileKey`, file.filePath);
        });

        existingFiles?.other_files?.forEach((file, index) => {
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length + index
            }].FileCategory`,
            2
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length + index
            }].EntityId`,
            file.entityId
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length + index
            }].Id`,
            file.entityId
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length + index
            }].FileKey`,
            file.filePath
          );
        });

        const existingVides = [];
        const existingImages = [];

        existingFiles?.video_files?.forEach((file) => {
          if (file.fileKey.endsWith([".mp4"])) {
            existingVides.push(file);
          } else {
            existingImages.push(file);
          }
        });

        existingVides?.forEach((file, index) => {
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].FileCategory`,
            3
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].EntityId`,
            file.entityId
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].Id`,
            file.entityId
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].FileKey`,
            file.filePath
          );
        });

        existingImages?.forEach((file, index) => {
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              existingVides?.length +
              index
            }].FileCategory`,
            4
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].EntityId`,
            file.entityId
          );
          formData.append(
            `UpdateFiles[${
              existingFiles?.report_documents?.length +
              existingFiles?.other_files?.length +
              index
            }].FileKey`,
            file.filePath
          );
        });

        // Append new files
        const reportDocs = values.report_documents || [];
        const otherFiles = values.other_files || [];
        const videoFiles = values.video_files || [];

        reportDocs?.forEach((file, index) => {
          const newIndex = existingFilesLength + index;
          formData.append(`UpdateFiles[${newIndex}].FileCategory`, 1);
          formData.append(`UpdateFiles[${newIndex}].file`, file.originFileObj);
        });

        otherFiles?.forEach((file, index) => {
          const newIndex = existingFilesLength + reportDocs.length + index;
          formData.append(`UpdateFiles[${newIndex}].FileCategory`, 2);
          formData.append(`UpdateFiles[${newIndex}].file`, file.originFileObj);
        });

        const videosUploaded = [];
        const imagesUploaded = [];
        videoFiles?.forEach((file) => {
          if (file.type.startsWith("image")) {
            imagesUploaded.push(file);
          } else if (file.type.startsWith("video")) {
            videosUploaded.push(file);
          }
        });

        videosUploaded?.forEach((file, index) => {
          const newIndex =
            index +
            reportDocs?.length +
            otherFiles?.length +
            existingFilesLength;
          formData.append(`UpdateFiles[${newIndex}].FileCategory`, 3);
          formData.append(`UpdateFiles[${newIndex}].file`, file.originFileObj);
        });

        imagesUploaded?.forEach((file, index) => {
          const newIndex =
            index +
            reportDocs?.length +
            otherFiles?.length +
            videosUploaded?.length +
            existingFilesLength;
          formData.append(`UpdateFiles[${newIndex}].FileCategory`, 4);
          formData.append(`UpdateFiles[${newIndex}].file`, file.originFileObj);
        });

        httpService.put(
          "/api/Site/updateSiteVisit",
          formData,
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: "Site visit card updated successfully!",
              text: "You have successfully modified the site visit",
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setSubmitLoader(false);
            form.resetFields();
            onOk();
          },
          (error) => {
            let errorMessage =
              error?.errors?.[0]?.message ||
              "There was a problem updating this site visit card!";

            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Failed to update this site visit card",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setSubmitLoader(false);
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Submission Failed:", errorInfo);
        scrollToField(errorInfo);

        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Failed to update this site visit card",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name, {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit site visit card</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button key="back" onClick={onClose} className="inviteButtonTPM">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={SiteLoader}>
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item label="Site visit card identifier" name="identifier">
            <Input
              placeholder="### - Namegoeshere - Location - date"
              disabled
            />
          </Form.Item>
          <GeneralDetails
            form={form}
            selectedSectorId={selectedSectorId}
            setSelectedSectorId={setSelectedSectorId}
            clusters={clusters}
            setClusters={setClusters}
            allIPPlans={allIPPlans}
            setAllIPPlans={setAllIPPlans}
            selectedIpPlan={selectedIpPlan}
            setSelectedIpPlan={setSelectedIpPlan}
            showHideIpPlanDetails={showHideIpPlanDetails}
            setShowHideIpPlanDetails={setShowHideIpPlanDetails}
            sectors={sectors}
            setSectors={setSectors}
          />
          <Divider />
          <h3>Location details</h3>
          <LocationDetails
            form={form}
            isEditing={true}
            onClose={onCancel}
            parishCheck={parishCheck}
            setParishCheck={setParishCheck}
          />

          <Divider />
          <h3>Visit details</h3>

          <VisitDetails
            form={form}
            kills={kills}
            setKills={setKills}
            requiredPhotos={requiredPhotos}
            setRequiredPhotos={setRequiredPhotos}
            requiredVideos={requiredVideos}
            setRequiredVideos={setRequiredVideos}
            siteContactsRequired={siteContactsRequired}
            setSiteContactsRequired={setSiteContactsRequired}
            setVideosLimit={setVideosLimit}
            videosLimit={videosLimit}
            photosLimit={photosLimit}
            setPhotosLimit={setPhotosLimit}
            observationList={observationList}
            setObservationList={setObservationList}
          />

          <Divider />
          <h3>Logistics</h3>

          <Logistics />

          <Divider />
          <h3>Team</h3>
          <TeamDetails singleTeam={singleTeam} setSingleTeam={setSingleTeam} />

          <Divider />
          <h3>Postponement</h3>
          <Postponement postponed={postponed} setPostponed={setPostponed} />

          <Divider />
          <h3>Resources</h3>

          <ResourcesDetails
            form={form}
            requiredPhotos={requiredPhotos}
            photosLimit={photosLimit}
            requiredVideos={requiredVideos}
            videosLimit={videosLimit}
            existingFiles={existingFiles}
            setExistingFiles={setExistingFiles}
            setMessage={setMessage}
            setRequiredPhotos={setRequiredPhotos}
            setRequiredVideos={setRequiredVideos}
          />

          {/* <Divider /> */}

          {/* <h3>Tracking</h3>
          <TrackingDetails /> */}

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default EditSiteVisitCardModal;
