import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Select, Divider } from "antd";
import "../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../services/httpService.service";
import AddMemberIcon from "./../../../../../Assets/svg/addMemberIcon.svg";

const { Option } = Select;

const AddDriverCardModal = ({ visible, onClose, setMessage, fetchDrivers }) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    httpService.get(
      `/api/GeoData/countries`,
      (response) => {
        setCountries(response.data);
      },
      (error) => {
        console.error("Error fetching teams:", error);
      }
    );
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const currentContractId = localStorage.getItem("currentContractId");

        const payload = {
          name: values.driverName,
          phoneNumber: values.driverPhoneNumber,
          vehicleInfo: values.vehicleInfo,
          countryId: values.driverBaseLocation,
          license: 1,
          contractId: currentContractId,
        };

        httpService.post(
          "/api/Driver",
          payload,
          (response) => {
            const message =
              response.message ||
              "A new driver has been created and added to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Driver added successfully!",
              text: message,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            form.resetFields();
            onClose();
            fetchDrivers();
          },
          (error) => {
            const messageError = error?.errors?.[0]?.message;
            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text:
                messageError ||
                "We had a problem adding this red flag to your database, please try again.",
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
          }
        );
      })
      .catch(() => {
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Validation Error",
          text: "Please fill in all required fields correctly.",
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Driver card</h2>
      <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">General details</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="driverName"
            label="Driver Name"
            rules={[{ required: true, message: "Please enter name" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter driver name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="driverPhoneNumber"
            label="Driver phone number"
            rules={[{ required: true, message: "Enter the phone number" }]}
            style={{ width: "48%" }}
          >
            <PhoneInput
              onChange={(value, country) => console.log(country)}
              country={"us"}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="vehicleInfo"
            label="Driver Vehicle Info"
            rules={[{ required: true, message: "Enter the vehicle info" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="driverBaseLocation"
            label="Driver Base Location"
            rules={[{ required: true, message: "Enter the location here" }]}
            style={{ width: "48%" }}
          >
            {/* <Input placeholder="Enter Name" autoComplete="off" /> */}
            <Select
              placeholder="Select a country"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Divider />
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Add driver card
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddDriverCardModal;
