import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Spin } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";
import "react-phone-input-2/lib/style.css";
import IconStrategy from "../../../Assets/svg/iconStrategy.svg";
import showConfirm from "../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;
const { TextArea } = Input;

const NewProject = ({ visible, onClose, onSubmit, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllContries] = useState();
  const [clients, setClients] = useState();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setCities([]);
    setStates([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const fetchCitiesData = (stateIds) => {
    setLoadingStates(true);
    const params = new URLSearchParams();
    stateIds.forEach((id) => params.append("stateIds", id));

    httpService.get(
      `/api/GeoData/cities?${params}`,
      (res) => {
        setCities(res.data);
        setLoadingStates(false);
      },
      (error) => {
        console.log(error);
        setLoadingStates(false);
      }
    );
  };

  const handleStateChange = (stateId) => {
    form.resetFields(["cityId"]);
    setCities([]);
    if (stateId && stateId?.length > 0) {
      fetchCitiesData(stateId);
    }
  };

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId");
    const apiCountries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;

    httpService.get(
      apiCountries,
      (res) => setAllContries(res.data),
      (error) => console.log(error)
    );

    const apiContracts = `/api/Contract/getClients?contractId=${contractId}`;

    httpService.get(
      apiContracts,
      (res) => setClients(res.data),
      (error) => console.log(error)
    );
  }, []);

  const [form] = Form.useForm();
  const id = localStorage.getItem("currentContractId");

  const handleCountryChange = (countryId) => {
    form.setFieldsValue({ state: [] });
    setStates([]);
    setCities([]);
    if (countryId) {
      setLoadingStates(true);
      httpService.get(
        `/api/GeoData/states?countryIds=${countryId}`,
        (res) => {
          setStates(res.data);

          setLoadingStates(false);
        },

        (error) => {
          console.log(error);
          setLoadingStates(false);
        }
      );
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const requestBody = {
          projectName: values.projectName,
          projectNumber: values.projectNumber,
          description: values.projectDescription || "",
          countryId: values.countryId,
          contractId: id,
          clients: values.clients,
          cityIds: values.cityId,
          stateIds: values.state,
          clientFocalPoint: values.clientFocalPoint,
          clientFocalPointEmail: values.clientFocalPointEmail.toLowerCase(),
          referenceDocuments: values.referenceDocuments || "",
          linkToSharedDrive: values.linkToSharedDrive,
        };

        httpService.post(
          "/api/projects",
          requestBody,
          (response) => {
            const message =
              response.message ||
              "A new project has been created and added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: IconStrategy,
              title: "Project added successfully!",
              text: message,
            });
            form.resetFields();
            onSubmit();
            handleLeave();
            setLoading(false);
          },
          (error) => {
            console.error("There was an error adding the project!", error);
            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this project from your database, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: IconStrategy,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });

    setMessage({
      trigger: false,
      type: "",
      icon: null,
      title: "",
      text: "",
    });
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">New project round</h2>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Project Details</p>
        <Form.Item
          name="projectName"
          label="Project Round Name"
          rules={[
            { required: true, message: "Please enter project round name" },
          ]}
        >
          <Input placeholder="Enter project round name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="projectNumber"
          label="Project Round Code"
          rules={[
            { required: true, message: "Please enter project round code" },
          ]}
        >
          <Input placeholder="Enter project round code" autoComplete="off" />
        </Form.Item>
        <Spin spinning={loadingStates}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="countryId"
              label="Country"
              tooltip="The list of countries displayed here comes from the countries you selected when creating the contract. The country you select now will be shown in this project."
              rules={[{ required: true, message: "Please select country" }]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Country"
                showSearch
                allowClear
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {allCountries &&
                  allCountries.map((country) => (
                    <Option key={country.id} value={country.id}>
                      {country.stateName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="state"
              tooltip="The list of states you select now will be shown in this project."
              label={
                cities.length < 1
                  ? "Governorate/State or city"
                  : "Governorate/State"
              }
              rules={[
                {
                  required: true,
                  message: "Please select a governorate/state or a city",
                },
              ]}
              className={states.length < 1 ? "d-none" : ""}
              style={{ width: "48%" }}
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                loading={!states}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
                placeholder={
                  states?.length < 1
                    ? "Select a country first!"
                    : "Select governorate/state"
                }
                onChange={handleStateChange}
                disabled={states?.length < 1}
                // mode="multiple"
              >
                {!states ? (
                  <Option>
                    <Spin></Spin>
                  </Option>
                ) : (
                  states.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.stateName}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="cityId"
              tooltip="The list of cities you select now will be shown in this project."
              label="City/Disctrict/Municipality:"
              rules={[
                {
                  required: cities.length > 1,
                  message: "Please select a city",
                },
              ]}
              style={{ width: "48%" }}
              className={cities.length < 1 ? "d-none" : ""}
            >
              <Select
                mode="multiple"
                placeholder={
                  cities?.length < 1 ? "Select a state first!" : "Select a city"
                }
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Spin>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="clients"
            label="Clients"
            rules={[{ required: true, message: "Please select a client" }]}
            style={{ width: "48%" }}
          >
            <Select
              placeholder="Select Clients"
              mode="multiple"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {clients &&
                clients.map((client) => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: "48%" }}
            name="clientFocalPoint"
            label="Client Focal Point"
            rules={[
              { required: true, message: "Please enter a client focal point" },
            ]}
          >
            <Input placeholder="Enter client focal point" autoComplete="off" />
          </Form.Item>
        </div>
        <Form.Item
          name="clientFocalPointEmail"
          label="Client Focal Point Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            placeholder="Enter client focal point email"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item name="projectDescription" label="Project Description">
          <TextArea placeholder="Enter project description" rows={4} />
        </Form.Item>
        <Form.Item
          name="referenceDocuments"
          label="Links to reference documents"
        >
          <TextArea placeholder="Enter links to reference documents" rows={4} />
        </Form.Item>
        <Form.Item
          name="linkToSharedDrive"
          label="Link to Shared Drive"
          tooltip="Please give a valid link ex: http://... or http://... or www..."
          rules={[
            {
              required: false,
              message: "Please enter a link to the shared drive",
            },
            {
              pattern: new RegExp(
                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
              ),
              message: "Please enter a valid URL",
            },
          ]}
        >
          <Input
            placeholder="http://www.link-shared-drive.com"
            autoComplete="off"
          />
        </Form.Item>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
            disabled={loading}
          >
            Create project round
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NewProject;
