import { Select, DatePicker, Input, Button, List, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "../../reporting/Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg";
import PreviewModal from "../ReportingList/PreviewModal";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";

function ReportingMap() {
  const [loading, setLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [data, setData] = useState([]);

  const handlePreviewClicked = (item) => {
    setClickedTaskId(item?.parentId);
    setShowPreviewModal(true);
  };

  const { Option } = Select;

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    const apiUrl = `/api/Site/getResourcesForSiteVisitsAndIPPlans?projectId=${projectId}&pageSize=${10}&pageNumber=${1}&SiteVisits=true&IPPlans=false`;
    setLoading(true);
    httpService.get(apiUrl, (res) => {
      setData(res.data);
    });

    setLoading(false);
  }, [pagination.pageNumber, pagination.pageNumber, pagination.total]);

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString?.split(pattern)[1];

    if (match) {
      return match;
    } else {
      return null;
    }
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <Preview
          visible={showPreviewModal}
          onClose={handleTogglePreviewModal}
          siteVisitCardId={clickedTaskId}
        />
        <span className="tableViewHeaderText">Last 10 uploaded resources</span>
        <div className="listDesign mt-5">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => handlePreviewClicked(item)}
                    className="buttonsList"
                  >
                    <img src={previewList} alt="Preview" />
                  </Button>,
                ]}
                style={{ width: "100%" }}
              >
                <List.Item.Meta
                  title={
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: "700" }} className="titleText">
                        {extractName(item.fileName) || item.fileName}
                      </span>
                      <span className="titleText">{item.identifier}</span>
                    </div>
                  }
                />
              </List.Item>
            )}
            // pagination={{
            //   pageNumber: pagination.pageNumber,
            //   pageSize: pagination.pageSize,
            //   total: pagination.total,
            //   onChange: handlePageChange,
            // }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </Spin>
  );
}

export default ReportingMap;
