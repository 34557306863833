import { Select, Table, Input, Button, List, Badge } from "antd";
import React, { useState } from "react";
// import "../Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import "../Completed.scss";
import CompletedMap from "./Map";
import ReportingMap from "./ReportingMap";


function VisitsMap() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { Option } = Select;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div className="reportingListDiv">
      <div className="visitsMap">
        <span className="tableViewHeaderText">Visits map</span>
        <div className="visitsMapContainer">
          {/* <div className="left">
            {/* List of States/Sectors/IPs */}
            {/* <List
              header={<div>States/Sectors/IPs</div>}
              bordered
              dataSource={data1}
              renderItem={(item) => (
                <List.Item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{item}</span>
                <Badge count={5} style={{ backgroundColor: '#D42177' }} />
              </List.Item>
              )}
            />
          </div> */}
          <CompletedMap />
        </div>
      </div>
      <ReportingMap></ReportingMap>

      {/* <div className="listDesign">
        <List
          itemLayout="horizontal"
          dataSource={paginatedData}
          renderItem={(item) => (
            <List.Item style={{ width: "100%" }}>
              <List.Item.Meta
                title={
                  <span className="titleText">{` ${item.ipName} + ${item.location} + ${item.date}`}</span>
                }
              />
            </List.Item>
          )}
          pagination={{
            current: currentPage,
            pageSize: itemsPerPage,
            total: data.length,
            onChange: handlePageChange,
          }}
          style={{ width: "100%" }}
        />
      </div> */}
    </div>
  );
}

export default VisitsMap;
