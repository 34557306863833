import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Divider,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { httpService } from "../../../../../../services/httpService.service";

const { Option } = Select;

const Edit = ({ visible, onClose, driverId, setMessage, fetchDrivers }) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    httpService.get(
      `/api/GeoData/countries`,
      (response) => {
        setCountries(response.data);
      },
      (error) => {
        console.error("Error fetching teams:", error);
      }
    );
  }, []);

  useEffect(() => {
    setLoadingPage(true);
    if (driverId) {
      const params = { driverId };
      const queryParams = new URLSearchParams(params).toString();

      httpService.get(
        `/api/Driver?${queryParams}`,
        (response) => {
          const driver = response?.data;
          form.setFieldsValue({
            driverName: driver?.name,
            driverPhoneNumber: driver?.phoneNumber,
            vehicleInfo: driver?.vehicleInfo,
            driverBaseLocation: driver?.baseLocation?.id,
          });
          setLoadingPage(false);
        },
        (error) => {
          console.error("Error fetching driver data:", error);
          setLoadingPage(false);
        }
      );
    }
  }, [driverId, form]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const payload = {
        id: driverId,
        name: values.driverName,
        phoneNumber: values.driverPhoneNumber,
        vehicleInfo: values.vehicleInfo,
        countryId: values.driverBaseLocation,
        license: 1,
      };

      httpService.put(
        "/api/Driver",
        payload,
        (response) => {
          const message = response.message || "Driver updated successfully!";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Driver added successfully!",
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          form.resetFields();
          onClose();
          fetchDrivers();
        },
        (error) => {
          const messageError = error?.errors?.[0]?.message;
          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text:
              messageError ||
              "We had a problem deleting this driver from your database, please try again.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        }
      );
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit driver information</h2>
      <Form form={form} layout="vertical">
        {" "}
        <Spin spinning={loadingPage}>
          <p className="modalsHeader1">General details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="driverName"
              label="Driver Name"
              rules={[{ required: true, message: "Please enter name" }]}
              style={{ width: "48%" }}
            >
              <Input placeholder="Enter driver name" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="driverPhoneNumber"
              label="Driver phone number"
              rules={[{ required: true, message: "Enter the phone number" }]}
              style={{ width: "48%" }}
            >
              <PhoneInput country={"us"} />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="vehicleInfo"
              label="Driver Vehicle Info"
              rules={[{ required: true, message: "Enter the vehicle info" }]}
              style={{ width: "48%" }}
            >
              <Input placeholder="Enter Name" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="driverBaseLocation"
              label="Driver Base Location"
              rules={[{ required: true, message: "Enter the location here" }]}
              style={{ width: "48%" }}
            >
              {/* <Input placeholder="Enter Name" autoComplete="off" /> */}
              <Select
                placeholder="Select a country"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
            >
              Save Progress
            </Button>
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default Edit;
