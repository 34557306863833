import { Button, Input, message } from "antd";

import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";

import { Form, Select, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { httpService } from "../../../services/httpService.service";

const { Option } = Select;
//months
const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

//years
const years = [
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
];

const implementingPartners = [
  "6FE4F20C-6AF6-4A09-92F7-14C41ACFFC56",
  "DA2C6BD6-F477-448E-BD67-190C2BF1315F",
  "695A9074-8230-4870-83FC-6D6F6DEFD26E",
  "F146B89D-E36D-4F85-87F9-954F0A54BA34",
  "CD11E9C8-4347-424D-BF8A-B638105524E8",
  "1261D65F-EA55-48D4-9402-E00856E7B60B",
  "12CF1789-00B0-4138-A9EF-F25334BEF497",
  "0A3D28AE-DAD4-4C11-82C0-F51BF8BC17F9",
];

const ipStatusses = [
  {
    id: 1,
    status: "Delivered",
  },
  {
    id: 2,
    status: "Post-data collection",
  },
  {
    id: 3,
    status: "Finalization",
  },
  {
    id: 4,
    status: "Data collection",
  },
  {
    id: 5,
    status: "Closed",
  },
];
const FilterComponent = ({}) => {
  const [selectsVisible, setSelectsVisible] = useState(true);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  const toggleSelects = () => {
    setSelectsVisible(!selectsVisible);
  };

  const handleClearAll = () => {
    form.resetFields();
    // setFilters({
    //   ImplementingPartnerId: "",
    //   IPStatus: "",
    //   AssignedTo: "",
    //   Month: "",
    //   Year: "",
    //   Search: ""
    // })
  };

  //handle filters change

  const handleSearch = (e) => {
    const Search = e.target.value;
    // setFilters((prevFilters) => ({ ...prevFilters, Search }));
  };

  const handleIP = (ImplementingPartnerId) => {
    // setFilters((prevFilters) => ({ ...prevFilters, ImplementingPartnerId }));
  };

  const handleiIpStatus = (IPStatus) => {
    // setFilters((prevFilters) => ({ ...prevFilters, IPStatus }));
  };

  const handleUser = (user) => {
    // setFilters((prevFilters) => ({ ...prevFilters, AssignedTo: user }));
  };

  const handleMonth = (Month) => {
    // setFilters((prevFilters) => ({ ...prevFilters, Month }));
  };

  const handleYear = (Year) => {
    // setFilters((prevFilters) => ({ ...prevFilters, Year }));
  };

  useEffect(() => {
    const apiUsers = "/api/User/getAllusers";

    httpService.get(
      apiUsers,
      (res) => setUsers(res.data),
      (error) => console.log(error)
    );
  }, []);

  return (
    <>
      <div className="filter-container">
        <div className="search-section">
          <h5>Search</h5>
        </div>
        <div className="search-filters">
          <div className="filter-btn">
            <Button className="clear-allBTN" onClick={handleClearAll}>
              Clear all
            </Button>
            <Button className="hide-filters-btn" onClick={toggleSelects}>
              {selectsVisible ? "Hide filters" : "Show filters"}
              {selectsVisible ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </div>
          <Search
            placeholder="Search a visit by its name"
            enterButton="Search"
            size="large"
            // value={filters.Search}
            // onChange={handleSearch}
            style={{ width: "100%" }}
          />
        </div>

        {selectsVisible && (
          <div>
            <Form layout="vertical" form={form}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Implementing Partner"
                    name="implementingPartner"
                  >
                    <Select
                      placeholder="Select the IP"
                      allowClear
                      onChange={handleIP}
                    >
                      {implementingPartners &&
                        implementingPartners.map((ip, index) => (
                          <Option key={index} value={ip}>
                            Partner {index + 1}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Current IP Status" name="currentIpStatus">
                    <Select
                      placeholder="Select an IP status"
                      onChange={handleiIpStatus}
                      allowClear
                    >
                      {ipStatusses &&
                        ipStatusses.map((ipstatus) => (
                          <Option key={ipstatus.id} value={ipstatus.id}>
                            {ipstatus.status}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Assigned to" name="assignedTo">
                    <Select
                      allowClear
                      onChange={handleUser}
                      placeholder="Select the assigned person"
                    >
                      {users &&
                        users.map((user) => (
                          <Option key={user.id} value={user.id}>
                            {user.email}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Month" name="month">
                    <Select
                      onChange={handleMonth}
                      placeholder="Select a month"
                      allowClear
                    >
                      {months &&
                        months.map((month) => (
                          <Option key={month.id} value={month.id}>
                            {month.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Year" name="year">
                    <Select
                      onChange={handleYear}
                      placeholder="Select a year"
                      allowClear
                    >
                      {years &&
                        years.map((year) => (
                          <Option key={year} value={year}>
                            {year}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};
export default FilterComponent;
