import axios from "axios";
import AuthService from "./authentication.service";

const { REACT_APP_API_BASE_URL } = process.env;

const https = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});

export const setupInterceptor = () => {
  https.interceptors.request.use((config) => {
    const token = AuthService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = REACT_APP_API_BASE_URL;
    return config;
  });

  https.interceptors.response.use(
    (response) => {
      if (response?.data) {
        return response;
      } else {
        return Promise.reject(new Error("Unexpected response format"));
      }
    },
    (error) => {
      if (!navigator.onLine) {
        return Promise.reject(new Error("No internet connection"));
      }

      if (error?.response?.status === 401) {
        AuthService.logout();
      }

      return Promise.reject(error.response?.data || error);
    }
  );
};

export default https;
