import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Divider,
  Space,
  Button,
  Spin,
  Card,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";
import PreviewModal from "../../modals/PreviewModal";
const { Option } = Select;
const customSpinner = (
  <LoadingOutlined style={{ fontSize: 20, color: "var(--2, #AA1A5F)" }} spin />
);

const GeneralDetails = ({
  form,
  onClose,
  selectedSectorId,
  setSelectedSectorId,
  clusters,
  setClusters,
  allIPPlans,
  setAllIPPlans,
  selectedIpPlan,
  showHideIpPlanDetails,
  setShowHideIpPlanDetails,
  setSelectedIpPlan,
  sectors,
  setSectors,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [subSectors, setSubSectors] = useState([]);

  const [ipLoader, setIpLoader] = useState(false);

  const [newSector, setNewSector] = useState("");
  const [newSubSector, setNewSubSector] = useState("");
  const [selectedClusterId, setSelectedClusterId] = useState("");
  const [addingSector, setAddingSector] = useState(false);
  const [addingSubSector, setAddingSubSector] = useState(false);
  const contractID = localStorage.getItem("currentProjectID");

  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedSectorId();
  }, [onClose]);

  useEffect(() => {
    if (selectedSectorId) {
      setSelectedSectorId(selectedSectorId);
    }
  }, [selectedSectorId]);

  const addSector = async (e) => {
    e.preventDefault();
    if (newSector) {
      setLoading(true);
      httpService.post(
        "/api/Site/createSector",
        { name: newSector, clusterId: selectedClusterId },
        (response) => {
          const message =
            response.message ||
            `A new sector has been created and added to your database!`;
          fetchSectorData(selectedClusterId);
          setNewSector("");
          setAddingSector(false);
          setLoading(false);
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: `Sector added successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        },
        (error) => {
          setLoading(false);
          const errorMessageCluster = selectedClusterId
            ? `We had a problem adding this sector to your database, please try again.`
            : "Select a cluster first!";

          const errorMessage =
            error?.errors?.[0]?.message || errorMessageCluster;

          console.error(`Error adding new item to:`, error);
          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        }
      );

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addSubSector = async (e) => {
    e.preventDefault();
    if (newSubSector && selectedSectorId) {
      setLoading(true);
      httpService.post(
        "/api/Site/createSubSector",
        {
          name: newSubSector,
          sectorId: selectedSectorId,
        },
        (response) => {
          const message =
            response.message ||
            `A new subsector has been created and added to your database!`;
          fetchSubSectorData();
          setNewSubSector("");
          setAddingSubSector(false);
          setLoading(false);
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: `Subsector added successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        },
        (error) => {
          setLoading(false);
          const errorMessage =
            error?.errors?.[0]?.message ||
            `We had a problem adding this subsector to your database, please try again.`;

          console.error(`Error adding new item to:`, error);
          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        }
      );

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClusterSelectChange = (id) => {
    form.setFieldsValue({
      sector: undefined,
      subSector: undefined,
    });
    setSelectedClusterId(id);
    fetchSectorData(id);
  };

  const handleSectorSelectChange = (id) => {
    form.setFieldsValue({
      subSector: undefined,
    });
    setSelectedSectorId(id);
  };

  const fetchSectorData = async (clusterId) => {
    setLoading(true);
    httpService.get(
      "/api/Site/getAllSectorsByCluster?clusterId=" + [clusterId],
      (res) => setSectors(res.data),
      (error) => console.error("Error fetching data:", error)
    );

    setLoading(false);
  };

  // const fetchImplementingPartners = async () => {
  //   try {
  //     const response = await get(`/api/ImplementingPartner/getAllImplementingPartners?contractId=${contractID}`);
  //     setAllImplementingPartners(response?.data);
  //   } catch (err) {
  //     console.log('error fetching implementing partners', err);
  //   }
  // }

  useEffect(() => {
    fetchSectorData();
    fetchIPPlaningCards();
  }, []);

  const seeIpPlanDetails = () => {
    setShowHideIpPlanDetails(!showHideIpPlanDetails);
  };

  const fetchSubSectorData = async () => {
    setNewSubSector(null);

    setLoading(true);
    httpService.get(
      "/api/Site/getAllSubsectors",
      (res) => {
        const filteredBySector = res.data.filter(
          (item) => item.sectorId === selectedSectorId
        );
        setSubSectors(filteredBySector);
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
    setLoading(false);
  };

  const fetchIPPlaningCards = async () => {
    setIpLoader(true);

    httpService.get(
      `/api/IPPlan/getAllIPPlans?ProjectId=${contractID}&IgnorePagination=true`,
      (response) => {
        setAllIPPlans(response?.data);
        setIpLoader(false);
      },
      (error) => {
        console.error("Error fetching IPPlans: ", error);
        setIpLoader(false);
      }
    );
  };

  useEffect(() => {
    fetchSubSectorData();
  }, [selectedSectorId]);

  const ipPlanChange = (ipPlanId) => {
    if (ipPlanId) {
      const apiIpPlan = `/api/IPPlan/getIPPlan?id=${ipPlanId}`;

      httpService.get(
        apiIpPlan,
        (res) => {
          setSelectedIpPlan(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    form.setFieldsValue({
      cluster: undefined,
      sector: undefined,
      subSector: undefined,
    });
    const selectedIPPlan = allIPPlans.find((plan) => plan.id === ipPlanId);

    if (selectedIPPlan) {
      setClusters(selectedIPPlan.clusters);
    }
  };

  useEffect(() => {
    if (selectedIpPlan) {
      setClusters(selectedIpPlan.clusters);
    }
  }, [selectedIpPlan]);

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <h3>General details</h3>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Select IP planning card"
            name="IPlaningCard"
            rules={[{ required: true, message: "Please select an ip plan" }]}
          >
            <Select
              placeholder="IP plannig card"
              loading={ipLoader}
              showSearch
              onChange={(ipPlanId) => ipPlanChange(ipPlanId)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {allIPPlans &&
                allIPPlans.map((IPPlan, index) => (
                  <Option key={index} value={IPPlan?.id}>
                    {IPPlan?.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedIpPlan == undefined ||
          selectedIpPlan == null ||
          selectedIpPlan == "" ? (
            <></>
          ) : (
            <button
              style={{
                marginTop: "0",
                marginBottom: "15px",
                border: "none",
                backgroundColor: "transparent",
                color: "var(--2, #AA1A5F)",
                fontWeight: "500",
              }}
              onClick={seeIpPlanDetails}
            >
              {showHideIpPlanDetails ? "Hide details" : "Show details"}

              <img src={DownArrow} alt="toggle" />
            </button>
          )}
          {showHideIpPlanDetails && (
            <div className="teamDetails mb-5">
              <Card title="Ip Plan General Details">
                <Divider />
                <div className="d-flex flex-row flex-wrap gap-5">
                  <p className="d-flex flex-column">
                    <span style={{ fontWeight: "700" }}>
                      Implementing partner:
                    </span>{" "}
                    {selectedIpPlan?.implementingPartner?.name}
                  </p>
                  <p className="d-flex flex-column">
                    <span style={{ fontWeight: "700" }}>
                      Governorate/State:
                    </span>{" "}
                    {selectedIpPlan?.ipPlanStates
                      ?.map((state) => state?.stateName)
                      .join(", ")}
                  </p>
                  {selectedIpPlan?.ipPlanCities?.length > 0 && (
                    <p className="d-flex flex-column">
                      <span style={{ fontWeight: "700" }}>
                        City/Disctrict/Municipality:
                      </span>{" "}
                      {selectedIpPlan?.ipPlanCities
                        ?.map((city) => city?.name)
                        .join(", ")}
                    </p>
                  )}
                  <p className="d-flex flex-column">
                    <span style={{ fontWeight: "700" }}>
                      Assigned to a field coordinator:
                    </span>{" "}
                    {selectedIpPlan?.assignee?.userDetails?.firstName
                      ? `${selectedIpPlan?.assignee?.userDetails?.firstName}
                     ${selectedIpPlan?.assignee?.userDetails?.lastName}`
                      : selectedIpPlan?.assignee?.email}
                  </p>
                </div>
              </Card>
            </div>
          )}
        </Col>
        <Col span={8}>
          <Form.Item
            label="Cluster"
            name="cluster"
            rules={[{ required: true, message: "Please select a cluster" }]}
          >
            <Select
              allowClear
              disabled={selectedIpPlan === ""}
              placeholder="Select an item"
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleClusterSelectChange}
              options={clusters?.map((cluster) => ({
                label: cluster.name,
                value: cluster.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Sector"
            name="sector"
            rules={[{ required: true, message: "Please select a sector" }]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select an item"
              disabled={sectors.length < 1}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {addingSector ? (
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={newSector}
                        onChange={(e) => setNewSector(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        loading={loading}
                        icon={<PlusOutlined />}
                        onClick={addSector}
                      >
                        Add item
                      </Button>
                    </Space>
                  ) : (
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        cursor: "pointer",
                        color: "var(--2, #AA1A5F)",
                      }}
                      onClick={() => setAddingSector(true)}
                    >
                      <PlusOutlined /> Other
                    </Space>
                  )}
                </>
              )}
              onChange={handleSectorSelectChange}
              onDropdownVisibleChange={(open) => {
                !open && setAddingSector(false);
                setNewSector();
              }}
              options={sectors.map((sector) => ({
                label: sector.name,
                value: sector.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Spin spinning={loading} indicator={customSpinner}>
            <Form.Item
              label="Subsectors"
              name="subSector"
              // rules={[{ required: true, message: "Please select a subsector" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select an item"
                disabled={!selectedSectorId}
                allowClear
                // value={selectedSubSectorId}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    {addingSubSector ? (
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={newSubSector}
                          onChange={(e) => setNewSubSector(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          loading={loading}
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addSubSector}
                        >
                          Add item
                        </Button>
                      </Space>
                    ) : (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingSubSector(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )}
                  </>
                )}
                // onChange={handleSubSectorSelectChange}
                onDropdownVisibleChange={(open) => {
                  !open && setAddingSubSector(false);
                  setNewSubSector();
                }}
                options={subSectors.map((subSector) => ({
                  label: subSector.name,
                  value: subSector.id,
                }))}
              />
            </Form.Item>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default GeneralDetails;
