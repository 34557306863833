import { Table } from "antd";
import React, { useState, useEffect } from "react";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import Preview from "./modals/Preview";

function TableView({ projectData }) {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const data = projectData?.data?.ipSummaryAllCardsForIP || [];

  // Handle preview modal toggle
  const handleTogglePreviewModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(!showPreviewModal);
  };

  // Define columns
  const columns = [
    {
      title: <span className="styledTitle">Card Type</span>,
      sorter: (a, b) => a.cardType - b.cardType,
      dataIndex: "cardType",
      key: "cardType",
      render: (text) => {
        let cardTypeText = "";
        switch (text) {
          case 1:
            cardTypeText = "Site Visit Card";
            break;
          case 2:
            cardTypeText = "IP Planning Card";
            break;
          case 3:
            cardTypeText = "Task";
            break;
          default:
            cardTypeText = "Unknown Card Type";
        }
        return <span className="styledDataIndex3">{cardTypeText}</span>;
      },
    },
    {
      title: <span className="styledTitle">Card Identifier</span>,
      sorter: (a, b) => a.cardIdentifier.localeCompare(b.cardIdentifier),
      dataIndex: "cardIdentifier",
      key: "cardIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Card Status</span>,
      sorter: (a, b) => (a.cardStatus || '').localeCompare(b.cardStatus || ''),
      dataIndex: "cardStatus",
      key: "cardStatus",
      render: (text) => <span className="styledDataIndex3">{text || 'N/A'}</span>,
    },
    {
      title: <span className="styledTitle">Other Status</span>,
      sorter: (a, b) => (a.otherStatus || '').localeCompare(b.otherStatus || ''),
      dataIndex: "otherStatus",
      key: "otherStatus",
      render: (text) => <span className="styledDataIndex3">{text || 'N/A'}</span>,
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    // {
    //   title: <span className="styledTitle"></span>,
    //   key: "action",
    //   fixed: isLargeScreen ? "right" : undefined,
    //   render: (text, record) => (
    //     <div className="actionDiv">
    //       <img
    //         src={previewIcon}
    //         // onClick={() => handleTogglePreviewModal(record)}
    //         style={{ cursor: "pointer" }}
    //         alt="Preview"

    //       />
    //     </div>
    //   ),
    // },
  ];

  // Generate unique key by combining properties or using an index as a fallback
  const dataWithKeys = data.map((item, index) => ({
    ...item,
    key: item.cardIdentifier + index, // Combining cardIdentifier and index to ensure uniqueness
  }));

  return (
    <div className="ipSummaryTable">
      <h2 className="ipCardName">All cards in system for this IP</h2>
      <div style={{ width: "100%" }}>
        <Table
          dataSource={dataWithKeys} // Use the modified data with unique keys
          columns={columns}
          className="tableTPM"
          pagination={false} // Disable pagination
        />
      </div>

      <Preview
        visible={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        record={selectedRowData}
      />
    </div>
  );
}

export default TableView;
