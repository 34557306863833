import { Select, DatePicker, Input, Button, List, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import { httpService } from "../../../../services/httpService.service";
import { useFilePreview } from "../../completed/ReportingList/Download";
import PreviewModal from "./PreviewModal";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";

function ReportingList() {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { previewFiles } = useFilePreview();
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString.split(pattern)[1];

    if (match) {
      return match;
    } else {
      return null;
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handlePreview = (item) => {
    setSelectedItem(item);
    setPreviewFileModal(true);
  };

  const handleModalClose = () => {
    setPreviewFileModal(false);
    setSelectedItem(null);
  };

  const { Option } = Select;

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: pageSize });
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    const apiUrl = `/api/Site/getResourcesForSiteVisitsAndIPPlans?projectId=${projectId}&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&Search=${debouncedQuery}&SiteVisits=true&IPPlans=false`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setData(res.data);
          setPagination({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.pageNumber, pagination.pageSize, debouncedQuery]);

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Reporting List</span>

        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input
              onChange={handleSearch}
              placeholder="Search... "
              suffix={<img src={SearchIcon} alt="Search" />}
            />
          </div>
        </div>
        <div className="listDesign">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => handlePreview(item)}
                    className="buttonsList"
                  >
                    <img src={previewList} alt="Preview" />
                  </Button>,

                  <Button
                    onClick={(e) => previewFiles(e, item.filePath, item.id)}
                    className="buttonsList"
                  >
                    <img src={downloadList} alt="Download" />
                  </Button>,
                ]}
                style={{ width: "100%" }}
              >
                <List.Item.Meta
                  title={
                    <span className="titleText">{`${
                      extractName(item.fileName) || item.filename
                    } - ${item.identifier}`}</span>
                  }
                />
              </List.Item>
            )}
            pagination={{
              current: pagination.pageNumber,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: handlePageChange,
            }}
            style={{ width: "100%" }}
          />
        </div>
        <PreviewModal
          onClose={handleModalClose}
          visible={previewFileModal}
          item={selectedItem}
        />
      </div>
    </Spin>
  );
}

export default ReportingList;
