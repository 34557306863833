import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  Divider,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const EditDraftTask = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);

  const [taskCreators, setTaskCreators] = useState([]);
  const [taskCreatorsLoading, setTaskCreatorsLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    setShowEditModal(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleCountryChange = (country) => {
    setSiteVisits([]);
    setTaskCreators([]);

    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });

    const projectId = localStorage.getItem("currentProjectID");
    const countryData = locations.find((location) => location.id == country);

    httpService.get(
      `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`,
      (res) => setSiteVisits(res.data),
      (err) => console.log(err)
    );
  };

  const handleSaveProgress = () => {
    setButtonLoading(true);
    const values = form.getFieldsValue();
    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      // setLoading(false);
      return;
    }

    const formattedDeadline = values.deadline
      ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    let selectedCountry;

    if (values?.country) {
      selectedCountry = locations?.find(
        (country) => country?.id === values?.country
      );
    }
    let selectedUser;
    if (values.assignedToMember) {
      selectedUser = taskCreators?.[0]?.members?.find(
        (member) => member?.id == values.assignedTo
      );
    }

    const formData = {
      name: values.taskName,
      deadline: formattedDeadline,
      taskLocation: {
        country: selectedCountry,
        municipality: values.location2,
        // cityId: values.city,
        district: values.district,
      },
      collectionMethod: values.collectionMethod,
      type: values.taskType,
      otherType: values.otherType,
      description: values.description,
      numberOfInterviews: values.numberOfInterviews,
      contactInformation: values.contactInformation,
      additionalNotes: values.additionalNotes,
      status: values.taskStatus,
      siteIds: values.siteIds,
      assignedTo: values.assignedTo,
    };

    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(formData);
    const payload = {
      id: taskId,
      ProjectId: projectId,
      jsonData: jsonData,
      type: 4,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft task card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "New draft task card added successfully!",
            text: message,
          });
          handleLeave();
          fetchData();
          fetchDraftsData();
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setButtonLoading(false);
        },
        (error) => {
          console.error("There was an error adding the task!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft task card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  const handleDelete = async () => {
    if (taskId) {
      const requestBody = [
        {
          id: taskId,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  const handleSubmit = async () => {
    const projectId = localStorage.getItem("currentProjectID");

    setButtonLoading(true);
    form
      .validateFields()
      .then((values) => {
        const formattedDeadline = values.deadline
          ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : null;

        let selectedUser;
        if (values.assignedTo) {
          selectedUser = taskCreators?.[0]?.members?.find(
            (member) => member?.id.toString() === values.assignedTo.toString()
          );
        }

        const payload = {
          projectId,
          name: values.taskName,
          deadline: formattedDeadline,
          taskLocation: {
            CountryId: values.country,
            municipality: values.location2,
            // cityId: values.city,
            district: values.district,
          },
          collectionMethod: values.collectionMethod,
          type: values.taskType,
          otherType: values.otherType,
          description: values.description,
          numberOfInterviews: values.numberOfInterviews,
          contactInformation: values.contactInformation,
          additionalNotes: values.additionalNotes,
          status: values.taskStatus,
          siteIds: values.siteIds,
          assignedTo: selectedUser,
        };

        httpService.post(
          "/api/Tasks",
          payload,
          (response) => {
            handleDelete();
            const message =
              response.data?.message ||
              "The task was successfully added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: "Task added successfully!",
              text: message,
            });

            setTimeout(() => {
              fetchData();
              fetchDraftsData();
            }, 400);
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setButtonLoading(false);
            handleLeave();
          },
          (error) => {
            console.error("Failed to submit task:", error);

            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this task, please try again.";

            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setButtonLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
        }, 3000);
        setButtonLoading(false);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchTeamBySiteVisit = async (siteVisitIds) => {
    setTaskCreatorsLoading(true);
    const queryString = siteVisitIds
      .map((id, index) => `siteVisitIds[${index}]=${encodeURIComponent(id)}`)
      .join("&");

    await httpService.get(
      `/api/Site/getTeamBySiteVisits?${queryString}`,
      (response) => {
        const formattedData = response.data.map((data) => data.members) || [];
        const allMembers = formattedData.flat();

        const uniqueMembers = allMembers.filter(
          (member, index, self) =>
            index === self.findIndex((m) => m.id === member.id)
        );

        setTaskCreators(uniqueMembers);
        setTaskCreatorsLoading(false);
      },
      (error) => {
        console.error("Failed to fetch team by site visit:", error);
        setTaskCreatorsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (taskId && visible && form) {
      const fetchData = async () => {
        setLoading(true);
        await httpService.get(
          `/api/Draft/getDraftById?id=${taskId}`,

          (response) => {
            const task = JSON.parse(response.data?.jsonData);

            const countryId = task?.taskLocation?.country?.id;
            const selectedSiteVisits = task?.siteIds || [];
            console.log("All task details:", task);
            if (countryId) {
              handleCountryChange(countryId);
            }
            if (selectedSiteVisits.length > 0) {
              fetchTeamBySiteVisit(selectedSiteVisits);
            }
            form.setFieldsValue({
              taskName: task?.name || undefined,
              deadline: dayjs(task.deadline || undefined),
              country: countryId || undefined,
              city: task?.taskLocation?.city?.id || undefined,
              siteIds: task?.siteIds || undefined,
              assignedTo: task?.assignedTo?.id
                ? task?.assignedTo?.id
                : task?.assignedTo,
              district: task?.taskLocation?.district || undefined,
              collectionMethod: task?.collectionMethod || undefined,
              numberOfInterviews: task?.numberOfInterviews || 1,
              description: task?.description || undefined,
              contactInformation: task?.contactInformation || undefined,
              additionalNotes: task?.additionalNotes || undefined,
              taskType: task?.type || undefined,
              taskStatus: task?.status || undefined,
            });
            // I have set this fields here just in case they are not being set correctly
            // setFormFields({
            //   siteIds: task?.siteIds || undefined,
            //   assignedTo: task?.assignedTo?.id || undefined,
            // });

            httpService.get(`/api/GeoData/countries`, (res) =>
              setLocations(res.data)
            );

            const projectId = localStorage.getItem("currentProjectID");

            const countryName = task?.taskLocation?.country?.stateName;
            httpService.get(
              `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryName}`,
              (res) => setSiteVisits(res.data)
            );

            httpService.get(
              `/api/Site/getTeamBySiteVisits?siteVisitIds=${task?.taskSite?.id}`,
              (res) => setTaskCreators(res.data)
            );

            setLoading(false);
          },
          (error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          }
        );
      };
      fetchData();
    }
  }, [taskId, form, visible]);

  if (!context) {
    return <></>;
  }
  const { fetchData, fetchDraftsData } = context;
  return (
    <Modal
      closable={false}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit Task Card</h2>
        </>
      }
      open={visible}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button
          className="inviteButtonTPM"
          key="back"
          onClick={handleSaveProgress}
          loading={buttonLoading}
        >
          Save progress
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Add new task
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task card identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the task name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select placeholder="Select an option">
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please select Country" }]}
              >
                <Select
                  placeholder="Select an option"
                  onChange={handleCountryChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {locations &&
                    locations.map((location) => (
                      <Option key={location?.id} value={location?.id}>
                        {location?.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please select City" }]}
              >
                <Select
                  placeholder="Select a city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={cities?.length < 1 ? true : false}
                >
                  {cities &&
                    cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Col span={24}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please input your site ID!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => {
                  fetchTeamBySiteVisit(value);
                }}
                disabled={siteVisits?.length < 1}
                // value={formFields.siteIds} // This should reflect the selected values
              >
                {siteVisits?.map((visit) => (
                  <Option key={visit.id} value={visit.id}>
                    {`${visit?.identifier} - ${visit?.ipPlan?.identifier}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Assigned to a field coordinator"
              name="assignedTo"
              rules={[{ required: false, message: "Please select a person" }]}
            >
              <Select
                placeholder="Select an option"
                disabled={taskCreators?.length < 1}
              >
                {taskCreators &&
                  taskCreators.map((member) => (
                    <Option key={member?.id} value={member?.id}>
                      {member?.firstName ?? ""} {member?.username ?? ""} (
                      {member?.roleName ?? ""})
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="Collection method" name="collectionMethod">
              <Input
                placeholder="Enter the collection method"
                // onChange={(e) =>
                //   handleInputChange("collectionMethod", e.target.value)
                // }
              />
            </Form.Item>
          </Col>

          <Form.Item label="Task description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the task description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber
              span={6}
              type="number"
              min={1}
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
          <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the task status" },
            ]}
          >
            <Select placeholder="Select the task status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditDraftTask;
