import React, { useState } from "react";
import "./Implementation.scss";
import VisitBoard from "./VisitBoard/VisitBoard";
import MonitoringRoundOverview from "./MonitoringRoundOverview/MonitoringRoundOverview";
import Banner from "../../components/Banner/Banner";
import FieldLogisticsBoard from "./FieldLogisticsBoard/FieldLogisticsBoard";
import MessagePopUp from "../../components/Messages/MessagePopUp";
function Implementation() {
  const [activeComponent, setActiveComponent] = useState("VisitBoard");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const openActiveComponent = (collector) => {
    setActiveComponent(collector);
  };
  const renderComponent = () => {
    if (activeComponent === "VisitBoard") {
      return (
        <div id="excludedStrictMode">
          <VisitBoard />
        </div>
      );
    } else if (activeComponent === "MonitoringRoundOverview") {
      return (
        <div id="excludedStrictMode">
          <MonitoringRoundOverview />
        </div>
      );
    } else if (activeComponent === "FieldLogisticsBoard") {
      return <FieldLogisticsBoard />;
    }
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="overflow-hidden">
        <Banner text="Implementation"></Banner>
      </div>
      <div style={{ backgroundColor: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "VisitBoard"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="VisitBoard"
                checked={activeComponent === "VisitBoard"}
                onChange={() => openActiveComponent("VisitBoard")}
              />
              Visit board
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "MonitoringRoundOverview"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="MonitoringRoundOverview"
                checked={activeComponent === "MonitoringRoundOverview"}
                onChange={() => openActiveComponent("MonitoringRoundOverview")}
              />
              Monitoring round overview
            </label>
          </div>
          <div>
            <label
              className={
                activeComponent === "FieldLogisticsBoard"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="FieldLogisticsBoard"
                checked={activeComponent === "FieldLogisticsBoard"}
                onChange={() => openActiveComponent("FieldLogisticsBoard")}
              />
              Field Logistics Board
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default Implementation;
