import { Spin, Pagination } from "antd";
import { useState, useEffect, useCallback } from "react";
import { json, useNavigate } from "react-router-dom";
import WorkspaceBox from "./WorkspaceBoxMenu";
import EditWorkspaceModal from "./EditWorkspace/EditWorkspaceModal";
import "./styles.scss";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import AddWorkspaceModal from "./AddWorkspace/AddWorkspaceModal";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../services/httpService.service";
import Preview from "./PreviewWorkspace/Preview";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";

const WorkspaceBody = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 12,
    total: 0,
    pageCount: 0,
  });
  const [modals, setModals] = useState({
    add: false,
    edit: false,
    delete: false,
    preview: false,
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const fetchWorkspaces = useCallback(async () => {
    setLoading(true);
    const params = {
      pageNumber: pagination.currentPage,
      pageSize: pagination.pageSize,
    };

    const queryString = new URLSearchParams(params).toString();
    const url = `/api/Contract/getAll?${queryString}`;

    httpService.get(
      url,
      (response) => {
        const { data = [], metaData = {} } = response;
        setWorkspaces(data);
        setPagination((prev) => ({
          ...prev,
          total: metaData.totalItemCount || 1,
          pageCount: metaData.pageCount || 1,
        }));
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching workspaces", error);
        setWorkspaces([]);
        setPagination((prev) => ({ ...prev, total: 1, pageCount: 1 }));
        setLoading(false);
      }
    );
  }, [pagination.currentPage, pagination.pageSize]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  const toggleModal = (modal) =>
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

  const handleWorkspaceChange = (workspace, action) => {
    setCurrentWorkspace(workspace);
    console.log("WORKSPACE: ",workspace)
    toggleModal(action);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({ ...prev, currentPage: page, pageSize }));
  };

  const handleWorkspaceClick = useCallback(
    (workspaceId, loggedinUserRole) => {
      localStorage.setItem("currentContractId", workspaceId);
      // localStorage.setItem("roleId", loggedinUserRole?.roleId);
      // localStorage.setItem("roleName", loggedinUserRole?.roleName);

      navigate("/projects/");
    },
    [navigate]
  );

  // const handleEditSubmit = useCallback(
  //   async (values) => {
  //     setLoading(true);

  //     httpService.put(
  //       "/api/Workspace",
  //       values,
  //       (res) => {
  //         fetchWorkspaces();
  //         toggleModal("edit");
  //       },
  //       (error) => {
  //         console.error(
  //           "Failed to edit contract:",
  //           error?.errors?.[0]?.message
  //         );
  //         setLoading(false);
  //       }
  //     );
  //   },
  //   [fetchWorkspaces]
  // );
  const handleDeleteSubmit = useCallback(async () => {
    setLoading(true);
    await httpService.delete(
      `/api/Contract?IsDeleted=true&Id=${currentWorkspace.id}`,
      "",
      (res) => {
        fetchWorkspaces();
        toggleModal("delete");
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Contract deleted successfully!",
          text: "This contract has been deleted from your database!",
        });
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "There was a problem deleting this contract from your database. Please try again later!";
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Failed to delete contract",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        console.error("Failed to delete contract:", error);
        setLoading(false);
      }
    );
  }, [currentWorkspace, fetchWorkspaces]);

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="top-content">
        <h4>My Contracts</h4>
        {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
          <button className="my-2" onClick={() => toggleModal("add")}>
            + New Contract
          </button>
        )}
      </div>
      <span className="d-flex" style={{ padding: "0 40px" }}>
        Below are the contracts you are part of. Select the one you want to work
        on
      </span>
      <div style={{ padding: "0 40px" }}>
        <div className="workspace-container">
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            <>
              {workspaces.length > 0 ? (
                workspaces.map((workspace) => (
                  <WorkspaceBox
                    key={workspace.id}
                    id={workspace.id}
                    title={workspace.name}
                    onEdit={() => handleWorkspaceChange(workspace, "edit")}
                    onPreview={() =>
                      handleWorkspaceChange(workspace, "preview")
                    }
                    onDelete={() => handleWorkspaceChange(workspace, "delete")}
                    onClick={() =>
                      handleWorkspaceClick(
                        workspace.id,
                        workspace.loggedinUserRole
                      )
                    }
                  />
                ))
              ) : (
                <p>No contracts available</p>
              )}
            </>
          )}
        </div>
        <div className="d-flex justify-content-end me-5 my-5">
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
          />
        </div>
      </div>
      <AddWorkspaceModal
        visible={modals.add}
        onClose={() => toggleModal("add")}
        onSubmit={() => {
          fetchWorkspaces();
        }}
        setMessage={setMessage}
      />
      <EditWorkspaceModal
        visible={modals.edit}
        onClose={() => toggleModal("edit")}
        onSubmit={() => {
          fetchWorkspaces();
        }}
        currentContractId={currentWorkspace}
        setMessage={setMessage}
      />
      <DeleteComponent
        header="Delete Contract"
        text={
          "You're about to delete this contract. This action can’t be undone."
        }
        visible={modals.delete}
        onClose={() => toggleModal("delete")}
        onConfirm={handleDeleteSubmit}
      />
      <Preview
        visible={modals.preview}
        currentContractId={currentWorkspace}
        onClose={() => toggleModal("preview")}
      ></Preview>
    </>
  );
};

export default WorkspaceBody;
