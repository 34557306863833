import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message, Spin } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import "./Offices.scss";
import { httpService } from "../../../services/httpService.service";

const { Option } = Select;

const AddOffice = ({ visible, onClose, onSubmit, setMessage }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);

  useEffect(() => {
    setLoadingCountries(true);
    httpService.get(
      "/api/GeoData/countries",
      (res) => {
        setAllCountries(res.data);
        setLoadingCountries(false);
      },
      (error) => {
        console.error("Failed to fetch countries", error);
        setLoadingCountries(false);
      }
    );
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        httpService.post(
          "/api/Office",
          { name: values.officeName, countryIds: values.countryIds },
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              title: "Office added successfully!",
              text: "New office has been created and added to your database!",
            });
            onSubmit();
            form.resetFields();
            onClose();
            setLoading(false);
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "Failed to add the office. Please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              title: "Error",
              text: errorMessage,
            });
            setLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={null}
      width={500}
    >
      <div className="modalHeader">
        <img
          src={cancelX}
          alt="Cancel"
          onClick={handleCancel}
          style={{ cursor: "pointer", float: "right" }}
        />
        <h2 className="modalHeader">Add New Office</h2>
      </div>
      <Form form={form} layout="vertical">
        <Form.Item
          name="officeName"
          label="Office Name"
          rules={[{ required: true, message: "Please enter the office name" }]}
        >
          <Input placeholder="Enter Office Name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="countryIds"
          label="Country"
          rules={[
            { required: true, message: "Please select one or more countries" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select Country"
            loading={loadingCountries}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {allCountries.map((country) => (
              <Option key={country.id} value={country.id}>
                {country.stateName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="buttonsModal" style={{ marginTop: "20px" }}>
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            loading={loading} // Loading state for the button
            onClick={handleOk} // Handle the form submission
          >
            Add Office
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddOffice;
