import React, { useEffect } from "react";
import { Button, message } from "antd";
import "./messageDesign.scss";
import CancelIcon from "../../../Assets/svg/cancelXbutton.svg";

const MessagePopUp = ({ trigger, icon, messageTitle, messageText, type }) => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (trigger) {
      handleMessage();
    }
  }, [trigger]);

  const handleMessage = () => {
    messageApi.open({
      content: (
        <div className={`messageContainer ${type}`}>
          <div className="icon">
            <img src={icon} alt="icon" />
          </div>
          <div className="content">
            <div className="text">
              <h1>{messageTitle}</h1>
              <p>{messageText}</p>
            </div>
            <Button onClick={() => messageApi.destroy()}>
              <img src={CancelIcon} alt="Close icon" />
            </Button>
            <div className="loading-bar"></div>
          </div>
        </div>
      ),
      duration: 2.5,
      style: {
        marginTop: "2vh",
      },
    });
  };

  return <>{contextHolder}</>;
};

export default MessagePopUp;
