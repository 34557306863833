import { Table } from "antd";
import React, { useState } from "react";
import deleteIcon from "../../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../../Assets/svg/table-action-preview.svg";
import AddMemberIcon from "../../../../../../Assets/svg/addMemberIcon.svg";
import DeleteComponent from "../../../../../components/DeleteComponent/DeleteComponent";
import Edit from "./modals/Edit";
import Preview from "./modals/Preview";
import { httpService } from "../../../../../../services/httpService.service";
import MessagePopUp from "../../../../../components/Messages/MessagePopUp";
import { RolesEnum } from "../../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../../services/authService";

function TableView({ teams, fetchTeams }) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const currentContractId = localStorage.getItem("currentContractId");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleToggleEditModal = (record) => {
    setSelectedRowData(record);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleDelete = () => {
    if (!selectedRowData?.id) return;

    httpService.delete(
      "/api/User/deleteTeam",
      { id: selectedRowData?.id },
      () => {
        fetchTeams(currentContractId);
        setShowDeleteModal(false);
        setSelectedRowData(null);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Team card deleted successfully!",
          text: "This team card has been deleted from your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem deleting this team card from your database, please try again!`;
        setMessage({
          trigger: true,
          type: "error",
          icon: AddMemberIcon,
          title: "Failed to delete this team card",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const columns = [
    {
      title: <span className="styledTitle">Team Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => <span className="styledDataIndex3">{name}</span>,
    },
    {
      title: <span className="styledTitle">Member Roles</span>,
      dataIndex: "members",
      key: "roles",
      render: (members) => {
        // Extract the role names from members
        const roles = members
          .map((member) => member.roleName)
          .filter((role) => role); // Remove any falsy values (e.g., null or undefined)

        // Create a Set to remove duplicates and then convert it back to an array
        const uniqueRoles = [...new Set(roles)];

        // Join the unique roles into a string
        return (
          <span className="styledDataIndex3">{uniqueRoles.join(", ")}</span>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: teams?.length ? "right" : "",
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
            RolesEnum.LimitedGuest,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
              alt=""
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
              alt=""
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedRowData(record);
                setShowDeleteModal(true);
              }}
              alt=""
            />
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      {/* <Filters /> */}
      <div>
        <Table
          rowKey="id"
          dataSource={teams}
          // rowSelection={{ ...rowSelection }}
          columns={columns}
          className="tableTPM"
        />
      </div>
      <Edit
        visible={showEditModal}
        onClose={handleToggleEditModal}
        record={selectedRowData}
        fetchTeams={fetchTeams}
        setMessage={setMessage}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        record={selectedRowData}
        teams={teams}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. This action can’t be undone."}
        header={"Delete"}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default TableView;
