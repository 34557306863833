import { Select, Input, Button, Spin } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import CalendarViewComponent from "../../../components/CalendarView/CalendarViewComponent";
import { httpService } from "../../../../services/httpService.service";
import PreviewModal from "../modals/PreviewModal";
import { debounce } from "lodash";

const { Option } = Select;

const CalendarViewPlanning = () => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [ipPlans, setIPPlans] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    ImplementingPartnerId: undefined,
    month: undefined,
    year: undefined,
    IPStatus: undefined,
    AssignedTo: undefined,
    search: "",
  });

  const [inputSearch, setInputSearch] = useState(filters.search);
  const [implementingPartners, setImplementingPartners] = useState([]);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
  };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: query,
      }));
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setInputSearch(search);
    debouncedSearchHandler(search);
  };

  const handleClearFilters = () => {
    setFilters({
      ImplementingPartnerId: undefined,
      month: undefined,
      year: undefined,
      IPStatus: undefined,
      AssignedTo: undefined,
      search: "",
    });
    setInputSearch("");
  };

  useEffect(() => {
    const fetchImplementingPartners = async () => {
      const workspaceId = localStorage.getItem("currentContractId");
      const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

      httpService.get(
        apiIps,
        (res) => setImplementingPartners(res.data),
        (error) => console.error("Error fetching Implementing Partners:", error)
      );
    };
    fetchImplementingPartners();

    httpService.get("/api/User/getAllusers", (res) => setUsers(res.data));
  }, []);

  const fetchIPPlans = async () => {
    const projectId = localStorage.getItem("currentProjectID");
    const filtersData = {
      ProjectId: projectId,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      Year: filters.year,
      Month: filters.month,
      IPStatus: filters.IPStatus,
      AssignedTo: filters.AssignedTo,
      Search: filters.search,
      IgnorePagination: true,
    };

    // Filter out undefined values
    const params = Object.fromEntries(
      Object.entries(filtersData).filter(([_, v]) => v !== undefined)
    );

    const queryString = new URLSearchParams(params).toString();
    const url = `/api/ipplan/getAllIPPlans?${queryString}`;

    setLoading(true);

    httpService.get(
      url,
      (response) => {
        const ipPlansData = response.data?.map((ipPlan) => ({
          date: ipPlan?.initialMeeting,
          identifier: ipPlan?.identifier,
          id: ipPlan?.id,
        }));
        setIPPlans(ipPlansData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching IP Plans:", error);
        setLoading(false);
      }
    );
  };

  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    fetchIPPlans();
  }, [filters]);

  const events = ipPlans.reduce((acc, ipPlan) => {
    console.log(ipPlans);
    console.log(ipPlan);
    if (!ipPlan) return acc;
    const dateKey = ipPlan.date?.split("T")[0];

    if (dateKey) {
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push({
        type: "success",
        status: "test",
        content: ipPlan.identifier,
        id: ipPlan.id,
      });
    }

    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format("YYYY-MM-DD");
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter((event) => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (
        filters.search &&
        !event.content.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
      <div className="filtersWrapper my-5">
        <div className="containerFilters">
          <Input
            placeholder="Type something here"
            name="search"
            value={inputSearch}
            onChange={handleSearchChange}
          />
          <Button className="clearButton" onClick={handleClearFilters}>
            Clear All
          </Button>
          <Button onClick={handleToggleFilters} className="toggleButton">
            {!showFilters ? "Show Filters" : "Hide Filters"}
          </Button>
        </div>
        {showFilters && (
          <div className="filtersContainer">
            <div className="filter">
              <p className="filterText">Implementing Partner</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select an IP"
                onChange={(value) =>
                  handleFilterChange("ImplementingPartnerId", value)
                }
                value={filters.ImplementingPartnerId}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Current IP status</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select an IP status"
                onChange={(value) => handleFilterChange("IPStatus", value)}
                value={filters.IPStatus}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                <Option key={1} value={1}>
                  Discussion
                </Option>
                <Option key={2} value={2}>
                  Document Review
                </Option>
                <Option key={3} value={3}>
                  Pre-phase
                </Option>
                <Option key={4} value={4}>
                  Data Collection
                </Option>
                <Option key={5} value={5}>
                  Closed
                </Option>
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Assigned to:</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select a user"
                onChange={(value) => handleFilterChange("AssignedTo", value)}
                value={filters.AssignedTo}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.userDetails.firstName
                        ? user.userDetails.firstName
                        : "N/A"}{" "}
                      {user.userDetails.lastName
                        ? user.userDetails.lastName
                        : "N/A"}{" "}
                      - ({user.userName})
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="filter">
              <p className="filterText">Month</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select a month"
                onChange={(value) => handleFilterChange("month", value)}
                value={filters.month}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">March</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>
            </div>

            <div className="filter">
              <p className="filterText">Year</p>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select a year"
                onChange={(value) => handleFilterChange("year", value)}
                value={filters.year}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  const children = React.Children.toArray(option.children).join(
                    ""
                  );
                  return children.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {Array.from({ length: 81 }, (_, i) => 2030 - i).map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>

      <Spin spinning={loading}>
        <CalendarViewComponent
          getListData={getListData}
          setClickedEventId={handleEventClicked}
        />
      </Spin>
      <PreviewModal
        visible={showPreviewModal}
        record={clickedEventId}
        onOk={handlePreviewOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default CalendarViewPlanning;
