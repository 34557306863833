import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Radio,
  Tooltip,
  Slider,
  Divider,
  Spin,
  message,
} from "antd";
import "../../../pages/UserManagement/UserManagement.scss";
import "../Flags.scss";
import "./modalStyles.scss";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { httpService } from "../../../../services/httpService.service";
import { forEach } from "lodash";

const { Option } = Select;

const PostVisitCardModal = ({
  visible,
  onClose,
  setMessage,
  fetchRedFlags,
}) => {
  const projectId = localStorage.getItem("currentProjectID");
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questionType, setQuestionType] = useState(1);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleQuestionType = (value) => {
    const selectedQuestionType = parseInt(value.target.value);
    setQuestionType(selectedQuestionType);
  };

  const fetchQuestions = (questionType) => {
    setLoadingQuestions(true);
    const params = {
      questionType: questionType,
    };
    const queryParams = new URLSearchParams(params).toString();

    httpService.get(
      `/api/RedFlag/questions?${queryParams}`,
      (response) => {
        setQuestions(response.data);
        setLoadingQuestions(false);
      },
      (error) => {
        console.log(error);
        setLoadingQuestions(false);
      }
    );
  };

  const findOptionId = (questionId, value) => {
    const question = questions.find((q) => q.id === questionId);

    const option = question?.options?.find(
      (option) => option?.text === `${value}`
    );
    return option?.id;
  };

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";

    return date.format("YYYY-MM-DD");
  };

  const handleOk = () => {
    setLoading(true); 

    form
      .validateFields()
      .then((values) => {
        const questionType1options = Object.entries(values)
          .filter(([key]) => key.startsWith("question_"))
          .map(([key, value]) => {
            const newKey = key.replace("question_", "");
            return {
              questionId: newKey,
              optionId: value,
            };
          });

        const questionType2options = Object.entries(values)
          .filter(([key]) => key.startsWith("question_"))
          .map(([key, value]) => {
            const newKey = key.replace("question_", "");
            return {
              questionId: newKey,
              optionId: findOptionId(newKey, value),
            };
          });

        const payload = {
          date: formatDate(values.date),
          staffName: values.staffName,
          implementingPartnerId: values.ip,
          siteVisitId: values.siteVisitId,
          responses:
            questions?.[0]?.questionType === 1
              ? questionType1options
              : questionType2options,
          otherFeedback: values.otherFeedback,
        };

        httpService.post(
          "/api/RedFlag/postSiteVisit",
          payload,
          (response) => {
            const message =
              response.message ||
              `A new post-site visit card has been created and added to your database!`;
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: `Post-visit card created successfully!`,
              text: message,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);

            fetchRedFlags();
            handleCancel(); 
            setLoading(false); 
          },
          (error) => {
            const messageError =
              error?.errors?.[0]?.message ||
              `We had a problem saving this post-site visit to your database, please try again!`;
            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Oops! A problem has occurred!",
              text: messageError,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setLoading(false); // Set loading to false after failure
          }
        );
      })
      .catch(() => {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "Please ensure all fields are filled out correctly.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false); // Set loading to false after validation failure
      });
  };


  const handleCancel = () => {
    setQuestionType(1);
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}`,
      (response) => {
        setSiteVisitCards(response?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    const contractId = localStorage.getItem("currentContractId");

    httpService.get(
      `/api/ImplementingPartner/getAllImplementingPartners?ContractId=${contractId}`,
      (response) => {
        setImplementingPartners(response?.data);
      },
      (error) => {
        console.log(error);
      }
    );
    if (questionType) {
      fetchQuestions(questionType);
    }
  }, [questionType]);

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Post-visit card</h2>
      <Form form={form} layout="vertical" initialValues={{ questionType: 1 }}>
        {" "}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Select a date" }]}
            style={{ width: "48%" }}
          >
            <DatePicker placeholder="Select date" className="w-100" />
          </Form.Item>
          <Form.Item
            name="staffName"
            label="Staff name"
            rules={[{ required: true, message: "Enter the staff name" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter the staff name" autoComplete="off" />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="ip"
            label="IP"
            rules={[{ required: true, message: "Please select an IP" }]}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Select an IP"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {implementingPartners &&
                implementingPartners.map((ip) => (
                  <Option key={ip.id} value={ip.id}>
                    {ip.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="location1"
            label="Location 1"
            rules={[{ required: true, message: "Enter the location" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter the location" autoComplete="off" />
          </Form.Item> */}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="siteVisitId"
            label="Pick the site visit card"
            rules={[{ required: true, message: "Select the site visit" }]}
            style={{ width: "100%" }}
          >
            <Select
              placeholder="Select the site visit"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {siteVisitCards &&
                siteVisitCards.map((siteVisitCard) => (
                  <Option key={siteVisitCard.id} value={siteVisitCard.id}>
                    {siteVisitCard.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <Spin spinning={loadingQuestions}>
          <div className="questionType">
            <div className="questionTypeHeader">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="questionType"
                  label="Choose the question type:"
                  rules={[{ required: true, message: "Select a date" }]}
                >
                  <Radio.Group
                    onChange={handleQuestionType}
                    className="custom-radio-group d-flex w-100"
                    value={questionType}
                    defaultValue={questionType}
                  >
                    <Radio value={1} className="custom-radio type-radio">
                      Binary
                    </Radio>
                    <Radio value={2} className="custom-radio type-radio">
                      Likert scale
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            <div
              className={
                questions?.[0]?.questionType === 1
                  ? "questionTypeBody1"
                  : "questionTypeBody1 hidden"
              }
            >
              {questions &&
                questions?.[0]?.questionType === 1 &&
                questions.map((question) => (
                  <Form.Item
                    key={question?.id}
                    name={`question_${question?.id}`}
                    label={question?.text}
                    rules={[
                      {
                        required: question?.isRequired,
                        message: "Please choose one answer!",
                      },
                    ]}
                  >
                    <Radio.Group className="custom-radio-group d-flex w-100">
                      {question?.options
                        ?.sort((a, b) => {
                          if (a.text === "Yes" && b.text !== "Yes") return -1;
                          if (a.text !== "Yes" && b.text === "Yes") return 1;
                          if (a.text === "No" && b.text !== "No") return 1;
                          if (a.text !== "No" && b.text === "No") return -1;
                          return 0;
                        })
                        ?.map((option) => (
                          <Radio
                            key={option?.id}
                            value={option.id}
                            className={`custom-radio ${
                              option?.text === "No" ? "no-radio" : "yes-radio"
                            }`}
                          >
                            {option.text}
                          </Radio>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                ))}
            </div>
            <div
              className={
                questions?.[0]?.questionType === 2
                  ? "questionTypeBody2"
                  : "questionTypeBody2 hidden"
              }
            >
              {questions &&
                questions?.[0]?.questionType === 2 &&
                questions.map((question) => {
                  const options = question?.options || [];
                  const marks = options.reduce((acc, option) => {
                    acc[parseInt(option.text)] = option.text;
                    return acc;
                  }, {});

                  return (
                    <Form.Item
                      key={question?.id}
                      name={`question_${question?.id}`}
                      label={
                        <div className="questionInfo">
                          {question?.text}
                          <span>
                            (1-Strongly Disagree, 2-Disagree, 3-Neither disagree
                            nor agree, 4-Agree, 5-Strongly Agree)
                          </span>
                        </div>
                      }
                      rules={[
                        {
                          required: question?.isRequired,
                          message: "Please select one from 1 to 5!",
                        },
                      ]}
                    >
                      <Slider
                        marks={marks}
                        step={1}
                        min={1}
                        max={5}
                        defaultValue={null}
                      />
                    </Form.Item>
                  );
                })}
            </div>
          </div>
        </Spin>
        <Form.Item
          name="otherFeedback"
          label={
            <span>
              Any other feedback&nbsp;
              <Tooltip title="Enter any additional feedback here">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          style={{ width: "100%" }}
        >
          <Input.TextArea rows={4} placeholder="Enter your follow up notes" />
        </Form.Item>
        <Divider />
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            Save changes
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PostVisitCardModal;
