import { Table } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import EditSiteVisitCardModal from "../Modals/EditSiteVisitCardModal";
import Preview from "./modals/Preview";
import Filters from "../Filters";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";

function TableView({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  setSiteVisitCardsForExcel,
  onPageChange,
  pagination,
  setPagination,
  onOk,
}) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });

  const [inputSearch, setInputSearch] = useState(filters.Search);

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };
  const [recordToDelete, setRecordToDelete] = useState();

  const handleDelete = (record) => {
    const siteVisitId = record.key;
    setRecordToDelete(siteVisitId);
    setShowDeleteModal(true);
  };

  const onEdited = () => {
    setShowEditModal(false);
    onOk();
  };

  const handleConfirmDelete = async () => {
    if (recordToDelete) {
      const requestBody = [
        {
          id: recordToDelete,
          isDeleted: false,
        },
      ];

      const url = `/api/Site/deleteSiteVisits?includeIsDeleted=false`;
      httpService.delete(
        url,
        JSON.stringify(requestBody),
        (response) => {
          const message = response.message;
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Site visit card created successfully!",
            text:
              message ||
              "You have successfully deleted this site visit. You can revert it from deleted cards.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          fetchSiteVisitCards(projectID);
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message || "Error deleting record.";
          console.error("Error deleting record:", error);

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage || "Failed to delete the record.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
        }
      );

      setShowDeleteModal(false);
      fetchSiteVisitCards(projectID);
      setRecordToDelete(null);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPagination({ ...pagination, current: 1 });
  };

  useEffect(() => {
    fetchSiteVisitCards(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters]);

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Date of planned visit</span>,
      sorter: (a, b) =>
        a.dateOfPlannedVisit.localeCompare(b.dateOfPlannedVisit),
      dataIndex: "dateOfPlannedVisit",
      key: "dateOfPlannedVisit",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site visit status</span>,
      sorter: (a, b) => a.siteVisitStatus.localeCompare(b.siteVisitStatus),
      dataIndex: "siteVisitStatus",
      key: "siteVisitStatus",
      render: (text) => {
        let className;
        let fill;
        if (text === "Data Collection") {
          className = "progress";
          fill = "#3B81FE";
        } else if (text === "Post-data Collection") {
          className = "monitoring";
          fill = "#FC9403";
        } else if (text === "Finalization") {
          className = "review";
          fill = "#9670B8";
        } else if (text === "Delivered") {
          className = "delivered";
          fill = "#045B46";
        } else if (text === "IP Feedback") {
          className = "debrief";
          fill = "#0F7507";
        } else {
          className = "noStatus";
          fill = "red";
        }
        return (
          <div className={`${className} m-0`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
            ></img>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(record)}
            ></img>
          )}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log("set", selectedRows);
    const formattedData = selectedRows.map((item) => ({
      "Site Visit Identifier": item.siteVisitIdentifier || "N/A",
      "Date of planned visit": item.dateOfPlannedVisit || "N/A",
      IP: item.ip || "N/A",
      Location: item.location || "N/A",
      Cluster: item.cluster || "N/A",
      Virtual: (item.virtual ? "YES" : "NO") || "N/A",
      "Site visit status": item.siteVisitStatus || "N/A",
      sector: item?.sector || "N/A",
      subsector: item?.subsector || "N/A",
      country: item?.country || "N/A",
      city: item?.city || "N/A",
      team: item?.team || "N/A",
    }));
    setSiteVisitCardsForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  // Formating data for the table
  const formatStatus = (status) => {
    if (status === 1) {
      return "Data Collection";
    } else if (status === 2) {
      return "Post-data Collection";
    } else if (status === 3) {
      return "Finalization";
    } else if (status === 4) {
      return "Delivered";
    } else if (status === 5) {
      return "IP Feedback";
    } else {
      return "No Status";
    }
  };

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      dateOfPlannedVisit: siteVisitCard?.visit?.plannedVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      siteVisitStatus: formatStatus(siteVisitCard?.tracking?.siteVisitStatus),
      sector: siteVisitCard?.sector?.name,
      subsector: siteVisitCard?.subSector?.name,
      country: siteVisitCard?.locationDetails?.country,
      city: siteVisitCard?.locationDetails?.city,
      team: siteVisitCard?.logistic?.team?.name,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  return (
    <>
      <Filters
        onFiltersChange={handleFiltersChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        setInputSearch={setInputSearch}
        inputSearch={inputSearch}
      />
      <div>
        <Table
          loading={loading}
          rowSelection={{ ...rowSelection }}
          dataSource={formattedData}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
          className="tableTPM"
          scroll={{ x: "max-content" }}
        />
      </div>
      <EditSiteVisitCardModal
        onOk={onEdited}
        visible={showEditModal}
        onCancel={handleToggleEditModal}
        siteVisitCardId={selectedSiteVisit}
        setMessage={setMessage}
      />
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete this site visit card. You can return it back from the deleted cards later! "
        }
        header={"Delete "}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}

export default TableView;
