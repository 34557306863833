import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select, DatePicker, Input, Space } from "antd";
import { httpService } from "../../../../../services/httpService.service";
import { useEffect, useState } from "react";

const { Option } = Select;

const Logistics = () => {
  const [coordinators, setCoordinators] = useState([]);

  const getCoordinators = (role) => {
    httpService.get(
      `/api/User/getAllusers?RoleIds=${role?.id}&IgnorePagination=true`,
      (res) => {
        console.log("RES", res?.data);
        setCoordinators(res?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getRoles = () => {
    httpService.get(
      `/api/Account/getAllRoles`,
      (res) => {
        getCoordinators(
          res?.data?.find((e) => e?.roleName === "Field Coordinator")
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getRoles();
  }, []);

  // const validateURL = (_, value) => {
  //   if (!value) {
  //     return Promise.resolve();
  //   }
  //   try {
  //     new URL(value);
  //     return Promise.resolve();
  //   } catch {
  //     return Promise.reject(new Error("Invalid URL format"));
  //   }
  // };

  return (
    <div>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            label="TPM Coordinator"
            name="tpmCoordinator"
            rules={[
              { required: true, message: "Please select a TPM coordinator" },
            ]}
          >
            <Select
              placeholder="Enter TPM coordinator"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
              }
            >
              {coordinators?.map((el) => (
                <Option value={el?.id}>
                  {el?.userDetails?.firstName
                    ? el?.userDetails?.firstName +
                      " " +
                      el?.userDetails?.lastName
                    : el?.userName}
                </Option>
              ))}
              {/* More options can be added here */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Coordination Type"
            name="coordinationType"
            rules={[
              {
                required: true,
                message: "Please select a coordination type",
              },
            ]}
          >
            <Select
              placeholder="Select coordination type"
              options={[
                { value: 1, label: "Remote" },
                { value: 2, label: "In-person" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            tooltip="Please give a valid link ex: http://... or http:// or www..."
            label="Data Collection Platfom Account"
            name="fulcrumAccount"
            rules={[
              {
                pattern: new RegExp(
                  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
                ),
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input placeholder="ex: https://www.yourplatform.com/" />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            label="Activation Date"
            name="activationDate"
            rules={[
              { required: true, message: "Please select an activation date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} onChange={(e) => changeValue(e)} />
          </Form.Item>
        </Col> */}
      </Row>
    </div>
  );
};
export default Logistics;
