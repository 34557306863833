import { Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import { useState } from "react";

const { Option } = Select;

//months
const months = [
  {
    id: "01",
    name: "January",
  },
  {
    id: "02",
    name: "February",
  },
  {
    id: "03",
    name: "March",
  },
  {
    id: "04",
    name: "April",
  },
  {
    id: "05",
    name: "May",
  },
  {
    id: "06",
    name: "June",
  },
  {
    id: "07",
    name: "July",
  },
  {
    id: "08",
    name: "August",
  },
  {
    id: "09",
    name: "September",
  },
  {
    id: "10",
    name: "October",
  },
  {
    id: "11",
    name: "November",
  },
  {
    id: "12",
    name: "December",
  },
];

//years
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 11 }, (_, i) => `${currentYear + i}`);

const TrackingDetails = () => {
  const [isPostponed, setIsPostponed] = useState(false);
  return (
    <div>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="reportingYear" label="Reporting year" required>
            <Select placeholder="Select reporting year" showSearch>
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="reportingMonth" label="Reporting month" required>
            <Select placeholder="Select reporting month" showSearch>
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Training Completed"}
            name="trainingIsCompleted"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="trainingDate" label="Training date" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="trainingScoreInfo" label="Training score info">
            <Input placeholder="example" />
          </Form.Item>
        </Col>
        <Col span={8} />
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            name="actualDateOfVisit"
            label="Actual date of visit"
            required
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfReport" label="Date of report" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfDebrief" label="Date of debrief" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            name="percentageOfDataCollectionCompleted"
            label="% of data collection completed"
            required
          >
            <Select placeholder="Select an option from 0 to 100" showSearch>
              {Array.from({ length: 101 }, (_, i) => (
                <Option value={i}>{i}%</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="siteVisitStatus" label="Site visit status" required>
            <Select placeholder="Select status">
              <Option value={1}>Data Collection</Option>
              <Option value={2}>Post-data Collection</Option>
              <Option value={3}>Finalization</Option>
              <Option value={4}>Delivered</Option>
              <Option value={5}>IP Feedback</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Col span={8}>
        <Form.Item
          name="fieldTeamReportingStatus"
          label="Field Team Reporting Status"
          required
        >
          <Select placeholder="Select a status">
            <Option value={1}>Data collection</Option>
            <Option value={2}>Data Check</Option>
            <Option value={3}>Translation</Option>
            <Option value={4}>Field Reporting Completed</Option>
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
};
export default TrackingDetails;
