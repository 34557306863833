import { Table, Input } from "antd";
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import AddBudget from "./AddBudget";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import { httpService } from "../../../services/httpService.service";
import AddMemberIcon from '../../../Assets/svg/addMemberIcon.svg';
import RemoveMemberIcon from '../../../Assets/svg/deleteMemberIconSuccess.svg'
import DeleteMemberIcon from '../../../Assets/svg/deleteMemberIcon.svg';
import MessagePopUp from "../../components/Messages/MessagePopUp";

const BudgetSavedTable = forwardRef(({callSummary},ref) => {
  const [contracts, setContracts] = useState([]);
  const [showAddBudget, setShowAddBudget] = useState(false);
  const [showEditBudget, setShowEditBudget] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  // Contract
  const [allContracts, setAllContracts] = useState([]);
  const [loadingContract, setLoadingContract] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true
 });
  const [searchContract, setSearchContract] = useState(null);
  const [contractToEdit, setContractToEdit] = useState({})

  // Summary
  const [summaryData, setSummaryData] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(false);


  // Tabs
  const tabs = [
    {
      key: '1',
      label: 'Saved',
      
    }
  ]
  // Filter contracts based on the search term
  const filteredContracts = contracts.filter((contract) =>
    contract.contractName.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleToggleAddBudget = (e) => {
    if(e === 'success') {
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Your contract budget has been successfully added!",
        text: "This contract budget was successfully added!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      setShowAddBudget(!showAddBudget);
    } else if(e === 'error') {
      setMessage({
        trigger: true,
        type: "danger",
        icon: DeleteMemberIcon,
        title: "Oops! A problem has occurred!",
        text: "We had a problem adding your contract budget, please try again.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
    } else {
      setShowAddBudget(!showAddBudget);
    }
  };

  const onEditCloseModal = (e) => {
    if(e === 'success') {
      setMessage({
        trigger: true,
        type: "success",
        icon: RemoveMemberIcon,
        title: "Your contract budget has been successfully edited!",
        text: "This contract budget was successfully edited!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
      fetchAllContracts();
      setShowEditBudget(!showEditBudget);
      callSummary()
    } else if(e === 'error') {
      setMessage({
        trigger: true,
        type: "danger",
        icon: DeleteMemberIcon,
        title: "Oops! A problem has occurred!",
        text: "We had a problem editing your contract budget, please try again.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: "",
        });
      }, 3000);
    } else {
      setShowEditBudget(!showEditBudget);
    }
  }

  const handleToggleEditBudget = (record) => {
    if(!showEditBudget) {
      setContractToEdit(record);
    }
    setShowEditBudget(!showEditBudget);
    
  };

  const handleDelete = () => {
    if (!selectedRowData?.id) return;
    setDeleteLoader(true);
    httpService.delete(
      "/api/Contract/deleteContractBudgets",
      [{ id: selectedRowData?.id, isDeleted: false}],
      () => {
        setShowDeleteModal(false);
        setSelectedRowData(null);
        fetchAllContracts();
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Contract budget deleted successfully!",
          text: "This contract budget has been deleted!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setDeleteLoader(false);
        callSummary()
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem deleting this contract budget, please try again!`;
        setMessage({
          trigger: true,
          type: "error",
          icon: "",
          title: "Failed to delete this contract budget",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setDeleteLoader(false);
      }
    );
  };

  const columns = [
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
      width: '50%'
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      width: '50%'
    },
    
    ...(allContracts.length > 0 ? [{
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: "right",
      width: 60,
      minWidth: 60,
      render: (text, record) => (
        <div className="actionDiv actionSide">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditBudget(record)}
              style={{ cursor: "pointer" }}
              alt=""
            />
          )} 
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedRowData(record);
                setShowDeleteModal(true);
              }}
              alt=""
            />
          )}
        </div>
      ),
    }] : []),
  ];

  const fetchAllContracts = () => {
    setLoadingContract(true)
    httpService.get(`/api/Contract/getAllContractBudgets?Search=${searchContract !== null ? searchContract : ''}&pageNumber=${pagination?.current}&pageSize=${pagination?.pageSize}`,
    (res) => {
      if(!allContracts?.length) {
        setPagination({...pagination, total: res?.metaData?.totalItemCount})
      }
      setAllContracts(res?.data);
      setLoadingContract(false);
    }, (err) => {
      setLoadingContract(false);
    })
  }
  
  const onPageChange = (pageNumber, pageSize) => {
    setPagination({...pagination, current: pageNumber})
    fetchAllContracts();
 };

 useEffect(() => {
  if(searchContract !== null) {
    const timeout = setTimeout(() => {
      fetchAllContracts();
    },1000);
  
    return () => {
      clearTimeout(timeout);
    }
  }
 },[searchContract])


 useEffect(() => {
  fetchAllContracts();
 },[])

  const savedTable = useRef();
  useImperativeHandle(ref, () => ({
    reFetch() {
      fetchAllContracts();
    }
  }));
  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <h2 className="contractBudget">Contract budget details</h2>
        <div style={{ overflowX: "auto" }}>
          <Input
            placeholder="Search by Contract Name"
            value={searchContract}
            onChange={(e) => setSearchContract(e.target.value)}
            style={{ marginBottom: 16 }}
            />
          <Table
            columns={columns}
            dataSource={allContracts}
            loading={loadingContract}
            pagination={{
              ...pagination,
              current: pagination?.current,
              onChange: onPageChange,
              showSizeChanger: false
           }}
          />
        </div>
      <AddBudget visible={showAddBudget} onClose={(e) => handleToggleAddBudget(e)} />
      <AddBudget visible={showEditBudget} isEdit={true} contractEdit={contractToEdit} onClose={(e) => onEditCloseModal(e)} />

      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. This action can’t be undone."}
        header={"Delete"}
        onConfirm={handleDelete}
        loading={deleteLoader}
      />
    </>
  );
});

export default BudgetSavedTable;
