import { Table, message } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
// import Edit from "./modals/Edit";
import Filters from "../Filters";
import { httpService } from "../../../../../services/httpService.service";

import AddTrackingCardModal from "../../../planning/VisitBoard/Modals/AddTrackingCardModal";
import PreviewTracking from "./modals/PreviewTracking";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";

function TableView({
  tracked,
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  setSiteVisitCardsForExcel,
  onPageChange,
  pagination,
  setPagination,
  filters,
  setFilters,
  handleFilterChange,
  onOk,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const onOkey = () => {
    setShowEditModal(!showEditModal);
    onOk();
  };

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };
  const [recordToDelete, setRecordToDelete] = useState();

  const handleDelete = (record) => {
    console.log(record);
    const siteVisitTrackingID = record.tracking;
    setRecordToDelete(siteVisitTrackingID);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (recordToDelete) {
      setLoadingDelete(true);
      httpService.delete(
        `/api/Site/deleteTrackings?includeIsDeleted=false`,
        [
          {
            id: recordToDelete,
            isDeleted: true,
          },
        ],
        (res) => {
          onOk();
          setShowDeleteModal(false);
          setLoadingDelete(false);
        },
        (err) => {
          console.log("ERROR: ", err);
          setShowDeleteModal(false);
          setLoadingDelete(false);
        }
      );
    }
  };

  useEffect(() => {
    const projectID = localStorage.getItem("currentProjectID");
    fetchSiteVisitCards(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters, pagination.current, pagination.pageSize]);

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Date of planned visit</span>,
      sorter: (a, b) =>
        a.dateOfPlannedVisit.localeCompare(b.dateOfPlannedVisit),
      dataIndex: "dateOfPlannedVisit",
      key: "dateOfPlannedVisit",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site visit status</span>,
      sorter: (a, b) => a.siteVisitStatus.localeCompare(b.siteVisitStatus),
      dataIndex: "siteVisitStatus",
      key: "siteVisitStatus",
      render: (text) => {
        let className;
        let fill;
        if (text === "Data Collection") {
          className = "progress";
          fill = "#3B81FE";
        } else if (text === "Post-data Collection") {
          className = "monitoring";
          fill = "#FC9403";
        } else if (text === "Finalization") {
          className = "review";
          fill = "#9670B8";
        } else if (text === "Delivered") {
          className = "delivered";
          fill = "#045B46";
        } else if (text === "IP Feedback") {
          className = "debrief";
          fill = "#0F7507";
        } else {
          className = "noStatus";
          fill = "red";
        }
        return (
          <div className={`${className} m-0`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,
      width: !tracked && "5%",
      render: (text, record) => (
        <div className="actionDiv">
          {tracked && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
            ></img>
          )}
          {isAuthorized([
            RolesEnum.SystemAdmin,
            RolesEnum.ProjectManager,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
            />
          )}

          {isAuthorized([RolesEnum.SystemAdmin, RolesEnum.ProjectManager]) &&
            tracked && (
              <img
                src={deleteIcon}
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(record)}
              />
            )}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    const formattedData = selectedRows.map((item) => ({
      "Site Visit Identifier": item.siteVisitIdentifier || "N/A",
      "Date of planned visit": item.dateOfPlannedVisit || "N/A",
      IP: item.ip || "N/A",
      Location: item.location || "N/A",
      Cluster: item.cluster || "N/A",
      Virtual: (item.virtual ? "YES" : "NO") || "N/A",
      "Site visit status": item.siteVisitStatus || "N/A",
      "Reporting Year": item?.reportingYear || "N/A",
      "Reporting Month": item?.reportingMonth || "N/A",
      "Training Date": item?.trainingDate || "N/A",
      "Training Score Info": item?.trainingScoreInfo || "N/A",
      "Actual Date of Visit": item?.actualDateOfVisit || "N/A",
      "Date of Report": item?.dateOfReport || "N/A",
      "Date of Debrief": item?.dateOfDebrief || "N/A",
      "Percentage of Data Collection Completed":
        item?.percentageOfDataCollectionCompleted || "N/A",
      "Field Team Reporting Status": item?.fieldTeamReportingStatus || "N/A",
      "Training Completed": item?.trainingIsCompleted ? "YES" : "NO",
    }));
    setSiteVisitCardsForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  // Formating data for the table
  const formatStatus = (status) => {
    if (status === 1) {
      return "Data Collection";
    } else if (status === 2) {
      return "Post-data Collection";
    } else if (status === 3) {
      return "Finalization";
    } else if (status === 4) {
      return "Delivered";
    } else if (status === 5) {
      return "IP Feedback";
    } else {
      return "No Status";
    }
  };

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      dateOfPlannedVisit: siteVisitCard?.visit?.plannedVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      siteVisitStatus: formatStatus(siteVisitCard?.tracking?.siteVisitStatus),
      reportingYear: siteVisitCard?.tracking?.reportingYear,
      reportingMonth: siteVisitCard?.tracking?.reportingMonth,
      trainingDate: siteVisitCard?.tracking?.trainingDate,
      trainingScoreInfo: siteVisitCard?.tracking?.trainingScoreInfo,
      actualDateOfVisit: siteVisitCard?.tracking?.actualDateOfVisit,
      dateOfReport: siteVisitCard?.tracking?.dateOfReport,
      dateOfDebrief: siteVisitCard?.tracking?.dateOfDebrief,
      percentageOfDataCollectionCompleted:
        siteVisitCard?.tracking?.percentageOfDataCollectionCompleted,
      fieldTeamReportingStatus:
        siteVisitCard?.tracking?.fieldTeamReportingStatus,
      trainingIsCompleted: siteVisitCard?.tracking?.trainingIsCompleted,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  return (
    <div style={{ paddingTop: "20px", borderBottom: "1px solid #e1e1e1" }}>
      <h5 className="tableViewHeaderText" style={{ fontSize: "20px" }}>
        {tracked ? "Tracking in progress" : "Waiting for tracking"}
      </h5>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Filters
        tracked={tracked}
        onChange={handleFilterChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        fetchSiteVisitCards={fetchSiteVisitCards}
      />
      <div>
        <Table
          loading={loading}
          rowSelection={{ ...rowSelection }}
          dataSource={formattedData}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
          className="tableTPM"
          scroll={{ x: "max-content" }}
        />
      </div>
      <AddTrackingCardModal
        visible={showEditModal}
        onCancel={handleToggleEditModal}
        onOk={() => onOkey()}
        siteVisitCardId={selectedSiteVisit}
        tracked={tracked}
        setMessage={setMessage}
      />
      <PreviewTracking
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete the tracking for this site visit card. This action can’t be undone."
        }
        header={"Delete "}
        onConfirm={handleConfirmDelete}
        loading={loadingDelete}
      />
    </div>
  );
}

export default TableView;
