import React, { useState, useEffect } from "react";
import "./IpSummary.scss";
import { Select, Radio, Progress, Button, Table, Spin } from "antd";
import iconCal from "../../../../Assets/svg/location4.svg";
import edit from "../../../../Assets/svg/editwhite.svg";
import snapshot1 from "../../../../Assets/svg/snapshot1.svg";
import snapshot2 from "../../../../Assets/svg/snapshot2.svg";
import snapshot3 from "../../../../Assets/svg/snapshot3.svg";
import snapshot4 from "../../../../Assets/svg/snapshot4.svg";
import snapshot5 from "../../../../Assets/svg/snapshot5.svg";
import snapshot6 from "../../../../Assets/svg/snapshot6.svg";
import IpCards from "./IpCards";
import ResourceSection from "./ResourceSection";
import LocationRatings from "./LocationRatings";
import deleteIcon from "../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../Assets/svg/table-action-preview.svg";
import EditIpModal from "./EditIpModal";
import TableView from "./TableView/TableView";
import { httpService } from "../../../../services/httpService.service";
import RedFlagCards from "./RedFlagCards";
import SiteVisitCards from "./SiteVisitCards";
import Map from "./Map";

const { Option } = Select;
function IpSummary() {
  const [periodType, setPeriodType] = useState("Month");
  const [shpwIpModal, setShowIpModal] = useState(false);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);
  useEffect(() => {
    const fetchProjectData = async () => {
      if (selectedPartner) {
        setLoading(true);
        try {
          const projectID = localStorage.getItem("currentProjectID");
          const apiProject = `/api/Projects/getIPsummary?implementingPartnerId=${selectedPartner}&projectId=${projectID}`;

          httpService.get(
            apiProject,
            (data) => {
              setProjectData(data);
              setLoading(false);
            },
            (error) => {
              console.error("Error fetching project data:", error);
              setLoading(false);
            }
          );
        } catch (error) {
          console.error("Error fetching project data:", error);
          setLoading(false);
        }
      }
    };

    fetchProjectData();
  }, [selectedPartner]);

  const handlePartnerChange = (value) => {
    setSelectedPartner(value);
  };
  return (
    <div className="IpSummaryDiv">
      <div className="ipSummaryHeader">
        <div style={{ width: "50%" }}>
          <span className="implementing-partner">Implementing Partner</span>
          <Select placeholder="Select an IP" onChange={handlePartnerChange}>
            {implementingPartners.map((implementingPartner) => (
              <Option
                key={implementingPartner.id}
                value={implementingPartner.id}
              >
                {implementingPartner.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      {selectedPartner && (
        <div>
          <Spin spinning={loading}>
            <div>
              <div className="tpmSummaryProject">
                <div className="tpmSummaryProjectName">
                  <div className="ipSummaryNameLeft">
                    <div className="circleIpSummary">
                      <img src={iconCal} alt="img"></img>
                    </div>
                    {projectData?.data?.ipResponse?.name}
                  </div>
                  {/* <Button onClick={handleShowEditIPModal} className="buttonEditIpName">
                  <img src={edit}></img>
                  </Button>
                  <EditIpModal visible={shpwIpModal} onClose={handleCloseIpModal} /> */}
                </div>
                <div className="ipSummaryProjectInfo">
                  <div className="profile-container">
                    <div className="profile-item">
                      <span className="ipNameLabel">Name</span>
                      <span className="ipNameValue">
                        {projectData?.data?.ipResponse?.name}
                      </span>
                    </div>
                    <div className="profile-item"></div>
                  </div>
                  <div className="profile-container">
                    {projectData?.data?.ipResponse?.locations?.states.map(
                      (state, idx) => (
                        <div key={idx} className="profile-item">
                          <span className="ipNameLabel">Country</span>
                          <span className="ipNameValue">
                            {state.country.stateName}
                          </span>
                          {state.stateName && (
                            <>
                              <span className="ipNameLabel">State</span>
                              <span className="ipNameValue">
                                {state.stateName}
                              </span>
                            </>
                          )}
                        </div>
                      )
                    )}
                  </div>

                  <div className="profile-container">
                    {projectData?.data?.ipResponse?.locations?.cities.map(
                      (city, idx) => (
                        <div key={idx} className="profile-item">
                          {projectData?.data?.ipResponse?.locations?.states
                            .length === 0 && (
                            <div>
                              <span className="ipNameLabel">Country</span>
                              <span className="ipNameValue">
                                {projectData?.data?.ipResponse?.locations
                                  ?.states[0]?.country.stateName || "N/A"}{" "}
                              </span>
                            </div>
                          )}
                          <span className="ipNameLabel">City</span>
                          <span className="ipNameValue">{city.name}</span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="activitySnapshot" style={{ marginTop: "20px" }}>
                {/* <div className="activitySnapshotHeaderContainer">
                  <h2 className="activitySnapshotHeader">Activity Snapshot</h2>
                  <Radio.Group
                    className="radioButtonGroup"
                    defaultValue="completed"
                  >
                    <Radio value="completed" style={{ fontSize: "16px" }}>
                      Completed
                    </Radio>
                    <Radio value="planned" style={{ fontSize: "16px" }}>
                      Planned
                    </Radio>
                  </Radio.Group>
                </div> */}
                <div className="activitySnapshotResultCard">
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {projectData?.data?.statusSnapshot?.siteVisitCards}
                      </h2>
                      <p className="activitySnapshotText">Site visit cards</p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot1} alt="img"></img>
                    </div>
                  </div>
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {projectData?.data?.statusSnapshot?.flagCards}
                      </h2>
                      <p className="activitySnapshotText">Flags cards</p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot2} alt="img"></img>
                    </div>
                  </div>
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {projectData?.data?.statusSnapshot?.taskCards}
                      </h2>
                      <p className="activitySnapshotText">Task cards</p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot3} alt="img"></img>
                    </div>
                  </div>
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {
                          projectData?.data?.statusSnapshot
                            ?.beneficiaryInterviews
                        }
                      </h2>
                      <p className="activitySnapshotText">
                        Beneficiary interviews
                        <br />
                      </p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot4} alt="img"></img>
                    </div>
                  </div>
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {projectData?.data?.statusSnapshot?.kllInterviews}
                      </h2>
                      <p className="activitySnapshotText">KII interviews</p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot5} alt="img"></img>
                    </div>
                  </div>
                  <div className="activitySnapshotCard">
                    <div className="activitySnapshotNumber">
                      <h2 className="activitySnapshotNumber1">
                        {
                          projectData?.data?.statusSnapshot
                            ?.observationChecklists
                        }
                      </h2>
                      <p className="activitySnapshotText">
                        Observation checklists
                      </p>
                    </div>
                    <div className="activitySnapshotImg">
                      <img src={snapshot6} alt="img"></img>
                    </div>
                  </div>
                </div>
              </div>
              <IpCards projectData={projectData}></IpCards>
              <SiteVisitCards projectData={projectData}></SiteVisitCards>
              <RedFlagCards projectData={projectData}></RedFlagCards>
              <ResourceSection projectData={projectData}></ResourceSection>
              <Map selectedIP={selectedPartner} />
              {/* <LocationRatings ></LocationRatings> */}
              <TableView projectData={projectData}></TableView>
            </div>
          </Spin>
        </div>
      )}
    </div>
  );
}

export default IpSummary;
