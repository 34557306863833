import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Button, Card, Spin, Divider } from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import "./Preview.scss";
import { httpService } from "../../../../../../services/httpService.service";
import { useFilePreview } from "../../../../completed/ReportingList/Download";

import PreviewVideoModal from "../../../../../components/VideoPreview/PreviewVideoModal";

const { Option } = Select;

const Preview = ({ visible, onClose, siteVisitCardId }) => {
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({
    videoName: "",
    videoUrl: "",
  });

  const { previewFiles } = useFilePreview();
  const { getVideoDetails } = useFilePreview();

  const openVideoModal = async (e, filePath, entityId) => {
    e.stopPropagation();
    e.preventDefault();

    const videoDetails = await getVideoDetails(filePath, entityId);
    if (videoDetails) {
      setCurrentVideo({
        videoName: videoDetails.videoName,
        videoUrl: videoDetails.videoUrl,
      });
      setVideoModalVisible(true);
    }
  };

  const fetchVideoDetails = async ({ entityId }) => {
    const details = await getVideoDetails("path/to/video.mp4", entityId);
    if (details) {
      console.log("Video Name:", details.videoName);
      console.log("Video URL:", details.videoUrl);
    }
  };

  const closeVideoModal = () => {
    setVideoModalVisible(false);
    setCurrentVideo("");
  };
  console.log(siteVisitCardId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [siteVisitCard, setSiteVisitCard] = useState({});
  const [filteredContacts, setFilteredContacts] = useState({
    siteContacts: [],
    pointOfContacts: [],
    KIIcontacts: [],
  });

  useEffect(() => {
    if (siteVisitCardId) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setSiteVisitCard(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [siteVisitCardId]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const contacts = siteVisitCard?.visit?.contacts || [];
    const siteContacts = contacts.filter(
      (contact) => contact.contactType === 1
    );
    const pointOfContacts = contacts.filter(
      (contact) => contact.contactType === 2
    );
    const KIIcontacts = contacts.filter((contact) => contact.contactType === 3);

    setFilteredContacts({
      siteContacts,
      pointOfContacts,
      KIIcontacts,
    });
  }, [siteVisitCard]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `Date: ${year}-${month}-${day}`;
  };

  const renderMonth = (month) => {
    switch (month) {
      case "01":
        return "January";
        break;
      case "02":
        return "Febuary";
        break;
      case "03":
        return "March";
        break;
      case "04":
        return "April";
        break;
      case "05":
        return "May";
        break;
      case "06":
        return "June";
        break;
      case "07":
        return "July";
        break;
      case "08":
        return "August";
        break;
      case "09":
        return "September";
        break;
      case "10":
        return "October";
        break;
      case "11":
        return "November";
        break;
      case "12":
        return "December";
        break;
      default:
        return "Not set";
    }
  };

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString.split(pattern)[1];

    if (match) {
      return match; // This captures everything after the time
    } else {
      return null;
    }
  };

  const formatCoordinationType = (status) => {
    if (status === 1) return "Remote";
    if (status === 2) return "In-Person";
  };

  const formatSiteVisitStatus = (status) => {
    if (status === 1) return "Data collection";
    if (status === 2) return "Post-data Collection";
    if (status === 3) return "Finalization";
    if (status === 4) return "Delivered";
    if (status === 5) return "IP Feedback";
  };

  const formatTeamRerportingStatus = (status) => {
    if (status === 1) return "Data collection";
    if (status === 2) return "Data Check";
    if (status === 3) return "Translation";
    if (status === 4) return "Field Reporting Completed";
  };

  const fulcrumAccount = siteVisitCard?.logistic?.fulcrumAccount;

  const formattedUrl =
    fulcrumAccount &&
    (fulcrumAccount.startsWith("http://") ||
      fulcrumAccount.startsWith("https://"))
      ? fulcrumAccount
      : `http://${fulcrumAccount}`;

  const hasCategoryReportFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 1
  );

  const hasCategoryOtherFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 2
  );

  const hasCategoryVideoFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 3 || item.fileCategory === 4
  );

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Site visit identifier</span>
            }
          >
            <p className="previewText">{siteVisitCard?.identifier}</p>
          </Form.Item>

          <hr />
          <p className="modalsHeader1">General details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Form.Item
            name="ip"
            label={
              <span style={{ fontWeight: "bold" }}>Implementing Partner</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">
              {siteVisitCard?.implementingPartner?.name}
            </p>
          </Form.Item> */}
            <Form.Item
              name="ip"
              label={
                <span style={{ fontWeight: "bold" }}>IP planning card</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.ipPlan?.identifier}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              cluster="cluster"
              label={<span style={{ fontWeight: "bold" }}>Cluster</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.cluster?.name}</p>
            </Form.Item>
            <Form.Item
              name="sector"
              label={<span style={{ fontWeight: "bold" }}>Sector</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.sector?.name}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {siteVisitCard?.subSector && (
              <Form.Item
                name="subSector"
                label={<span style={{ fontWeight: "bold" }}>Subsector</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{siteVisitCard?.subSector?.name}</p>
              </Form.Item>
            )}
          </div>

          <hr />
          <p className="modalsHeader1">Location Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="latitude"
              label={
                <span style={{ fontWeight: "bold" }}>Location on the map</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                Latitude: {siteVisitCard?.locationDetails?.latitude}° N,
                Longitude: {siteVisitCard?.locationDetails?.longitude}° W
              </p>
            </Form.Item>
            <Form.Item
              name="latitude"
              label={<span style={{ fontWeight: "bold" }}>Address</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.locationDetails?.address}
              </p>
            </Form.Item>
          </div>
          {siteVisitCard?.perish && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="latitude"
                label={<span style={{ fontWeight: "bold" }}>Parish</span>}
                style={{ width: "100%" }}
              >
                <p className="previewText">{siteVisitCard?.perish}</p>
              </Form.Item>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="isVirtual"
              label={
                <span style={{ fontWeight: "bold" }}>Virtual meeting? </span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.virtual ? "Yes" : "No"}
              </p>
            </Form.Item>
          </div>

          <hr />
          <p className="modalsHeader1">Visit details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Form.Item
              name="visitStartTime"
              label={
                <span style={{ fontWeight: "bold" }}>Visit start time</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {formatDate(siteVisitCard?.visit?.startTime)}
              </p>
            </Form.Item> */}
            <Form.Item
              name="dateOfPlannedVisit"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Date of planned visit
                </span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {formatDate(siteVisitCard?.visit?.plannedVisit, "time")}
              </p>
            </Form.Item>
          </div>

          {filteredContacts?.siteContacts.length > 0 && (
            <>
              <hr />
              <p className="modalsHeader1 mt-4">Site Contacts</p>
              <div className="container mt-4">
                <div className="contactsWrapper">
                  {filteredContacts.siteContacts.map((contact, index) => (
                    <div className="contactsInner">
                      <h1>Site contact {index + 1}</h1>
                      <div className="contactDetails">
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Contact name
                          </span>
                          <p>{contact.name}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Email</span>
                          <p className="previewtext">{contact.email}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Role</span>
                          <p>{contact.role}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Organization
                          </span>
                          <p>{contact.organization}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Phone</span>
                          <p>{contact.phone}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Skype</span>
                          <p>{contact.skype}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <hr />
          <p className="modalsHeader1 mt-4">Point of contacts</p>
          <div className="container mt-4">
            <div className="contactsWrapper">
              {filteredContacts?.pointOfContacts &&
                filteredContacts.pointOfContacts.map((contact, index) => (
                  <div className="contactsInner">
                    {/* <h1>Point of contacts {index + 1}</h1> */}
                    <div className="contactDetails">
                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Contact name</span>
                        <p>{contact.name}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Email</span>
                        <p className="previewtext">{contact.email}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Role</span>
                        <p>{contact.role}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Organization</span>
                        <p>{contact.organization}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Phone</span>
                        <p>{contact.phone}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Skype</span>
                        <p>{contact.skype}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {filteredContacts?.KIIcontacts.length > 0 && (
            <>
              <hr />
              <p className="modalsHeader1 mt-4">KII Contacts</p>
              <div className="container mt-4">
                <div className="contactsWrapper">
                  {filteredContacts.KIIcontacts.map((contact, index) => (
                    <div className="contactsInner">
                      <h1>KII contact {index + 1}</h1>
                      <div className="contactDetails">
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Contact name
                          </span>
                          <p>{contact.name}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Email</span>
                          <p className="previewtext">{contact.email}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Role</span>
                          <p>{contact.role}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Organization
                          </span>
                          <p>{contact.organization}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Phone</span>
                          <p>{contact.phone}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Skype</span>
                          <p>{contact.skype}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <div
            className="mt-5"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Specifics of on-site operation
                </span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {siteVisitCard?.specificsOfOnSiteOperation !== "null"
                  ? siteVisitCard?.specificsOfOnSiteOperation
                  : "N/A"}
              </p>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>Observation list?</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {siteVisitCard?.observationList ? "Yes" : "No"}
              </p>
            </Form.Item>
          </div>
          <div className="contactsWrapper">
            <div className="contactsInner" style={{}}>
              <h1>Interviews needed</h1>
              <div className="contactCounts">
                <div className="textWrapper">
                  <span style={{ fontWeight: "bold" }}>Beneficiaries</span>
                  <p>
                    {siteVisitCard?.visit?.interviewDetails?.beneficiariesCount}
                  </p>
                </div>
                <div className="textWrapper">
                  <span style={{ fontWeight: "bold" }}>KIIs</span>
                  <p>{siteVisitCard?.visit?.interviewDetails?.clientsCount}</p>
                </div>
                <div className="textWrapper">
                  <span style={{ fontWeight: "bold" }}>
                    Observation checklist
                  </span>
                  <p>
                    {
                      siteVisitCard?.visit?.interviewDetails
                        ?.observationChecklist
                    }
                  </p>
                </div>
                <div className="textWrapper">
                  <span style={{ fontWeight: "bold" }}>
                    Media (Video/Photos)
                  </span>
                  <p>{siteVisitCard?.visit?.interviewDetails?.mediaLimit}</p>
                </div>
              </div>
            </div>
          </div>
          {siteVisitCard?.visit?.interviewDetails?.samplingInstructions ? (
            <div
              className="mt-5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Sampling instructions (beneficiaries, sample, profile,
                    selection method, etc)
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.samplingInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          {siteVisitCard?.visit?.interviewDetails?.clientInstructions ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Instructions for any Kills
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.clientInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          {siteVisitCard?.visit?.interviewDetails?.otherInstructions ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Any other instructions
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.otherInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          {siteVisitCard?.visit?.interviewDetails?.clientInstructions ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Instructions for any Kills
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.clientInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <hr />
          <p className="modalsHeader1">Logistics</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>TPM Coordinator</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.tpmCoordinator?.firstName &&
                siteVisitCard?.logistic?.tpmCoordinator?.lastName
                  ? `${siteVisitCard?.logistic?.tpmCoordinator?.firstName} ${siteVisitCard?.logistic?.tpmCoordinator?.lastName}`
                  : siteVisitCard?.logistic?.tpmCoordinator?.email
                  ? ` ${siteVisitCard?.logistic?.tpmCoordinator?.email}`
                  : ""}
              </p>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>Coordination Type</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {formatCoordinationType(
                  siteVisitCard?.logistic?.coordinationType
                )}
              </p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Data Collection Platfom Account
                </span>
              }
              style={{ width: "100%" }}
            >
              <a href={formattedUrl} target="_blank" rel="noopener noreferrer">
                {formattedUrl}
              </a>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>Activation Date</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {formatDate(siteVisitCard?.logistic?.activationDate)}
              </p>
            </Form.Item>
          </div>
          <hr />

          <p className="modalsHeader1">Team</p>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Team name</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.team?.name}
              </p>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Team is local</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.isTeamLocal ? "Yes" : "No"}
              </p>
            </Form.Item>
          </div>
          <div className="teamDetails mb-5">
            <Card title="Team Details">
              <Divider />
              <p>
                <span style={{ fontWeight: "700" }}>Team Lead:</span>
                {siteVisitCard?.logistic?.team?.teamLead?.firstName}{" "}
                {siteVisitCard?.logistic?.team?.teamLead?.lastName}
              </p>
              {siteVisitCard?.logistic?.team?.enumerators?.map(
                (enumerator, index) => (
                  <p>
                    <span style={{ fontWeight: "700" }}>
                      Enumerator {index + 1}:
                    </span>
                    {enumerator?.firstName && enumerator?.lastName
                      ? ` ${enumerator?.firstName} ${enumerator?.lastName}`
                      : enumerator.email
                      ? ` ${enumerator.email}`
                      : ""}
                  </p>
                )
              )}
              {/* <div className="seeMoreBtn">
                <Button className="d-flex align-items-center">
                  See more
                  <DownOutlined />
                </Button>
              </div> */}
            </Card>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Postponed</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.isPostponed ? "Yes" : "No"}
              </p>
            </Form.Item>
            {siteVisitCard?.logistic?.isPostponed ? (
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>Postponed reason</span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.logistic?.postponedReason}
                </p>
              </Form.Item>
            ) : (
              ""
            )}
          </div>
          {siteVisitCard?.logistic?.isPostponed ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>Postponed details</span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.logistic?.postponedDetails}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          {siteVisitCard?.logistic?.notes ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Any other notes (Logistics/Coordination)
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">{siteVisitCard?.logistic?.notes}</p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <hr />

          {siteVisitCard?.siteVisitFiles?.length > 0 && (
            <p className="modalsHeader1">Resources</p>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {hasCategoryReportFiles && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "48%",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Site visit report documents
                </span>
                {siteVisitCard?.siteVisitFiles
                  ?.filter((item) => item.fileCategory === 1)
                  .map((file, index) => (
                    <a
                      type="button"
                      onClick={(e) => previewFiles(e, file.filePath, file.id)}
                      key={index}
                      rel="noopener noreferrer"
                      className="resourcesText mb-4"
                    >
                      {extractName(file.fileName) || file.fileName}
                    </a>
                  ))}
              </div>
            )}
            {hasCategoryOtherFiles && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "48%",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Other documents</span>
                {siteVisitCard?.siteVisitFiles
                  ?.filter((item) => item.fileCategory === 2)
                  .map((file, index) => (
                    <a
                      key={index}
                      type="button"
                      onClick={(e) => previewFiles(e, file.filePath, file.id)}
                      rel="noopener noreferrer"
                      className="resourcesText mb-4"
                    >
                      {extractName(file.fileName) || file.fileName}
                    </a>
                  ))}
              </div>
            )}
          </div>
          {hasCategoryVideoFiles && (
            <div className="mb-5" style={{ marginTop: "20px", width: "48%" }}>
              <span style={{ fontWeight: "bold" }}>Site video file</span>{" "}
              {siteVisitCard?.siteVisitFiles
                ?.filter(
                  (item) => item.fileCategory === 3 || item.fileCategory === 4
                )
                .map((file, index) => (
                  <div
                    key={index}
                    onClick={(e) =>
                      file?.fileName?.endsWith("mp4")
                        ? openVideoModal(e, file.filePath, file.id)
                        : previewFiles(e, file.filePath, file.id)
                    }
                    // onClick={(e) => previewFiles(e, file.filePath, file.id)}
                    style={{ cursor: "pointer" }}
                    className="resourcesText mb-4"
                  >
                    {extractName(file.fileName) || file.fileName}
                  </div>
                ))}
            </div>
          )}

          {siteVisitCard.tracking && (
            <>
              <p className="modalsHeader1">Tracking</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="reportingYear"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting year</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.reportingYear}
                  </p>
                </Form.Item>
                <Form.Item
                  name="reportingMonth"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting month</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {renderMonth(siteVisitCard?.tracking?.reportingMonth)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="trainingIsCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Training Completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.trainingIsCompleted
                      ? "Yes"
                      : "No"}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="trainingDate"
                  label={
                    <span style={{ fontWeight: "bold" }}>Training date</span>
                  }
                  style={{ width: "33%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.trainingDate)}
                  </p>
                </Form.Item>
                {siteVisitCard?.tracking?.trainingScoreInfo && (
                  <Form.Item
                    name="trainingScoreInfo"
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        Training score info
                      </span>
                    }
                    style={{ width: "67%" }}
                  >
                    <p className="previewText">
                      {siteVisitCard?.tracking?.trainingScoreInfo}
                    </p>
                  </Form.Item>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="actualDateOfVisit"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Actual date of visit
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.actualDateOfVisit)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfReport"
                  label={
                    <span style={{ fontWeight: "bold" }}>Date of report</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfReport)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfDebrief"
                  label={
                    <span style={{ fontWeight: "bold" }}>Date of debrief</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfDebrief)}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="percentageOfDataCollectionCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      % of data collection completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {`${siteVisitCard?.tracking?.percentageOfDataCollectionCompleted} %`}
                  </p>
                </Form.Item>
                <Form.Item
                  name="siteVisitStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Site visit status
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatSiteVisitStatus(
                      siteVisitCard?.tracking?.siteVisitStatus
                    )}
                  </p>
                </Form.Item>
                <Form.Item
                  name="fieldTeamReportingStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Field Team Reporting Status
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatTeamRerportingStatus(
                      siteVisitCard?.tracking?.fieldTeamReportingStatus
                    )}
                  </p>
                </Form.Item>
              </div>
            </>
          )}

          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>

      <PreviewVideoModal
        visible={videoModalVisible}
        handleCancel={() => setVideoModalVisible(false)}
        fileName={currentVideo.videoName}
        videoUrl={currentVideo.videoUrl}
      />
    </Modal>
  );
};

export default Preview;
