import axios from "axios";
// import https from "./http";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const get = async (url, params = {}) => {
  try {
    const response = await axios.get(`${API_BASE_URL}${url}`, {
      headers: getAuthHeaders(),
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url, data, config = {}) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${url}`, data, {
      headers: getAuthHeaders(),
      ...config,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const put = async (url, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}${url}`, data, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const del = async (url, data = null) => {
  try {
    const options = {
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "application/json-patch+json", // Specify the content type expected by the server
      },
    };

    if (data) {
      options.data = data; // Axios requires data to be in the 'data' field for DELETE requests with body
    }

    const response = await axios.delete(`${API_BASE_URL}${url}`, options);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function isAuthorized(allowedRoles) {
  const roleId = localStorage.getItem("roleId");
  return Array.isArray(allowedRoles)
    ? allowedRoles.includes(roleId)
    : allowedRoles === roleId;
}
