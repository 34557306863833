import { Button, DatePicker, Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import { httpService } from "../../../../services/httpService.service";

import { debounce } from "lodash";
const { Option } = Select;
//months
const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

const years = [
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
];

const Filters = ({
  filters,
  setFilters,
  pagination,
  setPagination,
  onFiltersChange,
  inputSearch,
  setInputSearch,
}) => {
  //Handle FIlters

  const [showFilters, setShowFilters] = useState(false);

  const [implementingPartners, setImplementingPartners] = useState();
  const [clusters, setClusters] = useState();

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      Search: "",
      ImplementingPartnerId: "",
      State: "",
      SiteVisitStatus: "",
      ClusterId: "",
      Month: "",
      Year: "",
    });
    setPagination({ ...pagination, current: 1 });
    setInputSearch("");
  };

  // const handleSearch = (event) => {
  //   const Search = event.target.value.toLowerCase();
  //   setFilters((prevFilters) => ({ ...prevFilters, Search }));
  //   // setPagination({ ...pagination, current: 1 });
  //   onFiltersChange({ Search: Search });
  // };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: query,
      }));
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));

      onFiltersChange({ Search: query });
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setInputSearch(search);
    debouncedSearchHandler(search);
  };

  const handleIp = (ip) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ImplementingPartnerId: ip,
    }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ ImplementingPartnerId: ip });
  };

  const handleProgressStatus = (progressStatus) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SiteVisitStatus: Number(progressStatus),
    }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ SiteVisitStatus: Number(progressStatus) });
  };

  const handleLocation = (location) => {
    setFilters((prevFilters) => ({ ...prevFilters, State: location }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ State: location });
  };

  const handleCluster = (cluster) => {
    setFilters((prevFilters) => ({ ...prevFilters, ClusterId: cluster }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ ClusterId: cluster });
  };

  const handleMonth = (month) => {
    setFilters((prevFilters) => ({ ...prevFilters, Month: month }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Month: month });
  };

  const handleYear = (year) => {
    setFilters((prevFilters) => ({ ...prevFilters, Year: year }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Year: year });
  };

  //get data from API for filters
  const [allCountries, setAllContries] = useState();

  useEffect(() => {
    const apiContries = "/api/GeoData/countries";

    httpService.get(
      apiContries,
      (res) => setAllContries(res.data),
      (error) => {
        console.log(error);
      }
    );

    const apiClusters = "/api/Site/getAllClusters";
    httpService.get(
      apiClusters,
      (res) => setClusters(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input
          placeholder="Search..."
          onChange={handleSearchChange} // Correct prop name
          suffix={<img src={SearchIcon} alt="search icon" />}
          value={inputSearch}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              showSearch
              value={
                filters.ImplementingPartnerId
                  ? filters.ImplementingPartnerId
                  : undefined
              }
              onChange={handleIp}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location</p>
            <Select
              onChange={handleLocation}
              value={filters.State ? filters.State : undefined}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries &&
                allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Progress status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              value={
                filters.SiteVisitStatus ? filters.SiteVisitStatus : undefined
              }
              onChange={handleProgressStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={1}>Data collection</Option>
              <Option value={2}>Post-data Collection</Option>
              <Option value={3}>Finalization</Option>
              <Option value={4}>Delivered</Option>
              <Option value={5}>IP Feedback</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              onChange={handleCluster}
              value={filters.ClusterId ? filters.ClusterId : undefined}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {clusters &&
                clusters.map((cluster) => (
                  <Option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month </p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              onChange={handleMonth}
              allowClear
              showSearch
              value={filters.Month ? filters.Month : undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <Select
              onChange={handleYear}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              value={filters.Year ? filters.Year : undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}

              {/* Add more role options as needed */}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
