import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Row,
  Select,
  Switch,
  Card,
  Divider,
  Button,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import AddTeamCardModal from "../../../implementation/FieldLogisticsBoard/modals/AddTeamCardModal";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";

const { Option } = Select;

const TeamDetails = ({ onCancel, singleTeam, setSingleTeam }) => {
  const [teams, setTeams] = useState([]);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [showAddTeamCardModal, setShowAddTeamCardModal] = useState(false);
  const [singleTeamLoader, setSingleTeamLoader] = useState(false);

  const [teamCardVisible, setTeamCardVisible] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);

  const getAllTeams = () => {
    const contractId = localStorage.getItem("currentContractId");
    const apiTeams = `/api/User/getAllTeams?contractId=${contractId}`;
    setTeamLoading(true);

    httpService.get(
      apiTeams,
      (res) => {
        setTeams(res.data?.filter((e) => e?.isActive === true));
        setTeamLoading(false);
      },
      (error) => {
        console.log(error);
        setTeamLoading(false);
      }
    );
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  const selectTeam = (teamID) => {
    setSingleTeamLoader(true);
    httpService.get(
      `/api/User/getTeamById?teamId=${teamID}`,
      (res) => {
        setSingleTeam(res?.data);
        setSingleTeamLoader(false);
      },
      (err) => {
        console.log(err);
        setSingleTeamLoader(false);
      }
    );
  };

  const onClose = () => {
    setTeamCardVisible(false);
  };

  useEffect(() => {
    if (onCancel) {
      onClose();
    }
  }, [onCancel]);

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="tripLogisticsCard">
        <div className="tripLogicWrapper">
          <h4>
            If you don't see your team on the list, create a new team card.
          </h4>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowAddTeamCardModal(true)}
            iconPosition="end"
            className="inviteButtonTPM"
            style={{ display: "flex", flexDirection: "row" }}
          >
            Create team card
          </Button>
        </div>
      </div>
      {/* {teamCardVisible ? ( */}
      {/* <div className="teamAdd"> */}
      <AddTeamCardModal
        visible={showAddTeamCardModal}
        onClose={() => setShowAddTeamCardModal(false)}
        fetchTeams={getAllTeams}
        setMessage={setMessage}
      />
      {/* </div> */}
      {/* ) : (
        <></>
      )} */}
      <Row gutter={16} className="d-flex align-items-center">
        <Col span={12}>
          <Spin spinning={teamLoading}>
            <Form.Item
              label="Select a team name for data collection"
              name="team"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) ===
                  0
                }
                placeholder="Select a team"
                onChange={(team) => selectTeam(team)}
              >
                {teams &&
                  teams.map((team) => (
                    <Option key={team?.id} value={team?.id}>
                      {team?.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Spin>
        </Col>

        <Col span={6}>
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <Form.Item
              name="isTeamLocal"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <span>Team is local</span>
          </div>
        </Col>
      </Row>

      {singleTeamLoader ? (
        <Spin style={{ width: "100%" }} />
      ) : singleTeam?.members?.length > 0 ? (
        <div className="teamDetails">
          <Card title="Team Details">
            <Divider />
            {singleTeam?.members
              ?.filter((el) => el?.roleName === "Field Team Leader")
              ?.map((el) => (
                <p key={el.id}>
                  <span style={{ fontWeight: "700" }}>Team Lead:</span>{" "}
                  {el?.username}
                </p>
              ))}
            {singleTeam?.drivers?.length > 0 && (
              <p>
                <span style={{ fontWeight: "700" }}>Driver:</span>{" "}
                {singleTeam?.drivers[0]?.name}
              </p>
            )}
            {singleTeam?.members
              ?.filter((el) => el?.roleName !== "Field Team Leader")
              ?.map((el, i) => (
                <p key={el.id}>
                  <span style={{ fontWeight: "700" }}>Member {i + 1}:</span>{" "}
                  {el?.username}
                </p>
              ))}
          </Card>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TeamDetails;
