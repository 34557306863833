import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Input, message } from "antd";
import { httpService } from "../../../services/httpService.service";
import "./styles.scss";
import MessagePopUp from "../../components/Messages/MessagePopUp";

const ForgetPasswordCode = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleVerify = async () => {
    setLoading(true);

    httpService.post(
      "/api/Account/confirmCode",
      {
        email,
        code,
        changeOrForgotPasswordConfirmation: true,
      },
      (res) => {
        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Confirmation!",
          text: "Code verified!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        navigate("/newPassword", { state: { email } });
      },
      (error) => {
        setLoading(false);
        const errorMessage = error?.errors?.[0]?.message;

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage || "Invalid code!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleResendCode = async () => {
    httpService.post(
      "/api/Account/resendCode",
      {
        email,
      },
      () => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Verification code resent!",
          text: "Verification code has been resent, please check your email!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const errorMessage = error?.errors?.[0]?.message;

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage || "Failed to resend code.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleCodeChange = (value) => {
    const isAnumber = /^\d+$/.test(value);

    if (isAnumber) {
      if (value?.length < 7 || value?.length === 0) {
        setCode(value);
        console.log("Confirm code:", code);
      } else {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "You can not add more than 6 characters!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      }
    } else {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "The confirmation code must have only numbers",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="col-md-6 right-column">
          <div className="form-wrapper1">
            <h2 className="verificationCodeHeader">Verification Code</h2>
            <p className="verificationCodeText">
              Please enter the verification code that we sent to {email}
            </p>
            <form className="w-100 d-flex flex-column ">
              <div className="OTP w-100 d-flex justify-content-center">
                <Input.OTP
                  type="text"
                  maxLength={6}
                  value={code}
                  onChange={handleCodeChange}
                  placeholder="Enter code"
                  className="code-input"
                />
              </div>

              <div className="buttonsModal">
                <Button
                  className="addButtonTPM"
                  key="submit"
                  type="primary"
                  onClick={handleVerify}
                  loading={loading}
                  disabled={loading}
                >
                  Verify code
                </Button>
              </div>
            </form>
            {/* Resend code link */}
            <p className="verificationCodeText">
              If you didn’t receive a verification code,{" "}
              <span
                style={{ color: "#E164A0", cursor: "pointer" }}
                onClick={handleResendCode}
              >
                click here to resend it
              </span>{" "}
              or send us an email at
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordCode;
