import React, { useContext, useState, useEffect } from "react";
import { Table } from "antd";
import { DataContext } from "../DataContext";
import PreviewTaskModal from "../Modals/PreviewTaskModal";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import EditDraftTask from "../Modals/EditDraftTask";

const taskTypes = [
  { id: 1, name: "Ops preparation" },
  { id: 2, name: "Data Collection" },
  { id: 3, name: "Data check" },
  { id: 4, name: "Translation" },
  { id: 5, name: "Field report" },
  { id: 6, name: "Data cleaning" },
  { id: 7, name: "Data analysis" },
  { id: 8, name: "Data translation" },
  { id: 9, name: "IP report" },
  { id: 10, name: "Donor report" },
  { id: 11, name: "Other" },
];

const DraftsTableView = ({ dataUpdated, setDataUpdated }) => {
  const context = useContext(DataContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  if (!context) {
    return <></>;
  }
  const {
    loading,
    fetchDraftsData,
    draftsData,
    setDraftsData,
    draftsPagination,
    setDraftsPagination,
  } = context;

  if (dataUpdated) {
    fetchDraftsData();
    setDataUpdated(false);
  }

  const columns = [
    {
      title: <span className="styledTitle">Last updated</span>,
      dataIndex: ["taskSite", "lastUpdated"],
      key: "lastUpdated",
      sorter: (a, b) => a.lastUpdated.localeCompare(b.lastUpdated),
      render: (text, record) => (
        <span className="styledDataIndex3">{record.lastUpdated}</span>
      ),
    },
    {
      title: <span className="styledTitle">Deadline</span>,
      dataIndex: "deadline",
      key: "deadline",
      width: 250,
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "taskUsers",
      key: "assignedTo",
      sorter: (a, b) => {
        const aUserNames = typeof a.taskUsers === "string" ? a.taskUsers : "";
        const bUserNames = typeof b.taskUsers === "string" ? b.taskUsers : "";
        return aUserNames.localeCompare(bUserNames);
      },
      render: (taskUsers) => (
        <span className="styledDataIndex3">
          {typeof taskUsers === "string" && taskUsers.length > 0
            ? taskUsers
            : taskUsers?.length === 0
            ? "unassigned"
            : "N/A"}
        </span>
      ),
    },

    {
      title: <span className="styledTitle">Location</span>,
      dataIndex: "taskLocation",
      key: "location",
      sorter: (a, b) =>
        a.taskLocation?.country?.stateName.localeCompare(
          b.taskLocation?.country?.stateName
        ),
      render: (taskLocation) => (
        <span className="styledDataIndex3">
          {taskLocation?.country?.stateName || "N/A"}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Type</span>,
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        const typeA = a.type ? a.type.toString() : "";
        const typeB = b.type ? b.type.toString() : "";
        return typeA.localeCompare(typeB, undefined, { sensitivity: "base" });
      },
      render: (typeId) => {
        const taskType = taskTypes.find((task) => task.id === typeId);
        return (
          <span className="styledDataIndex3">
            {taskType ? taskType.name : "Unknown Type"}
          </span>
        );
      },
    },
    {
      key: "action",
      render: (text, record) => (
        <div className="actionDiv d-flex justify-content-evenly">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleEdit(record)}
              style={{ cursor: "pointer" }}
              alt="Edit"
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              onClick={() => handleDelete(record)}
              style={{ cursor: "pointer" }}
              alt="Delete"
            />
          )}
        </div>
      ),
      fixed: isLargeScreen && draftsData.length > 0 ? "right" : undefined,
    },
  ];

  const handlePreview = (record) => {
    setSelectedTaskId(record.id);
    setShowPreviewModal(true);
  };

  const handleEdit = (record) => {
    setSelectedTaskId(record.key);
    setShowEditModal(true);
  };

  const handleDelete = async (record) => {
    setSelectedTaskId(record.key);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedTaskId) {
      setDeleteLoading(true)
      const requestBody = [{ id: selectedTaskId }];
      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(
        url,
        requestBody,
        (response) => {
          const message =
            response.data?.message ||
            "The task was successfully deleted from your database.";

          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Task deleted successfully!",
            text: message,
          });
          fetchDraftsData();
          setShowDeleteModal(false);
          setDeleteLoading(false);
        },
        (error) => {
          console.error("Error deleting record:", error);
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem deleting this task, please try again.";

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          fetchDraftsData();
          setShowDeleteModal(false);
          setDeleteLoading(false);
        }
      );
    }
  };

  const handleTableChange = (current, pageSize) => {
    setDraftsPagination({
      ...draftsPagination,
      current: current,
      pageSize: pageSize,
    });
    fetchDraftsData(current, pageSize); // Fetch new page data
  };

  return (
    <div className="d-flex gap-3 flex-column">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      {isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.FieldLogistics,
      ]) && (
        <Table
          className="mt-5"
          loading={loading}
          dataSource={draftsData}
          columns={columns}
          pagination={{
            current: draftsPagination.current,
            pageSize: draftsPagination.pageSize,
            total: draftsPagination.total,
            showSizeChanger: true,
            onChange: handleTableChange,
          }}
        />
      )}

      {selectedTaskId && (
        <>
          <EditDraftTask
            visible={showEditModal}
            setShowEditModal={setShowEditModal}
            taskId={selectedTaskId}
            setMessage={setMessage}
          />
          <PreviewTaskModal
            isVisible={showPreviewModal}
            onClose={() => setShowPreviewModal(false)}
            taskId={selectedTaskId}
          />
          <DeleteComponent
            visible={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            text={
              "You're about to delete this task. This action can’t be undone. "
            }
            header={"Delete "}
            onConfirm={handleConfirmDelete}
            loading={deleteLoading} 
          />
        </>
      )}
    </div>
  );
};

export default DraftsTableView;
