import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, message, Spin } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { httpService } from "../../../../services/httpService.service";

const { Option } = Select;

const PersonalInformation = React.memo(() => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    socialDetails: {
      skypeId: "",
    },
    languages: [],
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const fetchLanguages = () => {
      httpService.get(
        "/api/GeoData/languages",
        (res) => {
          setLanguages(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    fetchLanguages();
  }, []);

  const fetchUserData = async () => {
    const userId = localStorage.getItem("userID");
    setLoading(true);

    httpService.get(
      `/api/User?userId=${userId}`,
      (res) => {
        const userData = res.data;

        setInitialValues({
          firstName: userData.userDetails.firstName || "",
          lastName: userData.userDetails.lastName || "",
          gender: userData.userDetails.gender || "",
          socialDetails: {
            skypeId: userData.userDetails.socialDetails.skypeId || "",
          },
          languages: userData.languages?.map((lang) => lang.id) || [],
        });
     
        localStorage.setItem("profilePicture", userData.profilePictureUrl);   console.log(userData.profilePictureUrl)
        setLoading(false); // Move this inside the callback
      },
      () => {
        setLoading(false); // Ensure loader stops even if there is an error
      }
    );
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    gender: Yup.string().required("Please select your gender"),
    socialDetails: Yup.object({
      skypeId: Yup.string().required("Please enter your Skype ID"),
    }),
    languages: Yup.array().min(1, "Please select at least one language"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formattedValues = {
      ...values,
      languages: values.languages.map((languageId) => ({ languageId })),
    };
    localStorage.setItem(
      "username",
      `${formattedValues.firstName} ${formattedValues.lastName}`
    );

    setLoading(true);
    httpService.put(
      "/api/User/updateUserDetails",
      formattedValues,
      (res) => {
        const message = res.message;
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Red flag added successfully!",
          text: message || "User updated successfully.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        resetForm();
        fetchUserData();
        setLoading(false);
        window.location.reload();
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message || "Failed to update user details.";
        console.error("Error updating user details:", error);

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Error updating user details",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Spin spinning={loading} />
        </div>
      )}
      <Spin spinning={loading}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values, handleChange }) => (
            <Form
              className="personalInformation mb-5"
              autoComplete="off"
              layout="vertical"
            >
              <div className="mt-8 mb-2">
                <h4>Personal information</h4>
              </div>
              <Row gutter={24} style={{ paddingBottom: "20px" }}>
                <Col xs={24} sm={12} style={{ paddingBottom: "10px" }}>
                  <div className="form-item">
                    <label htmlFor="firstName">First Name</label>
                    <Field name="firstName">
                      {({ field }) => (
                        <Input
                          {...field}
                          value={values.firstName}
                          placeholder="First Name"
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} style={{ paddingBottom: "10px" }}>
                  <div className="form-item">
                    <label htmlFor="lastName">Last Name</label>
                    <Field name="lastName">
                      {({ field }) => (
                        <Input
                          {...field}
                          value={values.lastName}
                          placeholder="Last Name"
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} style={{ paddingBottom: "10px" }}>
                  <div className="form-item">
                    <label htmlFor="gender">Gender</label>
                    <Field name="gender">
                      {({ field }) => (
                        <Select
                          {...field}
                          value={values.gender}
                          placeholder="Select an option"
                          onChange={(value) => setFieldValue("gender", value)}
                        >
                          <Option value={1}>Male</Option>
                          <Option value={2}>Female</Option>
                          <Option value={3}>Other</Option>
                        </Select>
                      )}
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} style={{ paddingBottom: "10px" }}>
                  <div className="form-item">
                    <label htmlFor="socialDetails.skypeId">Skype ID</label>
                    <Field name="socialDetails.skypeId">
                      {({ field }) => (
                        <Input
                          {...field}
                          value={values.socialDetails.skypeId}
                          placeholder="Skype ID"
                          onChange={handleChange}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="socialDetails.skypeId"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} style={{ paddingBottom: "10px" }}>
                  <div className="form-item">
                    <label htmlFor="languages">Languages</label>
                    <Field name="languages">
                      {({ field }) => (
                        <Select
                          {...field}
                          mode="multiple"
                          allowClear
                          showSearch
                          placeholder="Select languages"
                          onChange={(value) =>
                            setFieldValue("languages", value)
                          }
                          value={values.languages}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().startsWith(input.toLowerCase())
                          }
                        >
                          {languages.map((language) => (
                            <Option key={language.id} value={language.id}>
                              {language.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Field>
                    <ErrorMessage
                      name="languages"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className=" mt-8" align="middle">
                <Col>
                  <Button
                    key="back"
                    onClick={handleCancel}
                    className="inviteButtonTPM"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    // loading={loading}
                    className="addButtonTPM"
                  >
                    Save changes
                    {/* {loading ? <Spin /> : <div></div>} */}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Spin>
    </div>
  );
});

export default PersonalInformation;
