import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import Flags from "./Flags";
import PostVisitCard from "./PostVisitCards/PostVisitCard";

function MainFlags() {
  const [activeComponent, setActiveComponent] = useState("Flags");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const openActiveComponent = (collector) => {
    setActiveComponent(collector);
  };
  const renderComponent = () => {
    if (activeComponent === "Flags") {
      return (
        <div>
          <Flags />
        </div>
      );
    } else if (activeComponent === "PostVisits") {
      return (
        <div>
          <PostVisitCard></PostVisitCard>
        </div>
      );
    }
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner text="Flags"></Banner>
      <div style={{ background: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "Flags"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="Flags"
                checked={activeComponent === "Flags"}
                onChange={() => openActiveComponent("Flags")}
              />
              Red Flags board
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "PostVisits"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="PostVisits"
                checked={activeComponent === "PostVisits"}
                onChange={() => openActiveComponent("PostVisits")}
              />
              Post-visit card board
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default MainFlags;
