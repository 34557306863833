import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  message,
  Divider,
} from "antd";

import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import IconStrategy from "../../../../../Assets/svg/task.svg";

const { Option } = Select;

const AddNewTaskModal = ({
  visible,
  setIsModalVisible,
  setMessage,
  updateData,
}) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);
  const [taskMembers, settaskMembers] = useState([]);
  const [taskMembersLoading, settaskMembersLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setIsModalVisible(false);
    // setLocations([]);
    setCities([]);
    setSiteVisits([]);
    settaskMembers([]);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => setLocations(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleLocationChange = async (country) => {
    setCities([]);
    setSiteVisits([]);
    settaskMembers([]);

    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });

    httpService.get(
      `/api/GeoData/cities?countryId=${country}`,
      (res) => setCities(res.data),
      (error) => console.log(error)
    );

    const fetchSiteVisitsByCountry = async () => {
      const projectId = localStorage.getItem("currentProjectID");
      if (!projectId) {
        console.error("No project ID found in local storage");
        return;
      }
      const countryData = locations.find((location) => location.id == country);
      const url = `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`;
      httpService.get(
        url,
        (response) => {
          if (response && Array.isArray(response.data)) {
            setSiteVisits(response.data);
          } else {
            console.error(
              "API response does not contain a data array:",
              response
            );
            setSiteVisits([]);
          }
        },
        (error) => {
          console.error("Failed to fetch site visits by country:", error);
          setSiteVisits([]);
        }
      );
    };

    fetchSiteVisitsByCountry();
  };

  const handleSubmit = async () => {
    const projectId = localStorage.getItem("currentProjectID");

    setButtonLoading(true);
    form
      .validateFields()
      .then((values) => {
        const formattedDeadline = values.deadline
          ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : null;

        const payload = {
          projectId,
          name: values.taskName,
          deadline: formattedDeadline,
          taskLocation: {
            countryId: values.location,
            municipality: values.location2,
            // cityId: values.city,
            district: values.district,
          },
          collectionMethod: values.collectionMethod,
          type: values.taskType,
          otherType: values.otherType,
          description: values.description,
          numberOfInterviews: values.numberOfInterviews,
          contactInformation: values.contactInformation,
          additionalNotes: values.additionalNotes,
          status: values.taskStatus,
          siteIds: values.siteIds,
          assignedTo: values.assignedTo,
        };

        httpService.post(
          "/api/Tasks",
          payload,
          (response) => {
            const message =
              response.data?.message ||
              "The task was successfully added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: IconStrategy,
              title: "Task added successfully!",
              text: message,
            });

            form.resetFields(); // Reset form fields
            setIsModalVisible(false); // Close modal
            updateData();
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            handleLeave();
            setButtonLoading(false);
          },
          (error) => {
            console.error("Failed to submit task:", error);

            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this task, please try again.";

            setMessage({
              trigger: true,
              type: "danger",
              icon: IconStrategy,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setButtonLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
        }, 3000);
        setButtonLoading(false);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSiteVisit = async (siteVisitIds) => {
    settaskMembersLoading(true);

    const queryString = siteVisitIds
      .map((id, index) => `siteVisitIds[${index}]=${encodeURIComponent(id)}`)
      .join("&");

    httpService.get(
      `/api/Site/getTeamBySiteVisits?${queryString}`,
      (response) => {
        const formattedData = response.data.map((data) => data.members) || [];
        const allMembers = formattedData.flat();

        const uniqueMembers = allMembers.filter(
          (member, index, self) =>
            index === self.findIndex((m) => m.id === member.id)
        );

        settaskMembers(uniqueMembers);
        settaskMembersLoading(false);
      },
      (error) => {
        console.error("Failed to fetch team by site visit:", error);
        settaskMembersLoading(false);
      }
    );
  };

  // if (!context) {
  //   return <></>;
  // }
  // const { fetchData } = context;

  const handleSaveDraft = () => {
    setSaveDraftLoading(true); // Show loader

    const values = form.getFieldsValue();
    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSaveDraftLoading(false); // Hide loader if validation fails
      return;
    }

    const formattedDeadline = values.deadline
      ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    let selectedCountry;

    if (values?.location) {
      selectedCountry = locations?.find(
        (country) => country?.id === values?.location
      );
    }
    let selectedUser;
    if (values.assignedTo) {
      selectedUser = taskMembers?.find(
        (member) => member?.id.toString() === values.assignedTo.toString()
      );
    }

    const formData = {
      name: values.taskName,
      deadline: formattedDeadline,
      taskLocation: {
        country: selectedCountry,
        municipality: values.location2,
        district: values.district,
      },
      collectionMethod: values.collectionMethod,
      type: values.taskType,
      otherType: values.otherType,
      description: values.description,
      numberOfInterviews: values.numberOfInterviews,
      contactInformation: values.contactInformation,
      additionalNotes: values.additionalNotes,
      status: values.taskStatus,
      siteIds: values.siteIds,
      assignedTo: selectedUser,
    };
    console.log(formData);
    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(formData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 4,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft task card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "New draft task card added successfully!",
            text: message,
          });

          form.resetFields();
          updateData();
          handleLeave();
          setSaveDraftLoading(false); // Hide loader after success
        },
        (error) => {
          console.error("There was an error adding the task!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft task card to your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setSaveDraftLoading(false); // Hide loader after error
        }
      )
      .finally(() => {
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
        setSaveDraftLoading(false); // Hide loader after completion
      });
  };

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Add new task</h2>
        </>
      }
      open={visible}
      onCancel={handleCancel}
      width={800}
      closable={false}
      destroyOnClose
      footer={[
        <Button
          className="inviteButtonTPM"
          key="back"
          onClick={handleSaveDraft}
          loading={saveDraftLoading || buttonLoading}
        >
          Save as draft
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={saveDraftLoading || buttonLoading}
          className="addButtonTPM"
        >
          Add new task
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Task card identifier" name="taskIdentifier">
          <Input
            placeholder="xxxx - Task Name - Task Type - date created"
            disabled
          />
        </Form.Item>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Task name"
              name="taskName"
              rules={[
                { required: true, message: "Please enter the task name" },
              ]}
            >
              <Input placeholder="Enter text here" />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item
            label="Task Type"
            name="taskType"
            rules={[{ required: true, message: "Please select a task type" }]}
          >
            <Select placeholder="Select an option">
              {taskTypes.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Deadline"
              name="deadline"
              rules={[
                { required: true, message: "Please select the deadline date" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Country"
              name="location"
              rules={[{ required: true, message: "Please select Country" }]}
            >
              <Select
                placeholder="Select an option"
                onChange={handleLocationChange}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {locations.map((location) => (
                  <Option key={location?.id} value={location?.id}>
                    {location?.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please select City" }]}
            >
              <Select
                placeholder="Select a city"
                showSearch
                disabled={cities?.length < 1}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        <Col span={24}>
          <Form.Item
            label="Pick the site visit card"
            name="siteIds"
            rules={[{ required: true, message: "Please input your site ID!" }]}
          >
            <Select
              placeholder="Select a site visit"
              onChange={handleSiteVisit}
              showSearch
              allowClear
              mode="multiple"
              disabled={siteVisits?.length < 1 ? true : false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {siteVisits?.map((visit) => (
                <Option key={visit.id} value={visit.id}>
                  {`${visit?.identifier} - ${visit?.ipPlan?.identifier}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Assigned to a field coordinator"
            name="assignedTo"
            rules={[{ required: false, message: "Please select a person" }]}
          >
            <Select
              placeholder="Select an option"
              disabled={taskMembers?.length < 1 ? true : false}
            >
              {taskMembersLoading ? (
                <Option disabled>
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 0",
                    }}
                  />
                </Option>
              ) : (
                taskMembers.map((member) => (
                  <Option key={member?.id} value={member?.id}>
                    {member?.firstName ?? ""} {member?.username ?? ""} (
                    {member?.roleName ?? ""})
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Collection method" name="collectionMethod">
            <Input placeholder="Enter the collection method" />
          </Form.Item>
        </Col>

        <Form.Item label="Task description" name="description">
          <Input.TextArea rows={3} placeholder="Enter the task description" />
        </Form.Item>
        <Col span={6}>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber
              min={1}
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
        </Col>
        <Form.Item label="Contact information" name="contactInformation">
          <Input placeholder="Enter the contact information" />
        </Form.Item>
        <Form.Item label="Any additional notes" name="additionalNotes">
          <Input.TextArea rows={3} placeholder="Enter additional notes" />
        </Form.Item>
        <Form.Item
          label="Task status"
          name="taskStatus"
          rules={[{ required: true, message: "Please select the task status" }]}
        >
          <Select placeholder="Select the task status">
            <Option value={1}>Assigned</Option>
            <Option value={2}>Accepted</Option>
            <Option value={3}>Completed</Option>
            <Option value={4}>OnHold</Option>
            <Option value={5}>Archived</Option>
          </Select>
        </Form.Item>
      </Form>

      <Divider />
    </Modal>
  );
};

export default AddNewTaskModal;
