import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const columnWidths = [];
    const keys = Object.keys(data[0]);
    keys?.forEach((key, colIndex) => {
        let maxLength = key.length; 
        data?.forEach(row => {
            maxLength = Math.max(maxLength, row[key] ? row[key].toString().length : 0);
        });
        columnWidths[colIndex] = { wch: maxLength + 2 }; 
    });

    worksheet['!cols'] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, `${fileName}.xlsx`);
};
