import React, { useState } from "react";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import Edit from "./modals/Edit";
import Preview from "./modals/Preview";
import Filters from "./Filters";
import { message, Select, Table } from "antd";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import { httpService } from "../../../../../services/httpService.service";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";

const { Option } = Select;

function DriverCards({
  drivers,
  setDrivers,
  loadingDrivers,
  setLoadingDrivers,
  setMessage,
  fetchDrivers,
}) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState("");

  const handleToggleEditModal = (record) => {
    setSelectedDriverId(record?.key);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    setSelectedDriverId(record?.key);
    setShowPreviewModal(!showPreviewModal);
  };

  //Handle FIlters

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleDelete = () => {
    const payload = { id: selectedDriverId, isDeleted: true };

    httpService.delete(
      "/api/Driver",
      payload,
      (response) => {
        message =
          response.data || "Driver was successfully deleted from the database!";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Driver added successfully!",
          text: message,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        fetchDrivers();
      },
      (error) => {
        const messageError = error?.errors?.[0]?.message;
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            messageError ||
            "We had a problem deleting this driver from your database, please try again.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const columns = [
    {
      title: <span className="styledTitle">Driver name</span>,
      dataIndex: "driverName",
      key: "driverName",
      sorter: (a, b) => a.driverName.localeCompare(b.driverName),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Driver phone</span>,
      dataIndex: "driverPhone",
      key: "driverPhone",
      sorter: (a, b) => a.driverPhone.localeCompare(b.driverPhone),
      render: (text) => <span className="styledDataIndex3">+{text}</span>,
    },
    {
      title: <span className="styledTitle">Main location</span>,
      dataIndex: "mainLocation",
      key: "mainLocation",
      sorter: (a, b) => a.mainLocation.localeCompare(b.mainLocation),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: drivers?.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
            RolesEnum.LimitedGuest
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
            ></img>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedDriverId(record?.key);
              }}
            ></img>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Driver Cards</span>
      </div>
      {/* <Filters data={drivers} setDrivers={setDrivers} /> */}

      <div>
        <Table
          loading={loadingDrivers}
          dataSource={drivers}
          columns={columns}
          className="tableTPM"
        />
      </div>
      <Edit
        visible={showEditModal}
        onClose={handleToggleEditModal}
        driverId={selectedDriverId}
        setMessage={setMessage}
        fetchDrivers={fetchDrivers}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        driverId={selectedDriverId}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete this driver. This action can’t be undone. "
        }
        header={"Delete "}
        onConfirm={handleDelete}
      />
    </div>
  );
}

export default DriverCards;
