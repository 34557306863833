import { Select, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import CalendarViewComponent from "../../../../components/CalendarView/CalendarViewComponent";
import Filters from "../Filters";
import PreviewModal from "../TableView/modals/Preview";

const CalendarView = ({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();
  const [pagination, setPagination] = useState();

  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });
  const [inputSearch, setInputSearch] = useState(filters.Search);
  const projectID = localStorage.getItem("currentProjectID");

  useEffect(() => {
    fetchSiteVisitCards(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year,
      true
    );
  }, [filters]);

  const events = siteVisitCards.reduce((acc, siteVisitCard) => {
    const dateKey = siteVisitCard?.ipPlan?.initialMeeting?.split("T")[0];

    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push({
      type: "success",
      status: siteVisitCard?.ipPlan?.currentIPStatus,
      content: siteVisitCard?.identifier,
      id: siteVisitCard.id,
    });
    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format("YYYY-MM-DD");
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter((event) => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (
        filters.search &&
        !event.content.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <>
      <Filters
        onFiltersChange={handleFiltersChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        setInputSearch={setInputSearch}
        inputSearch={inputSearch}
      />
      <CalendarViewComponent
        getListData={getListData}
        setClickedEventId={handleEventClicked}
      />
      <PreviewModal
        visible={showPreviewModal}
        onClose={handleCancel}
        siteVisitCardId={clickedEventId}
      />
    </>
  );
};

export default CalendarView;
