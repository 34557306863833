import React, { useState } from "react";
// import "../Implementation.scss";
import { Button, Tabs } from "antd";
import AddButton from "../../../../Assets/svg/addButton.svg";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import { httpService } from "../../../../services/httpService.service";

function MonitoringRoundOverview() {
  // Handle show/hide Modal for "Add new site visit card" button
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const fetchSiteVisitCards = async (
    projectId,
    pageSize,
    pageNumber,
    search,
    implementingPartnerId,
    country,
    siteVisitStatus,
    FieldTeamReportingStatus,
    clusterId,
    month,
    year,
    IgnorePagination
  ) => {
    setLoading(true);

    const params = {
      ProjectId: projectId,
      IncludeOnlyWithTracking: true,
      pageNumber: pageNumber,
      pageSize: pageSize,
      Search: search,
      ImplementingPartnerId: implementingPartnerId,
      State: country,
      SiteVisitStatus: siteVisitStatus,
      FieldTeamReportingStatus: FieldTeamReportingStatus,
      ClusterId: clusterId,
      Month: month,
      Year: year,
      IgnorePagination: IgnorePagination,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const sitevisitcardsData = response?.data;
        setSiteVisitCards(sitevisitcardsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  // This will return which page is going to be rendered
  const items = [
    {
      key: "1",
      label: "Kanban view",
      children: (
        <KanbanView
          siteVisitCards={siteVisitCards}
          setSiteVisitCards={setSiteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Calendar view",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Monitoring round overview</span>
        <div className="tableViewHeaderButtons">
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default MonitoringRoundOverview;
