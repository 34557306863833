// ConfirmModal.js
import React from "react";
import { Modal } from "antd";
import Warning from "../../../Assets/svg/warning.svg";
import "./style.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const { confirm } = Modal;

const showConfirm = (onOk, onCancel) => {
  confirm({
    title: "Are you sure you want to leave?",
    icon: (
      <img
        src={Warning}
        alt="Warning"
        style={{ width: "30px", height: "30px", flexShrink: "0" }}
      />
    ),
    content: (
      <p className="d-flex flex-column align-items-center">
        All unsaved changes in this card will be lost.
        <div>
          <span style={{ fontWeight: "700", color: "#3A3737" }}>
            Are you sure you want to continue?
          </span>
        </div>
      </p>
    ),
    okText: "Yes, exit",
    cancelText: "Cancel",
    okButtonProps: {
      className: "addButtonTPM",
    },
    cancelButtonProps: {
      className: "inviteButtonTPM",
    },
    closeIcon: (
      <img src={cancelX} style={{ marginLeft: "auto", cursor: "pointer" }} />
    ),
    async onOk() {
      try {
        await onOk();
      } catch (e) {
        console.error("Oops, errors!", e);
      }
    },
    onCancel,
  });
};

export default showConfirm;
