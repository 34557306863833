import { Button, Modal } from "antd";
import React from "react";
import downloadList from "../../../../Assets/svg/downloadwhite.svg";
import { useFilePreview } from "../../completed/ReportingList/Download";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";

function PreviewModal({ visible, onClose, item }) {
  const { previewFiles } = useFilePreview();

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString.split(pattern)[1];

    if (match) {
      return match;
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">File information</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" className="mt-4  inviteButtonTPM" onClick={onClose}>
          Close
        </Button>,
        <Button
          onClick={(e) => previewFiles(e, item.filePath, item.id)}
          className="mt-4 addButtonTPM"
        >
          Download <img src={downloadList} alt="Download" />
        </Button>,
      ]}
    >
      {/* <Spin spinning={loading}> */}
      {item ? (
        <>
          <p>
            <strong>File name:</strong>{" "}
            {extractName(item?.fileName) || item?.fileName}
          </p>
          <p>
            <strong>Identifier:</strong> {item.identifier}
          </p>
        </>
      ) : (
        <p>Loading item details...</p>
      )}
      {/* </Spin> */}
    </Modal>
  );
}

export default PreviewModal;
