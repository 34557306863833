import { create } from "zustand";

export const useSiteVisitStore = create((set) => ({
  siteVisitList: [],
  otherDocuments: [],
  siteVideos: [],

  setSiteVisitList: (data) => {
    set({
      siteVisitList: data,
    });
  },

  setOtherDocuments: (data) => {
    set({
      otherDocuments: data,
    });
  },

  setSiteVideos: (data) => {
    set({
      siteVideos: data,
    });
  },

  handleDeleteSiteVisit: () => {
    set({
      siteVisitList: [],
    });
  },
  handleDeleteOtherDocuments: () => {
    set({
      otherDocuments: [],
    });
  },
  handleDeleteSiteVideos: () => {
    set({
      siteVideos: [],
    });
  },
}));
