import React, { useState, useEffect, useCallback } from "react";
import { Select, Input, Button, Spin } from "antd";
import KanbanBoardComponent from "../../../components/KanbanBoard/KanbanBoardComponent";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import PreviewModal from "../modals/PreviewModal";
import { Pagination } from "antd";
import { httpService } from "../../../../services/httpService.service";

import { debounce } from "lodash";

const { Option } = Select;

const formatDate = (isoString) => {
  const date = new Date(isoString);

  const formattedDate = date.toLocaleDateString("en-CA");
  const formattedTime = date.toLocaleTimeString("en-GB", { hour12: false });

  return `${formattedDate} Time: ${formattedTime}`;
};

const KanbanIPPlansView = (ipPlansData) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [ipPlans, setIPPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    ip: undefined,
    month: undefined,
    year: undefined,
    IPStatus: undefined,
    AssignedTo: undefined,
    search: "",
  });

  const [inputSearch, setInputSearch] = useState(filters.search);
  const [implementingPartners, setImplementingPartners] = useState([]);

  const handleTaskClick = (taskId) => {
    setSelectedTaskId(taskId);
    if (taskId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };
  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    const fetchImplementingPartners = async () => {
      const workspaceId = localStorage.getItem("currentContractId");
      const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

      httpService.get(
        apiIps,
        (res) => setImplementingPartners(res.data),
        (error) => console.log(error)
      );
    };

    fetchImplementingPartners();

    httpService.get(
      "/api/User/getAllusers",
      (res) => setUsers(res.data),
      (error) => console.log(error)
    );
  }, []);

  const buildQueryString = (params) => {
    const query = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined && params[key] !== "") {
        query.append(key, params[key]);
      }
    });
    return query.toString();
  };

  const fetchIPPlans = async () => {
    const projectId = localStorage.getItem("currentProjectID");
    const queryString = buildQueryString({
      ProjectId: projectId,
      ImplementingPartnerId: filters.ip,
      Year: filters.year,
      Month: filters.month,
      IPStatus: filters.IPStatus,
      AssignedTo: filters.AssignedTo,
      Search: filters.search,
      pageSize: pagination.pageSize,
      pageNumber: pagination.current,
    });

    setLoading(true);
    httpService.get(
      `/api/ipplan/getAllIPPlans?${queryString}`,
      (response) => {
        const ipPlansData = response.data.map((ipPlan) => ({
          id: ipPlan?.id,
          trackingId: ipPlan?.id,
          content: {
            title: ipPlan?.id,
          },
          column: ipPlan?.currentIPStatus,
          ////
          id: ipPlan?.id,
          content: {
            title: ipPlan?.identifier,
            statusUpdated: formatDate(ipPlan?.updatedDate) || "Never updated",
            ip: ipPlan?.implementingPartner?.name,
            state:
              ipPlan?.office?.states?.[0]?.country?.stateName ||
              ipPlan?.office?.cities?.[0]?.country?.stateName,
            municipality:
              ipPlan?.office?.states?.[0]?.stateName ||
              ipPlan?.office?.cities?.[0]?.name,
          },
          column: ipPlan?.currentIPStatus,
        }));
        const metaData = response.metaData;

        setPagination({
          current: metaData?.pageNumber,
          pageSize: metaData?.pageSize,
          total: metaData?.totalItemCount,
        });
        setIPPlans(ipPlansData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching IP Plans:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchIPPlans(ipPlansData, pagination.current, pagination.pageSize);
  }, [filters, pagination.current, pagination.pageSize, ipPlansData]);

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: query,
      }));
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setInputSearch(search);
    debouncedSearchHandler(search);
  };

  const handleClearFilters = () => {
    setFilters({
      ip: undefined,
      month: undefined,
      year: undefined,
      IPStatus: undefined,
      AssignedTo: undefined,
      search: "",
    });
    setInputSearch("");
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const changeIPPlanStatus = (draggedTask) => {
    setLoading(true);

    const requestBody = {
      ipPlanId: draggedTask.id,
      ipPlanStatus: draggedTask.column,
      TrackingId: draggedTask.trackingId,
    };

    // Use the appropriate method if `upload` is not applicable
    httpService
      .put(
        "/api/ipplan/changeIPPlanStatus",
        requestBody,
        (res) => {
          const message =
            res.message || "The IP Plan status has been updated successfully.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Status Updated Successfully!",
            text: message,
          });
          fetchIPPlans(ipPlansData); // Refresh IP Plans
        },
        (error) => {
          console.error(
            "There was an error updating the IP Plan status!",
            error
          );

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message; // Use the error message from the backend
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem updating the IP Plan status, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  const columns = [
    { id: 1, title: "Discussion", color: "#AA1A5F" },
    { id: 2, title: "Document Review", color: "#FC9403" },
    { id: 3, title: "Pre Planning Phase", color: "#325899" },
    { id: 4, title: "Data collection", color: "#3B81FE" },
    { id: 5, title: "Closed", color: "#555" },
  ];

  const onPageChange = (current, pageSize) => {
    setPagination({ ...pagination, current, pageSize });
  };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Spin spinning={loading}>
        <div className="filtersWrapper my-5">
          <div className="containerFilters">
            <Input
              placeholder="Type something here"
              name="search"
              value={inputSearch}
              onChange={handleSearchChange}
            />
            <Button className="clearButton" onClick={handleClearFilters}>
              Clear All
            </Button>
            <Button onClick={handleToggleFilters} className="toggleButton">
              {!showFilters ? "Show Filters" : "Hide Filters"}
            </Button>
          </div>
          {showFilters && (
            <div className="filtersContainer">
              <div className="filter">
                <p className="filterText">Implementing Partner</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select an IP"
                  onChange={(value) => handleFilterChange("ip", value)}
                  value={filters.ip}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {implementingPartners.map((implementingPartner) => (
                    <Option
                      key={implementingPartner.id}
                      value={implementingPartner.id}
                    >
                      {implementingPartner.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Current IP status</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select an IP status"
                  onChange={(value) => handleFilterChange("IPStatus", value)}
                  value={filters.IPStatus}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  <Option key={1} value={1}>
                    Discussion
                  </Option>
                  <Option key={2} value={2}>
                    Document Review
                  </Option>
                  <Option key={3} value={3}>
                    Pre-phase
                  </Option>
                  <Option key={4} value={4}>
                    Data Collection
                  </Option>
                  <Option key={5} value={5}>
                    Closed
                  </Option>
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Assigned to:</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a user"
                  onChange={(value) => handleFilterChange("AssignedTo", value)}
                  value={filters.AssignedTo}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {users &&
                    users.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.userDetails.firstName
                          ? user.userDetails.firstName
                          : "N/A"}{" "}
                        {user.userDetails.lastName
                          ? user.userDetails.lastName
                          : "N/A"}{" "}
                        - ({user.userName})
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Month</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a month"
                  onChange={(value) => handleFilterChange("month", value)}
                  value={filters.month}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  <Option value="1">January</Option>
                  <Option value="2">February</Option>
                  <Option value="3">March</Option>
                  <Option value="4">April</Option>
                  <Option value="5">May</Option>
                  <Option value="6">June</Option>
                  <Option value="7">July</Option>
                  <Option value="8">August</Option>
                  <Option value="9">September</Option>
                  <Option value="10">October</Option>
                  <Option value="11">November</Option>
                  <Option value="12">December</Option>
                </Select>
              </div>

              <div className="filter">
                <p className="filterText">Year</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a year"
                  onChange={(value) => handleFilterChange("year", value)}
                  value={filters.year}
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    const children = React.Children.toArray(
                      option.children
                    ).join("");
                    return children.toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {Array.from({ length: 81 }, (_, i) => 2030 - i).map(
                    (year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
          )}
        </div>
        <PreviewModal
          visible={showPreviewModal}
          record={selectedTaskId}
          onOk={handlePreviewOk}
          onCancel={handleCancel}
        />
        <div id="excludedStrictMode">
          <KanbanBoardComponent
            tasks={ipPlans}
            columns={columns}
            changeStatus={changeIPPlanStatus}
            setClickedTaskId={handleTaskClick}
          />
          <div className="d-flex justify-content-end my-5">
            <Spin spinning={loading}>
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(page, pageSize) => {
                  onPageChange(page, pageSize);
                  setPagination({ ...pagination, current: page, pageSize });
                }}
              />
            </Spin>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default KanbanIPPlansView;
