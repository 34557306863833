import React from 'react'
import "./styles.scss"
import BudgetSummaryBody from './BudgetSummaryBody'

const BudgetSummary = () => {
  return (
    <div className='budgetWrapper'>
      <BudgetSummaryBody />
    </div>
  )
}

export default BudgetSummary