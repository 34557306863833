import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input, Spin, Form } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";

const { Option } = Select;

const PreviewOffice = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const formRef = React.useRef(); // Reference to the Form

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (visible && record) {
      setLoading(true);
      httpService.get(
        `/api/Office?id=${record.id}`,
        (res) => {
          setLoading(false);
          if (formRef.current) {
            formRef.current.setFieldsValue({
              officeName: res.data.name,
              country: res.data.countries.map((country) => country.id),
            });
          }
        },
        (error) => {
          console.log("Office Fetch Error:", error);
          setLoading(false);
        }
      );
    }
  }, [visible, record]);

  const fetchCountries = () => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => {
        setAllCountries(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("testiiing", record)

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={400}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Preview Office</h2>
      <Spin spinning={loading || submitting}>
        <Form ref={formRef} layout="vertical">
          <Form.Item
            label="Office Name"
            name="officeName"
            rules={[
              { required: true, message: "Please enter the office name" },
              {
                max: 100,
                message: "Office name must be at most 100 characters long",
              },
            ]}
          >
            <p>{record?.name}</p>
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[
              { required: true, message: "Please select at least one country" },
            ]}
          >
        <p>{record?.country}</p>
          </Form.Item>

          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PreviewOffice;
