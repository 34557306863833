import React, { useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../TableView/Filters";
import { Pagination, Spin } from "antd";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";

const KanbanViewReporting = ({
  tasks,
  columns,
  changeStatus,
  setFilters,
  handleFilterChange,
  onPageChange,
  pagination,
  setPagination,
  loading,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };
  console.log(tasks);

  return (
    <>
      <Filters onChange={handleFilterChange} setFilters={setFilters} />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
      <Spin spinning={loading}>
        <KanbanBoardComponent
          tasks={tasks}
          columns={columns}
          changeStatus={changeStatus}
          setClickedTaskId={handleTaskClick}
        />
      </Spin>
      <div className="d-flex justify-content-end my-5">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) => {
            onPageChange(page, pageSize);
            setPagination({ ...pagination, current: page, pageSize });
          }}
        />
      </div>
    </>
  );
};

export default KanbanViewReporting;
