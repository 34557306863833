import React, { useState, useEffect } from "react";
import { Spin, Pagination } from "antd";
import KanbanBoardComponent from "../../../components/KanbanBoard/KanbanBoardComponent";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import Preview from "./modals/Preview";
import Filters from "../TableView/Filters";

const KanbanView = ({
  redFlags,
  setRedFlags,
  fetchRedFlags,
  loading,
  setLoading,
  pagination,
  setPagination,
  onPageChange,
  filters,
  setFilters,
  handleFilterChange,
}) => {
  const [clickedTaskId, setClickedTaskId] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  // useEffect(() => {
  //   const projectID = localStorage.getItem("currentProjectID");
  //   fetchRedFlags(
  //     projectID,
  //     filters.Search,
  //     filters.ImplementingPartnerId,
  //     filters.State,
  //     filters.Month,
  //     filters.Year
  //   );
  // }, [filters, pagination.current, pagination.pageSize]);

  const columns = [
    { id: 1, title: "Assigned", color: "#FC9403" },
    { id: 2, title: "In progress", color: "#3B81FE" },
    { id: 3, title: "On hold", color: "#D46600" },
    { id: 4, title: "Resolved", color: "#045B46" },
    { id: 5, title: "Archived", color: "#555" },
  ];

  const changeRedFlagStatus = (draggedTask) => {
    setLoading(true);
    const { id, column } = draggedTask;

    const updatedRedFlags = redFlags.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          flagStatus: column,
        };
      }
      return card;
    });

    setRedFlags(updatedRedFlags);

    const requestBody = {
      redFlagId: draggedTask.id,
      redFlagStatus: draggedTask.column,
    };

    setLoading(true);
    httpService.put(
      "/api/RedFlag/changeStatus",
      requestBody,
      (response) => {
        fetchRedFlags(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Status updated successfully!",
          text: "Red flag status was correctly updated to your database.",
        });
        setLoading(false);
      },
      (error) => {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "We had a problem updating red flag status, please try again.",
        });
        setLoading(false);
      }
    );

    setTimeout(() => {
      setMessage({
        trigger: false,
        type: "",
        icon: "",
        title: "",
        text: "",
      });
    }, 3000);
  };

  const formatDate = (date) => {
    if (!date || typeof date !== "string") {
      console.error("Invalid date:", date);
      return "";
    }
    const formattedDate = date.split("T")[0];
    return formattedDate;
  };
  // Format kanban data
  const formatData = (redFlag) => {
    return {
      id: redFlag.id,
      content: {
        title: redFlag.name,
        statusUpdated: formatDate(redFlag.updatedDate),
        ip: redFlag.implementingPartnerName,
        state: redFlag.office.country,
        municipality: redFlag.office.city,
      },
      column: redFlag.flagStatus,
    };
  };

  const formattedData = redFlags.map(formatData);

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        record={clickedTaskId}
      />
      <Spin spinning={loading}>
        <Filters
          onChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          pagination={pagination}
          setPagination={setPagination}
          fetchRedFlags={fetchRedFlags}
        />
        <div id="excludedStrictMode">
          <KanbanBoardComponent
            tasks={formattedData}
            columns={columns}
            changeStatus={changeRedFlagStatus}
            setClickedTaskId={handleTaskClick}
          />
        </div>
        <div className="d-flex justify-content-end my-5">
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) => {
              onPageChange(page, pageSize);
              setPagination({ ...pagination, current: page, pageSize });
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default KanbanView;
