import React from "react";
import { Button, Modal } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const MembersFailed = ({ visible, onClose, response }) => {
  return (
    <Modal
      title="Created members information"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" className="mt-4  inviteButtonTPM" onClick={onClose}>
          Close
        </Button>,
      ]}
      closable={false}
    >
      <div
        style={{
          borderRadius: "6px",
          padding: "1px 0px 1px 10px",
          gap: "10px",
          background: "#FADCEA",
          marginBottom: "10px",
        }}
      >
        <h1
          style={{
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "22px",
            margin: 0,
          }}
        >
          Note: Here you have information about the{" "}
          <span style={{ fontWeight: 700 }}>users</span> you created!{" "}
        </h1>
      </div>
      <div
        className="d-flex flex-column gap-1 mb-5 text-center border border-2"
        style={{ height: "400px", overflowX: "scroll" }}
      >
        {response?.map((message, index) => (
          <p
            key={index}
            style={{
              color: message.includes("successfully") ? "green" : "red",
            }}
          >
            {message}
          </p>
        ))}
      </div>
    </Modal>
  );
};

export default MembersFailed;
