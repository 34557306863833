import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Modal, Spin } from "antd";
import "./LoginInformation.scss";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import Locker from "./../../../../Assets/svg/locker.svg";

const LoginInformation = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [code, setCode] = useState("");
  const [confLoaded, setConfLoaded] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const userEmail = localStorage.getItem("userEmail");

  const onFinish = async (values) => {
    httpService.post(
      "/api/account/changepassword",
      {
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmPassword,
      },
      (response) => {
        console.log("Password changed successfully", response);
        const message = response.message;
        setMessage({
          trigger: true,
          type: "success",
          icon: Locker,
          title: "Password changed successfully!",
          text:
            message ||
            "Your passowrd has bin changed successfully saved into the database!",
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: Locker,
            title: "",
            text: "",
          });
        }, 3000);
        form.resetFields();
        setLoadingPage(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to change password. Please try again.";

        if (errorMessage.includes("Code not confirmed yet!")) {
          handleResendCode();
          setIsModalVisible(true);
        } else {
          setMessage({
            trigger: true,
            type: "danger",
            icon: Locker,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        }

        setLoadingPage(false);
      }
    );
  };

  const handleVerify = async (formValues) => {
    setLoading(true);

    await httpService.post(
      "/api/Account/confirmCode",
      {
        email: userEmail,
        code,
        changeOrForgotPasswordConfirmation: true,
      },
      (res) => {
        const message = res.message;
        setLoading(false);
        if (message === "Code successfully confirmed") {
          setLoadingPage(true);
          onFinish(formValues);
        }

        setIsModalVisible(false);
      },
      (error) => {
        setLoading(false);
        const messageError = error?.errors?.[0]?.message;
        setMessage({
          trigger: true,
          type: "danger",
          icon: Locker,
          title: "Oops! A problem has occurred!",
          text: messageError || "Invalid code!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleResendCode = () => {
    setSendingCode(true);
    setMessage({
      trigger: true,
      type: "success",
      icon: Locker,
      title: "Please wait!",
      text: "A verification code is being sent to your email.",
    });
    setTimeout(() => {
      setMessage({
        trigger: false,
        type: "",
        icon: "",
        title: "",
        text: "",
      });
    }, 3000);
    setConfLoaded(true);
    httpService.get(
      `/api/Account/resendCode?email=${userEmail}`,
      (res) => {
        setConfLoaded(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: Locker,
          title: "Done!",
          text: "Verification code sent!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSendingCode(false);
      },
      (error) => {
        setConfLoaded(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: Locker,
          title: "Oops! A problem has occurred!",
          text: "Failed to resend code.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSendingCode(false);
      }
    );
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const handleCodeChange = (value) => {
    setCode(value);
    console.log(value, typeof value);

    const isAnumber = /^\d+$/.test(value);

    if (isAnumber) {
      if (value?.length < 7 || value?.length === 0) {
        setCode(value);
        console.log("Confirm code:", code);
      } else {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "You can not add more than 6 characters!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      }
    } else {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "The confirmation code must have only numbers",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
  };

  return (
    <>
      <div className="loginInformation">
        <div
          className="accountSettings"
          style={{ padding: "60px 80px 80px 80px" }}
        >
          <p className="title">Log in information</p>
          <MessagePopUp
            trigger={message.trigger}
            type={message.type}
            icon={message.icon}
            messageTitle={message.title}
            messageText={message.text}
          />
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="mt-8 mb-5">
              <h4>Username and password</h4>
            </div>
            <Spin spinning={loadingPage}>
              <Row gutter={24}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={<span className="label-text">Email</span>}
                    name="email"
                  >
                    <span>{userEmail}</span>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={<span className="label-text">Old password</span>}
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your old password!",
                      },
                    ]}
                    autoComplete="off"
                  >
                    <Input.Password
                      autoComplete="off"
                      placeholder="Type your old password"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={<span className="label-text">New password</span>}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters long!",
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="off"
                      placeholder="Type your new password"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span className="label-text">Confirm new Password</span>
                    }
                    name="confirmPassword"
                    dependencies={["newPassword"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The two passwords do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      autoComplete="off"
                      placeholder="Confirm your new password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <span className="password-list-text">
                Your new password must contain at least:
              </span>
              <ul>
                <li className="password-list-text">
                  Password must have at least 8 characters
                </li>
                <li className="password-list-text">One uppercase character</li>
                <li className="password-list-text">One lowercase character</li>
                <li className="password-list-text">One number</li>
                <br />
              </ul>
              <Row gutter={16} className="mt-8" align="middle">
                <Col>
                  <Button
                    key="back"
                    onClick={handleCancel}
                    className="inviteButtonTPM"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="addButtonTPM">
                    Save changes
                  </Button>
                </Col>
              </Row>
            </Spin>
          </Form>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
      >
        <h2 className="verificationCodeHeader">Verification Code</h2>
        <p className="verificationCodeText">
          Please enter the verification code that we sent to {userEmail}
        </p>
        <Spin spinning={sendingCode}>
          <form>
            {/* <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter code"
            className="code-input"
          /> */}
            <div className="OTP w-100 d-flex justify-content-center">
              <Input.OTP
                value={code}
                type="text"
                maxLength={6}
                onChange={handleCodeChange}
                placeholder="Enter code"
                className="code-input"
              />
            </div>
            <div className="buttonsModal">
              <Button
                className="addButtonTPM"
                key="submit"
                type="primary"
                onClick={() => {
                  const values = form.getFieldsValue(); // Get form values
                  handleVerify(values); // Pass values to handleVerify
                }}
                loading={loading}
              >
                Verify code
              </Button>
            </div>
          </form>
        </Spin>
        <Spin spinning={loading}>
          <p className="verificationCodeText" style={{ marginTop: "20px" }}>
            If you didn’t receive a verification code,{" "}
            <span
              style={{ color: "#E164A0", cursor: "pointer" }}
              onClick={handleResendCode}
            >
              click here to resend it
            </span>{" "}
            or send us an email at info@kaptechgroup.com
          </p>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginInformation;
