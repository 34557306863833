import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, Form, Row, Switch, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const Postponement = ({ postponed, setPostponed }) => {
  return (
    <div>
      <Row gutter={18} align="middle">
        <Col span={24}>
          <Form.Item
            name="isPostponed"
            label="Postponed"
            valuePropName="checked"
            value={postponed}
          >
            <Switch onChange={(e) => setPostponed(e)} />
          </Form.Item>
        </Col>
      </Row>

      {postponed ? (
        <Col span={18}>
          <Form.Item name="postponedReason" label="Postponed reason">
            <Input disabled={!postponed} placeholder="Enter a reason account" />
          </Form.Item>
          <Form.Item name="postponedDetails" label="Postponed Details">
            <TextArea
              rows={2}
              placeholder="Enter details of the postpone"
              disabled={!postponed}
            />
          </Form.Item>
          <Form.Item
            name="notes"
            label="Any other notes (Logistics/Coordination)"
          >
            <TextArea
              rows={2}
              placeholder="Enter details of the postpone"
              disabled={!postponed}
            />
          </Form.Item>
        </Col>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Postponement;
