import React, { useState } from "react";
import PersonalInformation from "./PersonalInformation";
import "./index.scss";
import { Button, Col, Row } from "antd";
import DeactivateAccountModal from "./DeactivateAccountModal";
import editIcon from "../../../../Assets/svg/editIcon.svg";
import EditProfilePicture from "./EditProfilePicture";

const ProfileInformation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);

  const handleAccountModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  let userEmail = localStorage.getItem("userEmail");
  let userName = localStorage.getItem("username");
  const profilePicture = localStorage.getItem("profilePicture");

  const handleImageEdit = () => {
    setShowProfilePictureModal(true);
  };
  return (
    <div className="profileInformation accountSettings">
      <div className="userLogo">
        <div className="logo">
          {profilePicture &&
          profilePicture !== "" &&
          profilePicture !== "Profile picture removed successfully" ? (
            <img src={profilePicture} className="profilePictureSize" />
          ) : (
            <span>
              {userName && userName !== undefined
                ? userName.charAt(0)
                : userEmail.charAt(0)}
            </span>
          )}
          <div className="editIconWrapper" onClick={handleImageEdit}>
            <img src={editIcon} alt="edit" />
          </div>
        </div>
      </div>
      <div className="profileContent">
        <Row gutter={16} align="middle">
          <Col xs={24} sm={12} lg={12} xl={12}>
            <p className="title">Profile information</p>
          </Col>
          <Col xs={24} sm={12} lg={12} xl={12} justify="flex-end">
            {/* <Button disabled type="default" className="inviteButtonTPM" onClick={handleAccountModal}>
                     Deactivate account
                  </Button> */}
          </Col>
        </Row>
        <PersonalInformation />
      </div>
      {isModalOpen && (
        <DeactivateAccountModal
          isModalOpen={isModalOpen}
          handleAccountModal={handleAccountModal}
        ></DeactivateAccountModal>
      )}
      <EditProfilePicture
        visible={showProfilePictureModal}
        onClose={() => setShowProfilePictureModal(false)}
      />
    </div>
  );
};

export default ProfileInformation;
