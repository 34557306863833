import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  Divider,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";
import IconStrategy from "../../../../../Assets/svg/task.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const EditTaskModal = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);

  const [taskCreators, setTaskCreators] = useState([]);
  const [taskCreatorsLoading, setTaskCreatorsLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    setShowEditModal(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleCountryChange = (country) => {
    setCities([]);
    setSiteVisits([]);
    setTaskCreators([]);

    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });

    httpService.get(
      `/api/GeoData/cities?countryId=${country}`,
      (res) => setCities(res.data),
      (error) => console.log * error
    );

    const projectId = localStorage.getItem("currentProjectID");
    const countryData = locations.find((location) => location.id == country);

    httpService.get(
      `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`,
      (res) => setSiteVisits(res.data),
      (err) => console.log(err)
    );
  };

  const handleSubmit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();

      const payload = {
        id: taskId,
        name: values.taskName,
        deadline: values.deadline,
        taskLocation: {
          countryId: values.country,
          // cityId: values.city,
        },
        collectionMethod: values.collectionMethod,
        type: values.taskType,
        otherType: values.otherType,
        description: values.description,
        numberOfInterviews: values.numberOfInterviews,
        contactInformation: values.contactInformation,
        additionalNotes: values.additionalNotes,
        status: values.taskStatus,
        siteVisitId: values.siteIds,
      };

      httpService.put(
        "/api/Tasks/updateTask",
        payload,
        (response) => {
          const message =
            response.data.message ||
            "This task was correctly updated to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: IconStrategy,
            title: "Task updated successfully!",
            text: message,
          });
          onClose();
          fetchData();
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setButtonLoading(false);
        },
        (error) => {
          console.error("Failed to submit task:", error);
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem updating this task, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: IconStrategy,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setButtonLoading(false);
        }
      );
    } catch (errorInfo) {
      console.error("Form validation failed:", errorInfo);
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 3000);
      // Handle form validation errors if needed
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchTeamBySiteVisit = async (siteVisitIds) => {
    setTaskCreatorsLoading(true);
    // const queryString = siteVisitIds
    //   .map((id, index) => `siteVisitIds[${index}]=${encodeURIComponent(id)}`)
    //   .join("&");

    httpService.get(
      `/api/Site/getTeamBySiteVisits?${siteVisitIds}`,
      (response) => {
        const formattedData = response.data.map((data) => data.members) || [];
        const allMembers = formattedData.flat();

        const uniqueMembers = allMembers.filter(
          (member, index, self) =>
            index === self.findIndex((m) => m.id === member.id)
        );

        setTaskCreators(uniqueMembers);
        setTaskCreatorsLoading(false);
      },
      (error) => {
        console.error("Failed to fetch team by site visit:", error);
        setTaskCreatorsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (taskId && visible) {
      const fetchData = async () => {
        setLoading(true);
        httpService.get(
          `/api/Tasks/getTask?id=${taskId}`,
          (response) => {
            const task = response.data;
            console.log("response:", response.data);

            form.setFieldsValue({
              taskIdentifier: task?.identifier,
              taskName: task?.name,
              deadline: dayjs(task.deadline),
              country: task?.taskLocation?.country?.id,
              city: task?.taskLocation?.city?.id,
              siteIds: task?.taskSite?.id,
              assignedTo: task?.taskUsers[0]?.userName,
              district: task?.taskLocation?.district,
              collectionMethod: task?.collectionMethod,
              description: task?.description,
              numberOfInterviews: task?.numberOfInterviews,
              contactInformation: task?.contactInformation,
              additionalNotes: task?.additionalNotes,
              taskType: task?.type,
              taskStatus: task?.status,
            });

            httpService.get(`/api/GeoData/countries`, (res) =>
              setLocations(res.data)
            );

            const countryId = task?.taskLocation?.country?.id;
            httpService.get(
              `/api/GeoData/cities?countryId=${countryId}`,
              (res) => setCities(res.data)
            );

            const projectId = localStorage.getItem("currentProjectID");

            const countryData = locations.find(
              (location) => location.id == countryId
            );
            httpService.get(
              `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}`,
              (res) => setSiteVisits(res.data)
            );

            httpService.get(
              `/api/Site/getTeamBySiteVisits?siteVisitIds=${task?.taskSite?.id}`,
              (res) => setTaskCreators(res.data)
            );

            setLoading(false);
          },
          (error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          }
        );
      };
      fetchData();
    }
  }, [taskId, form, visible]);

  if (!context) {
    return <></>;
  }
  const { fetchData } = context;

  return (
    <Modal
      closable={false}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit Task Card</h2>
        </>
      }
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button className="inviteButtonTPM" key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task card identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the task name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select placeholder="Select an option">
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please select Country" }]}
              >
                <Select
                  placeholder="Select an option"
                  onChange={handleCountryChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {locations &&
                    locations.map((location) => (
                      <Option key={location?.id} value={location?.id}>
                        {location?.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please select City" }]}
              >
                <Select
                  placeholder="Select a city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={cities?.length < 1 ? true : false}
                >
                  {cities &&
                    cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Col span={24}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please input your site ID!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                onChange={(value) => {
                  fetchTeamBySiteVisit(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={siteVisits?.length < 1}
              >
                {siteVisits &&
                  siteVisits?.map((visit) => (
                    <Option key={visit.id} value={visit.id}>
                      {`${visit?.identifier} - ${visit?.ipPlan?.identifier}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Assigned to a field coordinator"
              name="assignedTo"
              rules={[{ required: false, message: "Please select a person" }]}
            >
              <Select
                placeholder="Select an option"
                disabled={taskCreators?.length < 1 ? true : false}
              >
                {taskCreatorsLoading ? (
                  <Option>
                    <Spin
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px 0",
                      }}
                    />
                  </Option>
                ) : (
                  taskCreators.map((member) => (
                    <Option key={member?.id} value={member?.id}>
                      {member?.firstName ?? ""} {member?.username ?? ""} (
                      {member?.roleName ?? ""})
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item label="Collection method" name="collectionMethod">
              <Input
                placeholder="Enter the collection method"
                // onChange={(e) =>
                //   handleInputChange("collectionMethod", e.target.value)
                // }
              />
            </Form.Item>
          </Col>

          <Form.Item label="Task description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the task description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber
              span={6}
              min={1}
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
          <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the task status" },
            ]}
          >
            <Select placeholder="Select the task status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditTaskModal;
