import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Tooltip,
  Space,
  Divider,
  Spin,
} from "antd";
import "./style.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import FormItem from "antd/es/form/FormItem";
import PlanningIcon from "../../../../Assets/svg/iconMenu2.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { PlusOutlined } from "@ant-design/icons";
import { httpService } from "../../../../services/httpService.service";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";

const { Option } = Select;

const AddModal = ({ visible, onClose, setMessage, onAdd }) => {
  const [loading, setLoading] = useState(false);
  const [savingPorgress, setSavingPorgres] = useState(false);
  const [form] = Form.useForm();
  const projectID = localStorage.getItem("currentProjectID");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const inputRef = useRef(null);
  const [clusters, setClusters] = useState([]);
  const [newCluster, setNewCluster] = useState("");
  const [addingCluster, setAddingCluster] = useState(false);
  const [users, setUsers] = useState();
  const [implementingPartners, setImplementingPartners] = useState();
  const [selectedIp, setSelectedIp] = useState({});
  const [fileList, setFileList] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [ipPlanInputId, setIpPlanInputId] = useState("###");

  const years = Array.from({ length: 11 }, (_, i) => currentYear + i);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const fetchClusterData = async () => {
    setLoading(true);
    httpService.get(
      "/api/Site/getAllClusters",
      (response) => setClusters(response.data),
      (error) => {
        console.log(error);
      }
    );

    setLoading(false);
  };

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      setLoading(true);
      httpService.post(
        "/api/Site/createCluster",
        {
          name: newCluster,
        },
        (response) => {
          const message =
            response.message ||
            `A new cluster has been created and added to your database!`;

          fetchClusterData();
          setNewCluster("");
          setAddingCluster(false);
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: `Cluster added successfully!`,
            text: message,
          });
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            `We had a problem adding this cluster to your database, please try again!`;

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      );

      setLoading(false);
      setTimeout(() => {
        setMessage({
          trigger: false,
          type: "",
          icon: "",
          title: "",
          text: ``,
        });
      }, 3000);

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const handleClusterSelectChange = () => {
    setAddingCluster(false);
  };

  useEffect(() => {
    setSelectedIp({});
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );

    fetchClusterData();

    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleIpChange = (implementingPartnerId) => {
    setLoadingStates(true);
    setSelectedIp({});
    setSelectedCityIds([]);
    setCities([]);
    form.resetFields(["state", "city"]);
    if (implementingPartnerId) {
      const selectedIp = implementingPartners.find(
        (ip) => ip.id === implementingPartnerId
      );
      setSelectedIp(selectedIp);
      const states = selectedIp?.states;
      setStates(states);
      const cities = selectedIp?.cities;
      if (selectedIp?.cities?.length > 0) {
        setCities(cities);
      }
    }
    setTimeout(() => {
      setLoadingStates(false);
    }, 500);
  };

  const handleCityChange = (cityIds) => {
    setSelectedCityIds(cityIds);
  };

  const handleStateChange = (stateIds) => {
    setSelectedStateIds(stateIds);
  };

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const postJsonData = (values, filesData) => {
    let selectedIp;
    if (values?.ImplementingPartnerId) {
      selectedIp = implementingPartners?.find(
        (ip) => ip.id === values?.ImplementingPartnerId
      );
    }

    let selectedClusters;
    if (values.cluster) {
      selectedClusters = clusters?.filter((cluster) =>
        values.cluster.includes(cluster.id)
      );
    }

    let slectedUsers;
    if (values.AssignedTo) {
      slectedUsers = users?.find((user) => user.id === values.AssignedTo);
    }

    const ipPlanDraftData = {
      ipPlanStates: values.states || undefined,
      ipPlanCities: values.cities || undefined,
      implementingPartner: selectedIp,
      ...(values.ipPlanId && {
        ipPlanId: values.ipPlanId,
      }),
      ...(values?.OfficeId && {
        office: {
          id: values.OfficeId,
        },
      }),
      ...(selectedStateIds &&
        selectedStateIds.length && {
          StateIds: selectedStateIds,
        }),
      ...(selectedCityIds && {
        cityId: selectedCityIds,
      }),
      ...(slectedUsers && {
        assignedTo: slectedUsers,
      }),
      ...(selectedClusters && {
        clusterId: selectedClusters,
      }),
      ...(values.generalInfo && { generalInfo: values.generalInfo }),
      ...(values?.programDescription && {
        programDescription: values.programDescription,
      }),
      ...(values.InitialMeeting && { initialMeeting: values.InitialMeeting }),
      ...(values.Month && { month: values.Month }),
      ...(values.Year && { year: values.Year }),
      ...(values.contactName && {
        pointOfContact: {
          name: values.contactName,
          ...(values.contactRole && { role: values.contactRole }),
          ...(values.contactEmail && { email: values.contactEmail }),
          ...(values.contactPhone && {
            organisation: values.contactPhone,
            phone: values.contactPhone,
          }),
          ...(values.contactSkype && { skype: values.contactSkype }),
        },
      }),
      ...(values.currentIpStatus && {
        currentIpStatus: values.currentIpStatus,
      }),
      ...(filesData?.length && { fileList: filesData }),
    };

    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(ipPlanDraftData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 2,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: PlanningIcon,
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          onAdd(res.data);
          handleLeave();
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: PlanningIcon,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  const handleSaveProgress = async () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: PlanningIcon,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    let ipPlanFiles = fileList;
    if (ipPlanFiles?.length > 0) {
      const formData = new FormData();

      formData.append("FolderName", "TPM");
      formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

      ipPlanFiles?.forEach((file, index) => {
        formData.append(
          `entityFormFileDtos[${index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f611111"
        );
        formData.append(
          `entityFormFileDtos[${index}].formFile`,
          file.originFileObj
        );
      });

      axios
        .post(
          "https://kapfilemanager.kapsurvey.com/FileData/addFile",
          formData,
          {
            headers: {
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          const filesData = response.data.map((fileData) => fileData);
          postJsonData(values, filesData);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    } else {
      postJsonData(values);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append("IPPLanCode", values.ipPlanId);
        formData.append("ImplementingPartnerId", values.ImplementingPartnerId);
        selectedStateIds.forEach((stateId) => {
          formData.append("StateIds", stateId);
        });
        selectedCityIds.forEach((cityId) => {
          formData.append("CityIds", cityId);
        });
        formData.append("ProjectId", projectID);
        formData.append("AssignedTo", values.AssignedTo);
        values.cluster.forEach((cluster, index) =>
          formData.append(`Clusters[${index}]`, cluster)
        );
        if (values.generalInfo) {
          formData.append("generalInfo", values.generalInfo || "");
        }

        if (values.programDescription !== undefined) {
          formData.append(
            "PlanningRound.ProgramDescription",
            values?.programDescription
          );
        }
        formData.append(
          "InitialMeeting",
          values.InitialMeeting
            ? values.InitialMeeting.format("YYYY-MM-DD")
            : null
        );

        if (values.Month) formData.append("PlanningRound.Month", values.Month);
        if (values.Year) formData.append("PlanningRound.Year", values.Year);
        if (values.contactName)
          formData.append("PointOfContact.Name", values.contactName);
        if (values.contactEmail)
          formData.append("PointOfContact.Email", values.contactEmail);
        if (values.contactRole)
          formData.append("PointOfContact.Role", values.contactRole);
        if (values.contactPhone)
          formData.append("PointOfContact.Phone", values.contactPhone);
        if (values.contactSkype)
          formData.append("PointOfContact.Skype", values.contactSkype);
        if (values.contactOrganization)
          formData.append(
            "PointOfContact.Organization",
            values.contactOrganization
          );

        formData.append("PointOfContact.ContactType", 2);

        formData.append("CurrentIPStatus", values.currentIpStatus || "");
        formData.append("IPDonorReportingEnum", 1 || "");

        fileList.forEach((file, index) => {
          formData.append(`Files[${index}].FileStatus`, 1);
          formData.append(`Files[${index}].File`, file.originFileObj);
        });

        httpService
          .upload(
            "/api/IPPlan",
            formData,
            (res) => {
              const message =
                res.message ||
                "A new IP Plan Card has been created and added to your database.";
              setMessage({
                trigger: true,
                type: "success",
                icon: PlanningIcon,
                title: "New IP Plan Card added successfully!",
                text: message,
              });
              onAdd(res);
              handleLeave();
              setFileList([]);
              form.resetFields();
            },
            (error) => {
              console.error(
                "There was an error adding the IP Plan Card!",
                error
              );

              let errorMessage;
              if (error?.message) {
                errorMessage = error.message;
              } else {
                errorMessage =
                  error?.errors?.[0]?.message ||
                  "We had a problem adding this IP Plan Card from your database, please try again.";
              }

              setMessage({
                trigger: true,
                type: "danger",
                icon: PlanningIcon,
                title: "Oops! A problem has occurred!",
                text: errorMessage,
              });
            }
          )
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 1000);
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: PlanningIcon,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setFileList([]);
    setCities([]);
    setSelectedIp({});
    setSelectedStateIds([]);
    setSelectedCityIds([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      destroyOnClose
      open={visible}
      closable={false}
      width={800}
      onCancel={handleCancel}
      footer={[
        <Button
          onClick={handleSaveProgress}
          key="back"
          loading={loading || savingPorgress}
          className="inviteButtonTPM"
        >
          Save as draft
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={loading || savingPorgress}
          className="addButtonTPM"
        >
          Add IP plan card
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">New IP Plan Card</h2>
      <Form form={form} layout="vertical">
        <Form.Item label="Ip plan card identifier" name="identifier">
          <div className="form-control-plaintext">
            <p
              style={{
                border: "2px solid #d9d9d9",
                borderRadius: "10px",
                padding: "8px 12px",
                fontSize: "14px",
                color: "#495057",
              }}
            >
              <Tooltip title="This number will be generated automatically!">
                <span>00X - </span>
              </Tooltip>
              <Tooltip title="Selected Implementing Partner!">
                <span>
                  {selectedIp?.name !== undefined
                    ? selectedIp?.name
                    : "Implementing Partner"}
                </span>
              </Tooltip>
              <Tooltip title="Created date!">
                <span>{`- ${formattedDate}`}</span>
              </Tooltip>
            </p>
          </div>
        </Form.Item>
        <Form.Item
          label="IP Plan code  (optional)"
          name="ipPlanId"
          rules={[
            {
              required: false,
              message:
                "This field is optional, but if filled, it must be exactly 6 characters long.",
            },
            {
              len: 6,
              message: "Input must be exactly 6 characters long!",
            },
          ]}
        >
          <Input
            style={{ width: "100px" }}
            onChange={(e) => setIpPlanInputId(e.target.value)}
            maxLength={6}
            placeholder="###"
          />
        </Form.Item>
        <h3>General details</h3>
        <Form.Item
          name="ImplementingPartnerId"
          label="Implementing partner"
          rules={[{ required: true, message: "Select an IP" }]}
        >
          <Select
            placeholder="Select an IP"
            onChange={handleIpChange}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {implementingPartners &&
              implementingPartners.map((implementingPartner) => (
                <Option
                  key={implementingPartner.id}
                  value={implementingPartner.id}
                >
                  {implementingPartner.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className="firstSection">
          <div className="row">
            {isEmptyObject(selectedIp) ? (
              ""
            ) : (
              <Spin spinning={loadingStates}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item
                    name="country"
                    label="Country"
                    tooltip="County selected for this project round!"
                    style={{ width: "48%" }}
                  >
                    <p className="previewText my-2">
                      {selectedIp?.states?.[0]?.country?.stateName}
                    </p>
                  </Form.Item>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please select one or more states",
                      },
                    ]}
                    tooltip={
                      cities?.length < 1
                        ? "Cities selected for this implementing partner!"
                        : "States selected for this implementing partner!"
                    }
                    label={
                      cities?.length < 1
                        ? "City/Disctrict/Municipality"
                        : "Governorate/State"
                    }
                    style={{ width: "48%" }}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) === 0
                      }
                      placeholder="Select one or more states"
                      onChange={handleStateChange}
                      disabled={states && !states?.length}
                    >
                      {states.map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.stateName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item
                  name="city"
                  tooltip="Cities selected for this implementing partner!"
                  label="City/Disctrict/Municipality"
                  className={cities?.length > 0 ? "" : "d-none"}
                  rules={[
                    {
                      required: cities?.length > 0,
                      message: "Please select one or more cities",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                    placeholder="Select one or more cities"
                    onChange={handleCityChange}
                    disabled={cities && !cities?.length}
                  >
                    {cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Spin>
            )}
            <Form.Item
              name="AssignedTo"
              label="Assigned to a field coordinator"
              rules={[
                {
                  required: true,
                  message: "Select a person to assign the plan",
                },
              ]}
            >
              <Select
                placeholder="Select a person to assign the plan"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) ===
                  0
                }
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.userDetails?.firstName && user.userDetails?.lastName
                        ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                        : user.userName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Clusters"
              name="cluster"
              rules={[
                {
                  required: true,
                  message: "Please select a cluster",
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().startsWith(input.toLowerCase())
                }
                width={100}
                allowClear
                placeholder="Select an item"
                mode="multiple"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    {addingCluster ? (
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={newCluster}
                          onChange={(e) => setNewCluster(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          type="text"
                          loading={loading}
                          icon={<PlusOutlined />}
                          onClick={addCluster}
                        >
                          Add item
                        </Button>
                      </Space>
                    ) : (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingCluster(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )}
                  </>
                )}
                onChange={handleClusterSelectChange}
                onDropdownVisibleChange={(open) => {
                  !open && setAddingCluster(false);
                  setNewCluster();
                }}
                options={clusters.map((cluster) => ({
                  label: cluster.name,
                  value: cluster.id,
                }))}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          name="generalInfo"
          label="General information about on-site operations"
          rules={[
            { required: true, message: "General information is required" },
          ]}
        >
          <Input.TextArea placeholder="Enter general information about operations" />
        </Form.Item>
        <Form.Item
          name="programDescription"
          label="IP Program Activities Description"
        >
          <Input.TextArea placeholder="Enter a description of the program" />
        </Form.Item>
        <div className="secondSection">
          <div className="row">
            <div className="column">
              <Form.Item
                name="InitialMeeting"
                label="Initial meeting"
                rules={[
                  { required: true, message: "Initial meeting is required" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="assignedToRoundLabel py-3 pb-2">
          <span>Assigned to round</span>
          <Tooltip title="Information about assignment to rounds">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
        <div className="row">
          <div className="column">
            <Form.Item
              name="Month"
              label="Month"
              rules={[{ required: true, message: "Month is required" }]}
            >
              <Select placeholder="Select month" style={{ width: "100%" }}>
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">March</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="column">
            <Form.Item
              name="Year"
              label="Year"
              rules={[{ required: true, message: "Year is required" }]}
            >
              <Select
                showSearch
                placeholder="Select year"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="assignedToRoundLabel">
          <span>IP Point of contact</span>
          <Tooltip title="Information about assignment to rounds">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
        <div className="contact-info">
          <div className="row">
            <div className="column">
              <Form.Item
                name="contactName"
                label="Contact name"
                rules={[
                  { required: true, message: "Contact name is required" },
                ]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                name="contactEmail"
                label="Email"
                rules={[
                  { required: true, message: "Email is required" },
                  { type: "email", message: "The input is not valid E-mail!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </div>

            <div className="column">
              <Form.Item
                name="contactRole"
                label="Role"
                rules={[{ required: true, message: "Please select role" }]}
              >
                <Input placeholder="Enter role" />
              </Form.Item>
              <Form.Item
                name="contactOrganization"
                label="Organization"
                rules={[
                  { required: true, message: "Organization is required" },
                ]}
              >
                <Input placeholder="Enter your organization" />
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item
                name="contactPhone"
                label="Phone"
                rules={[
                  { required: true, message: "Phone number is required" },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item name="contactSkype" label="Skype (optional)">
                <Input placeholder="Enter your skype username" />
              </Form.Item>
            </div>
          </div>
        </div>{" "}
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="column">
            <FormItem
              name="currentIpStatus"
              label="Current IP status"
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Select
                placeholder="Select an IP status"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <Option value="1">Discussion</Option>
                <Option value="2">Document Review</Option>
                <Option value="3">Pre Planning Phase</Option>
                <Option value="4">Data Collection</Option>
                <Option value="5">Closed</Option>
              </Select>
            </FormItem>
          </div>
        </div>
        <div className="assignedToRoundLabel py-3">
          <span>Security plan</span>
          <Tooltip title="Information about assignment to rounds"></Tooltip>
        </div>
        <div className="row">
          <div className="column">
            <Form.Item name="files" label="Upload files">
              <Upload.Dragger
                multiple
                listType="text"
                accept=".jpg, png"
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={(file) => handleFileSize(file)}
              >
                <div className="d-flex justify-content-center pb-3">
                  <img src={uploadIcon} alt="" />
                </div>
                <Button className="uploadButton">
                  Drag and drop files here, or click to select files
                </Button>
                <div className="d-flex justify-content-center bottomText ">
                  <span className="span3">JPG or PNG</span>
                  <span className="span4">Max file size: 5MB</span>
                </div>
              </Upload.Dragger>

              <div className="uploadsContainer">
                {fileList &&
                  fileList.map((file) => (
                    <div className="uploadContent">
                      <img
                        className="uploadIcon"
                        src={uploadIcon}
                        alt={file.name}
                      />
                      <div>
                        <h1>{file.name}</h1>
                        <p>
                          {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                        </p>
                      </div>
                      <img
                        onClick={() => handleResourceRemove(file)}
                        src={RemoveIcon}
                        alt="RemoveIcon"
                      />
                    </div>
                  ))}
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="trackingElement">
          <Form.Item label="" className="trackingLabel"></Form.Item>
        </div>
      </Form>

      <Divider />
    </Modal>
  );
};

export default AddModal;
