import { Tabs } from "antd";
import Banner from "../../components/Banner/Banner";
import TabPane from "antd/es/tabs/TabPane";
import IpPlanningBoard from "./IpPlanningBoard";
import TasksAssignments from "./TasksAssignments/TasksAssignments";
import "./styles.scss";
import VisitsBoard from "./VisitBoard/VisitBoard";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import { DataContext, DataProvider } from "./TasksAssignments/DataContext";

function Planning() {
  return (
    <>
      <div className="planning-banner">
        <Banner text="Planning" />
      </div>
      <div className="planning-page">
        <Tabs
          type="card"
          className="card-tabs"
          // onChange={}
          destroyInactiveTabPane={true}
        >
          {" "}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.Guest,
          ]) && (
            <TabPane className="tabpane" tab="IP Planning board" key="1">
              <IpPlanningBoard />
            </TabPane>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
            RolesEnum.LimitedGuest,
          ]) && (
            <TabPane className="tabpane" tab="Visits board" key="2">
              <VisitsBoard />
            </TabPane>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
            RolesEnum.Guest,
          ]) && (
            <TabPane className="tabpane" tab="Tasks assignments" key="3">
              <TasksAssignments />
              <DataProvider>
                <TasksAssignments />
              </DataProvider>
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
}

export default Planning;
