import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Upload,
  Divider,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Assigned" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const AddRedFlagCardModal = ({
  visible,
  onClose,
  setMessage,
  fetchRedFlags,
  fetchDraftData,
}) => {
  const [users, setUsers] = useState();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [savingPorgres, setSavingPorgres] = useState(false);

  const [form] = Form.useForm();

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";
    return date.format("YYYY-MM-DD");
  };

  const handleOk = async () => {
    setLoadingData(true);

    await form
      .validateFields()
      .then((values) => {
        const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
        const dueDate = formatDate(values.dueDate);
        const projectId = localStorage.getItem("currentProjectID");

        const formData = new FormData();

        formData.append("Name", values.name);
        formData.append("Description", values.description);
        formData.append("SiteVisitId", values.siteVisitCard);
        formData.append("ProjectId", projectId);
        formData.append("DateOfSiteVisit", dateOfSiteVisit);
        formData.append("DueDate", dueDate);
        formData.append("AssignedTo", values.assignedTo);
        formData.append("FlagStatus", values.flagStatus);
        formData.append("FollowUpNotes", values.followUpNotes);

        fileList?.forEach((file, index) => {
          formData.append(
            `Files[${index}].id`,
            "00000000-0000-0000-0000-000000000000"
          );
          formData?.append(`Files[${index}].file`, file.originFileObj);
        });

        httpService.upload(
          "/api/RedFlag",
          formData,
          (response) => {
            const message =
              response.message ||
              "A new red flag has been created and added to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Red flag added successfully!",
              text: message,
            });
            form.resetFields();
            fetchRedFlags(false);
            fetchDraftData();
            handleLeave();
            setFileList([]);
            setLoadingData(false);
          },
          (error) => {
            const messageError = error?.errors?.[0]?.message;
            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text:
                messageError ||
                "We had a problem adding this red flag to your database, please try again.",
            });
            setLoadingData(false);
          }
        );
      })
      .catch((errorInfo) => {
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Validation Error",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setLoadingData(false);
      });

    setTimeout(() => {
      setMessage({
        trigger: false,
      });
    }, 3000);
  };

  const handleSiteVisitChange = (id) => {
    const siteVisit = siteVisits?.find((siteVisit) => siteVisit?.id === id);
    const ipOfSiteVisit = siteVisit?.ipPlan?.implementingPartner?.name;
    form.setFieldValue("implementingPartner", ipOfSiteVisit);
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
    httpService.get(
      "/api/User/getAllusers",
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const postJsonData = (values, filesData) => {
    let slectedUsers;
    if (values.assignedTo) {
      slectedUsers = users?.find((user) => user.id === values.assignedTo);
    }

    const RedFlagDraftData = {
      Name: values.name,
      ImplementingPartner: values?.implementingPartner,
      Description: values.description,
      SiteVisitId: values?.siteVisitCard,
      ProjectId: localStorage.getItem("currentProjectID"),
      DateOfSiteVisit: values?.dateOfSiteVisit,
      DueDate: values?.dueDate,
      AssignedTo: slectedUsers,
      FlagStatus: values.flagStatus,
      FollowUpNotes: values.followUpNotes,
      ...(filesData?.length && { fileList: filesData }),
    };
    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(RedFlagDraftData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 3,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          fetchRedFlags(false);

          fetchDraftData();
          handleLeave();
          setFileList([]);
          form.resetFields();
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  const handleSaveProgress = async () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    let redFlagFiles = fileList;
    if (redFlagFiles?.length > 0) {
      const formData = new FormData();

      formData.append("FolderName", "TPM");
      formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

      redFlagFiles?.forEach((file, index) => {
        formData.append(
          `entityFormFileDtos[${index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f611111"
        );
        formData.append(
          `entityFormFileDtos[${index}].formFile`,
          file.originFileObj
        );
      });

      axios
        .post(
          "https://kapfilemanager.kapsurvey.com/FileData/addFile",
          formData,
          {
            headers: {
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          const filesData = response.data.map((fileData) => fileData);
          postJsonData(values, filesData);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    } else {
      postJsonData(values);
    }
  };

  const handleLeave = () => {
    form.resetFields();
    setFileList([]);
    onClose();
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="cancelX"
        />
      </div>
      <h2 className="modalHeader">Add Red flag card</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          className="fullWidthInput"
          name="name"
          label="Name (type of red flag)"
          rules={[
            { required: true, message: "Enter the name of the red flag" },
          ]}
        >
          <Input
            placeholder="Enter the name of the red flag"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="siteVisitCard"
          label="Select the site visit card"
          rules={[{ required: true, message: "Select the site visit card" }]}
        >
          <Select
            onChange={handleSiteVisitChange}
            placeholder="<###> - SV - <Sitename> - <Location 2> - <Location 3>"
          >
            {siteVisits &&
              siteVisits.map((siteVisit) => (
                <Option key={siteVisit.id} value={siteVisit.id}>
                  {siteVisit.identifier}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="implementingPartner" label="Implementing partner">
          <Input
            disabled
            placeholder="Implementing partner of the selected site visit card"
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Enter the description of the flag" },
          ]}
        >
          <TextArea rows={4} placeholder="Enter the description of the flag" />
        </Form.Item>
        <Form.Item
          name="dateOfSiteVisit"
          label="Date of site visit"
          rules={[{ required: true, message: "Enter the date of site visit" }]}
        >
          <DatePicker placeholder="Date of site visit" format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          name="dueDate"
          label="Due date"
          rules={[{ required: true, message: "Enter the due date" }]}
        >
          <DatePicker placeholder="Due date" format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="assignedTo"
          label="Assigned to"
          rules={[{ required: true, message: "Select the assigned user" }]}
        >
          <Select placeholder="Select the assigned user">
            {users &&
              users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.userDetails?.firstName && user.userDetails?.lastName
                    ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                    : user.userName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="flagStatus"
          label="Flag status"
          rules={[{ required: true, message: "Select the flag status" }]}
        >
          <Select placeholder="Select the flag status">
            {flagStatuses.map((status) => (
              <Option key={status.id} value={status.id}>
                {status.status}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="followUpNotes"
          label="Follow-up notes"
          rules={[{ required: true, message: "Enter the follow-up notes" }]}
        >
          <TextArea rows={4} placeholder="Enter the follow-up notes" />
        </Form.Item>
        <div className="resourcesElement">
          <div className="resourcesLabel">
            <div className="resourcesUploadElement">
              <Form.Item
                name="files"
                label={
                  <div>
                    <p
                      className="modalsHeader1"
                      style={{
                        color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                        fontFamily: "Poppins", // Use camelCase for properties in React
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "144%",
                      }}
                    >
                      Evidence
                    </p>
                    <p
                      style={{
                        color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                        fontFamily: "Poppins", // Use camelCase for properties in React
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >
                      Please attach any relevant evidence if needed
                    </p>
                  </div>
                }
              >
                <Upload.Dragger
                  multiple
                  listType="text"
                  accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={(file) => handleFileSize(file)}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload.Dragger>
                <div className="uploadsContainer">
                  {fileList &&
                    fileList.map((file) => (
                      <div className="uploadContent">
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
        <Divider />
        <Form.Item>
          <div className="buttonsModal mt-3">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              loading={loadingData || savingPorgres}
              onClick={handleSaveProgress}
            >
              Save as Draft
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loadingData || savingPorgres}
            >
              Add new red flag
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRedFlagCardModal;
