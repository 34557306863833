import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input, Spin } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";

const { Option } = Select;

const EditIp = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [citiesOfProject, setCitiesOfProject] = useState([]);
  const [statesOfProject, setStatesOfProject] = useState([]);
  const [implementingPartnerData, setImplementingPartnerData] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    ipName: Yup.string().required("Please enter IP name"),
    country: Yup.string().required("Please select a country"),
    state: Yup.array().required("Please select governorate/state"),

    city: Yup.array()
      .test("is-required", "Please select a city", function (value) {
        const { path, createError } = this;
        if (cities.length > 0 && (!value || value.length === 0)) {
          return createError({ path, message: "Please select a city" });
        }
        return true;
      })
      .nullable(),
  });

  useEffect(() => {
    setLoading(true);
    if (visible && record) {
      const projectId = localStorage.getItem("currentProjectID");
      httpService.get(
        `/api/Projects?id=${projectId}`,
        (res) => {
          const projectDetails = res.data?.projectDetails;
          setAllCountries([projectDetails?.country]);
          setStatesOfProject(projectDetails?.states);
          setCitiesOfProject(projectDetails?.locations);

          setCities(projectDetails?.locations);
          setStates(projectDetails?.states);
          setLoading(false);
          // if (citiesData.length > 0) {
          //   const countryId = statesData[0]?.country?.id;
          //   const stateIds = statesData?.map((state) => state?.id);

          //   fetchStates(countryId);
          //   if (stateIds?.length > 0) {
          //     fetchCities(stateIds);
          //   }
          // } else if (statesData.length) {
          //   const countryId = statesData[0]?.country?.id;
          //   fetchStates(countryId);
          // }
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );

      httpService.get(
        `/api/ImplementingPartner/getImplementingPartner?implementingPartnerId=${record.id}`,
        (res) => {
          setImplementingPartnerData(res.data);
          setLoading(false);
        },
        (error) => {
          console.log("Implementing Partner Fetch Error:", error);
          setLoading(false);
        }
      );
    }
  }, [visible, record]);

  const fetchStates = (countryId) => {
    setLoading(true);
    httpService.get(
      `/api/GeoData/states?countryIds=${countryId}`,
      (res) => {
        const citiesData = res.data;

        const filteredCities = citiesData?.filter((city) =>
          statesOfProject?.some((projectCity) => projectCity.id === city.id)
        );
        setStates(filteredCities);
        setCities([]); // Clear cities when states change
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const fetchCities = (stateIds) => {
    setLoading(true);
    const params = new URLSearchParams();
    stateIds.forEach((id) => params.append("stateIds", id));
    httpService.get(
      `/api/GeoData/cities?${params}`,
      (res) => {
        const citiesData = res.data || [];
        const filteredCities = citiesData?.filter((city) =>
          citiesOfProject?.some((projectCity) => projectCity.id === city.id)
        );
        setCities(filteredCities);

        setLoading(false);
      },
      (error) => {
        console.log(error);

        setLoading(false);
      }
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    httpService.put(
      "/api/ImplementingPartner/updateImplementingPartner",
      {
        id: record.id,
        name: values.ipName,
        cityIds: values.city,
        stateIds: values.state,
      },
      (res) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: `IP updated successfully!`,
          text: `IP has been updated in your database!`,
        });
        onSubmit();
        onClose();
        setCities([]);
        setSubmitting(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          `We had a problem updating this IP in your database, please try again.`;

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        console.log("Update error:", error);
        setSubmitting(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: ``,
          });
        }, 3000);
      }
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={600}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Edit IP</h2>
      <Spin spinning={loading}>
        {implementingPartnerData && (
          <Formik
            enableReinitialize
            initialValues={{
              ipName: implementingPartnerData?.name || "",
              country: allCountries?.[0]?.id,
              state:
                implementingPartnerData?.states?.map((state) => state?.id) ||
                [],
              city:
                implementingPartnerData?.cities.map((city) => city?.id) || [],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              isSubmitting,
              handleChange,
            }) => (
              <Form layout="vertical">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    paddingBottom: "20px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    IP name
                  </label>
                  <Input
                    name="ipName"
                    placeholder="Enter IP name"
                    value={values.ipName}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {errors.ipName && touched.ipName ? (
                    <div className="error">{errors.ipName}</div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    paddingBottom: "20px",
                  }}
                >
                  <label>
                    <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                    Country
                  </label>
                  <Field name="country">
                    {({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select Country"
                        onChange={(value) => {
                          setCities([]);
                          setStates([]);
                          setFieldValue("country", value);
                          fetchStates(value);
                          setFieldValue("state", undefined); // Clear state field
                          setFieldValue("city", []); // Clear city field
                        }}
                        value={values.country}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) === 0
                        }
                      >
                        {allCountries.map((country) => (
                          <Option key={country.id} value={country.id}>
                            {country.stateName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Field>
                  {errors.country && touched.country ? (
                    <div className="error">{errors.country}</div>
                  ) : null}
                </div>
                {implementingPartnerData?.states.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      paddingBottom: "20px",
                    }}
                  >
                    <label>
                      <span style={{ color: "red", paddingRight: "3px" }}>
                        *
                      </span>
                      Governorate/State
                    </label>
                    <Field name="state">
                      {({ field }) => (
                        <Select
                          {...field}
                          mode="multiple"
                          placeholder="Select a governorate or state"
                          onChange={(value) => {
                            setCities([]);
                            setFieldValue("state", value);
                            fetchCities(value);
                            setFieldValue("city", []);
                          }}
                          value={values.state}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) === 0
                          }
                        >
                          {states.map((state) => (
                            <Option key={state.id} value={state.id}>
                              {state.stateName}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Field>
                    {errors.state && touched.state ? (
                      <div className="error">{errors.state}</div>
                    ) : null}
                  </div>
                )}
                {implementingPartnerData?.cities.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      paddingBottom: "20px",
                    }}
                  >
                    <label>
                      <span style={{ color: "red", paddingRight: "3px" }}>
                        *
                      </span>
                      City/Disctrict/Municipality
                    </label>
                    <Field name="city">
                      {({ field }) => (
                        <Select
                          {...field}
                          placeholder="Select a city"
                          mode="multiple"
                          onChange={(value) => {
                            setFieldValue("city", value);
                          }}
                          value={values.city}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) === 0
                          }
                        >
                          {cities.map((city) => (
                            <Option key={city.id} value={city.id}>
                              {city.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Field>
                    {errors.city && touched.city ? (
                      <div className="error">{errors.city}</div>
                    ) : null}
                  </div>
                )}

                <div className="buttonsModal" style={{ marginTop: "20px" }}>
                  <Button
                    className="inviteButtonTPM"
                    key="cancel"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="addButtonTPM"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Spin>
    </Modal>
  );
};

export default EditIp;
