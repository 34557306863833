import React from "react";
import { Modal, Form, Button, Spin } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const PreviewProject = ({ visible, onClose, project }) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const linkToSharedDrive = project?.projectDetails?.linkToSharedDrive;

  const formattedUrl =
    linkToSharedDrive &&
    (linkToSharedDrive.startsWith("http://") ||
      linkToSharedDrive.startsWith("https://"))
      ? linkToSharedDrive
      : `http://${linkToSharedDrive}`;

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview Project Round</h2>
      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Project Details</p>
        <Spin spinning={!project}>
          {project && (
            <>
              <Form.Item
                name="projectName"
                label={
                  <span style={{ fontWeight: "bold" }}>Project Round Name</span>
                }
              >
                <p className="previewText">{project?.projectName}</p>
              </Form.Item>
              <Form.Item
                name="projectNumber"
                label={
                  <span style={{ fontWeight: "bold" }}>Project Round Code</span>
                }
              >
                <p className="previewText">{project?.projectNumber}</p>
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="country"
                  label={<span style={{ fontWeight: "bold" }}>Country</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {project?.projectDetails?.country?.stateName}
                  </p>
                </Form.Item>

                <Form.Item
                  name="state"
                  label={<span style={{ fontWeight: "bold" }}>State</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {project?.projectDetails?.states
                      .map((state) => state?.stateName)
                      .join(", ")}
                  </p>
                </Form.Item>
              </div>
              {project.projectDetails?.locations.length ? (
                <Form.Item
                  name="city"
                  label={<span style={{ fontWeight: "bold" }}>Locations</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {(project?.projectDetails?.locations)
                      .map((location) => location.name)
                      .join(", ")}
                  </p>
                </Form.Item>
              ) : (
                ""
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="client"
                  label={<span style={{ fontWeight: "bold" }}>Clients</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {(project?.clients || [])
                      .map((client) => client.name)
                      .join(", ")}
                  </p>
                </Form.Item>
                <Form.Item
                  name="clientFocalPoint"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Client Focal Point
                    </span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {project?.projectDetails?.clientFocalPoint || ""}
                  </p>
                </Form.Item>
              </div>
              <Form.Item
                name="clientFocalPointEmail"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Client Focal Point Email
                  </span>
                }
              >
                <p className="previewText">
                  {project?.projectDetails?.clientFocalPointEmail || ""}
                </p>
              </Form.Item>
              {project?.description && (
                <Form.Item
                  name="projectDescription"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Project Description
                    </span>
                  }
                >
                  <p className="previewText">{project?.description}</p>
                </Form.Item>
              )}
              <Form.Item
                name="referenceDocuments"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Reference documents (communications doc link, etc.)
                  </span>
                }
              >
                <p className="previewText">
                  {project?.projectDetails?.referenceDocuments || ""}
                </p>
              </Form.Item>
              <Form.Item
                name="linkToSharedDrive"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Link To Shared Drive
                  </span>
                }
              >
                <a
                  href={formattedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formattedUrl}
                </a>
              </Form.Item>

              <div className="buttonsModal">
                <Button
                  className="inviteButtonTPM"
                  key="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Spin>
      </Form>
    </Modal>
  );
};

export default PreviewProject;
