import { Modal, Table, Button, notification } from "antd";
import { useState, useEffect } from "react";
import { httpService } from "../../../../../../services/httpService.service";
import deleteIcon from "../../../../../../Assets/svg/table-action-delete.svg";
import { RevertButton } from "../../../../../../Assets/svg";

const DeletedModal = ({ visible, onOk, onCancel, fetchSiteVisitCards }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const projectId = localStorage.getItem("currentProjectID");

  const fetchData = async (pagination) => {
    setLoading(true);

    const projectId = localStorage.getItem("currentProjectID");
    if (!projectId) {
      throw new Error("Project ID is not available");
    }

    const url = `/api/Site/getAllSiteVisits?ProjectId=${projectId}&GetDeletedOnly=true&page=${pagination.current}&pageSize=${pagination.pageSize}`;
    httpService.get(
      url,
      (result) => {
        if (!result || !result.data || !result.metaData) {
          throw new Error("API response is missing expected fields.");
        }
        setData(result.data);
        setPagination({
          ...pagination,
          total: result.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Failed to fetch tasks:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination);
    }
  }, [visible]);

  const deleteTask = async (taskId) => {
    
      const url = `/api/Site/deleteSiteVisits?includeIsDeleted=true`;
      const data = JSON.stringify([{ id: taskId, isDeleted: true }]);
      httpService.delete(url, data,
        (res)=>{
          fetchData(pagination);
        },
        (error)=>{
          const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem deleting this site visit from the database, please try again!";
          console.error("Failed to delete task:", errorMessage);
        }
      );
      
  };

  const recoverData = async (id) => {
    await httpService.put(
      `/api/Site/revertDelete`,
      {
        entityType: 11,
        ids: [id],
      },
      (response) => {
        const message =
          response.message ||
          "Tasks are successfully reverted from soft deletion";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Trip logistic reverted successfully!",
          text: message,
        });
        fetchData(pagination);
        fetchSiteVisitCards(projectId);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
        error?.errors?.[0]?.message ||
          "Failed to recover visit Tasks.";

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination);
  };

  const statusMappingSiteVisitStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Data collection in progress",
          className: "progress",
          fill: "#3B81FE",
        };
      case 2:
        return {
          text: "Selected for monitoring",
          className: "monitoring",
          fill: "#FC9403",
        };
      case 3:
        return { text: "Review", className: "review", fill: "#9670B8" };
      case 4:
        return { text: "Delivered", className: "delivered", fill: "#045B46" };
      case 5:
        return { text: "Debrief", className: "debrief", fill: "#0F7507" };
      default:
        return { text: "Unknown", className: "unknown", fill: "#000000" };
    }
  };

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
      dataIndex: "identifier",
      key: "identifier",
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) =>
        a.implementingPartner.localeCompare(b.implementingPartner),
      dataIndex: "implementingPartner",
      key: "implementingPartner",
      render: (record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.locationDetails.localeCompare(b.locationDetails),
      dataIndex: "locationDetails",
      key: "locationDetails",
      render: (record) => (
        <span className="styledDataIndex3">{record?.country}</span>
      ),
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.isVirtual.localeCompare(b.isVirtual),
      dataIndex: "isVirtual",
      key: "isVirtual",
      render: (record) => (
        <span className="styledDataIndex3">{record ? "Yes" : "No"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Site visit status</span>,
      dataIndex: "tracking",
      key: "siteVisitStatus",
      width: 250,
      sorter: (a, b) => a?.siteVisitStatus - b?.siteVisitStatus,
      render: (record) => {
        const { text, className, fill } = statusMappingSiteVisitStatus(
          record?.siteVisitStatus
        );
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            onClick={() => deleteTask(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={deleteIcon} alt="delete" />
          </Button>
          <Button
            onClick={() => recoverData(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={RevertButton} alt="RevertButton" />
          </Button>
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <Modal
        title="Deleted site visits"
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
        width={"70%"}
      >
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey="key"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </div>
  );
};

export default DeletedModal;
