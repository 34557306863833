import { Select, Button, List, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "../../reporting/Reporting.scss";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import { httpService } from "../../../../services/httpService.service";
import jsPDF from "jspdf";
import { tab } from "@testing-library/user-event/dist/tab";
import { TbArrowBack } from "react-icons/tb";

function DatasetList() {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [data, setData] = useState([]);

  const { Option } = Select;

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: pageSize });
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    const apiUrl = `/api/Site/getAllSiteVisits?projectId=${projectId}&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&IgnorePagination=true`;
    setLoading(true);

    httpService
      .get(
        apiUrl,
        (res) => {
          const data = res.data;
          const filterData = data.filter(
            (siteVisit) =>
              siteVisit.logistic?.fulcrumAccount &&
              siteVisit.logistic?.fulcrumAccount !== "undefined" &&
              siteVisit.logistic?.fulcrumAccount !== "null"
          );

          setData(filterData);
          setPagination({
            pageNumber: res?.metaData?.pageNumber,
            pageSize: res?.metaData?.pageSize,
            total: res?.metaData?.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.pageNumber, pagination.pageSize, pagination.total]);

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Dataset List</span>

        <div
          className="listDesign"
          id="pdf-content"
          style={{ marginTop: "40px" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a
                    href={
                      item?.logistic?.fulcrumAccount
                        ? `https://${item?.logistic?.fulcrumAccount
                            .trim()
                            .replace(/^https?:\/\//, "")}`
                        : "#"
                    }
                    target="_blank"
                    className="buttonsList"
                  >
                    <img
                      style={{ transform: "rotate(-90deg)" }}
                      src={downloadList}
                      alt="View"
                    />
                  </a>,
                ]}
                style={{ width: "100%" }}
              >
                <List.Item.Meta
                  title={
                    <p className="titleText">
                      {item?.logistic?.fulcrumAccount
                        ? `${item?.logistic?.fulcrumAccount} - ${item.identifier}`
                        : `No Platfom Account - ${item.identifier}`}
                    </p>
                  }
                />
              </List.Item>
            )}
            pagination={{
              current: pagination.pageNumber,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: handlePageChange,
            }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </Spin>
  );
}

export default DatasetList;
